import React, {lazy, useState, useEffect, useMemo, ReactNode, FC, JSXElementConstructor, Suspense} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import AfdErrorBoundary from './component/error_boundary/AfdErrorBoundary';
import RestApiService from "./service/restapi/RestApiService";
import RestApiCommonService from "./service/restapi/RestApiCommonService";

import Alert from '@mui/material/Alert';
import CtxYupSchemaObj from "./context/CtxYupSchemaObj";
import CtxFormResponse from "./context/CtxFormResponse";

import CtxAppObj from "./context/CtxApp";
import IAppObj from "./type/context/IAppObj";
import { useParams } from "react-router-dom";

import {processElement} from "./util/UtilReact";

import RtContactCover from "./gantry/rt-contact-cover";
import {camelize, capitalize} from "./util/Util";
import RtSiteSingle from "./gantry/rt-site-single";
import RtSiteTrain  from "./gantry/rt-amocourse-train";
import RtAccountTrain from "./gantry/rt-account-train";
import RtClubDashboard from "./gantry/rt-club-dashboard";
import RtSiteInstructor  from "./gantry/rt-site-instructor";
import RtClubDouble from "./gantry/rt-club-double";
import RtClubNewbb from "./gantry/rt-club-newbb";
import RtBackendFull from "./gantry/rt-backend-full";
import RtClubChapter from "./gantry/rt-club-chapter";
import RtSiteProfile from "./gantry/rt-site-profile";
import RtSiteBlog from "./gantry/rt-site-blog";

import {NURBSUtils} from "three/examples/jsm/curves/NURBSUtils";
import IJos4Menu from "./type/IJos4Menu";
import IUserInfo from "./type/IUserInfo";
import IRedirectionInfo from "./type/IRedirectionInfo";
import IContainerQuery from "./type/IContainerQuery";
import IRouteMenutype from "./type/IRouteMenutype";
import Container from "@mui/material/Container";

import ReactPixel from 'react-facebook-pixel';

import AfdConfigurator from "./examples/Configurator/AfdConfigurator";
import { useCHGetContextSoftUI, setMiniSidenav, setOpenConfigurator } from "./context/CtxSoftUIGlobalSetupByRdx";
import AfdLoadingFullpage from "./component/AfdLoadingFullpage";
import ICtxGlobalConfig from "./type/context/ICtxGlobalConfig";
import IRQOutputSingle from "./type/rq/IRQOutputSingle";
import RestApiImageService from "./service/restapi/RestApiImageService";
import IRQOutputList from "./type/rq/IRQOutputList";
import {isDev} from "./util/isDev";
import SoftBox from "./components/SoftBox";
import SoftTypography from "./components/SoftTypography";
import buildInfoObj from "./buildInfoObj";
import 'react-toastify/dist/ReactToastify.css';
import {toast, ToastContainer} from "react-toastify";
import linkDictionary from "./linkDictionary";
import ICtxFormResponse from "./type/context/ICtxFormResponse";
import IUniversalFormSubmitReponse from "./type/comprofiler/IUniversalFormSubmitReponse";
import useCHFBConversionAPI, {EventTypes} from "./hook/useCHFBConversionAPI";
import {formikInitialValueObj} from "./form/formikInitialValueObj";
import IFormikInitialValues from "./type/form/IFormikInitialValues";
import {IValidationSchemaShapeObj} from "./form/GetFormFieldGroup";
import {useCookies} from "react-cookie";

interface IAppRouter2Decode2Gantry {
    userInfoObj: IUserInfo;
    routeCategory: string;
    redirectionObj: IRedirectionInfo;
}
/* Retrieve the data based on selected route
- takes appObj will all info as input
* */
export default function AppRouter2Decode2Gantry(
    {
        userInfoObj: userInfoObj,
        routeCategory: routeCategory,
        redirectionObj: redirectionObj,
    }: IAppRouter2Decode2Gantry) {

    const [cookieJos4Name, setCookieJos4Name] = useCookies(['jos4_name']); // de facto nazwa cookie (jako typ ?? )
    const navigate = useNavigate();
    var GantryComponent: JSXElementConstructor<any>;
    const location = useLocation();
    // Change the openConfigurator state
    // @ts-ignore
    const [controllerParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controllerParamObj;
    const { facebookApiFunction, facebookApiIsLoading, facebookApiData, facebookApiError } = useCHFBConversionAPI(
        {
            user_email: userInfoObj.user_email,
            user_score: 100,
            client_ip: ''
        });

    const [storeValidationSchemaShapeObj, setStoreValidationSchemaShapeObj] = useState<IValidationSchemaShapeObj> ({});
    const [storeFormikInitialValueObj, setStoreFormikInitialValueObj] = useState<IFormikInitialValues>(formikInitialValueObj);
    const [storeFormResponseObj, setStoreFormResponseObj] = useState<IUniversalFormSubmitReponse | null>(null);

    const [menuItemObj, setMenuItemObj] = useState<IJos4Menu>();
    const [instructorObjList, setInstructorObjList] = useState([]);
    const [containerObjList, setContainerObjList] = useState<IContainerQuery[]>([]);

        /* RQ Idle */
        const rqJos4Menu: IRQOutputList = RestApiCommonService.getJos4MenuRQ(redirectionObj.menu_item_id);
        const rqInstructorLetterInfo: IRQOutputList = RestApiCommonService.getInstructorLetterInfoRQ();
        const rqXxClubPageModule2PageSets: IRQOutputList = RestApiCommonService.getXxClubPageModule2PageSetsRQ(redirectionObj.menu_item_id);




    const components:any = {
        "rt-site-single": RtSiteSingle,
        "rt-site-blog": RtSiteBlog,
        "rt-contact-cover": RtContactCover,
        "rt-site-train": RtSiteTrain,
        "rt-account-train": RtAccountTrain,
        "rt-club-dashboard": RtClubDashboard,
        "rt-backend-full": RtBackendFull,
        "rt-club-double": RtClubDouble,
        "rt-site-instructor": RtSiteInstructor,
        'rt-club-newbb': RtClubNewbb,
        'rt-club-chapter': RtClubChapter,
        'rt-site-profile': RtSiteProfile,
    }
    //https://stackoverflow.com/questions/56947690/react-component-type-in-typescript

    //var GantryComponent; //: FC<string>
    //GantryComponent = RtContactCover;

/*
    function getComponentContainer(xxReactGantry: string) {
        let componentName =  capitalize(camelize(xxReactGantry.toLowerCase()));
        let importPath = "./gantry/"+ xxReactGantry +"/"+componentName;
        //return require(importPath).default;
        import(importPath).then(module => {
            debugLog("module: ", module);
        });
    }*/

 /*   function renderGreeting(Elem: React.ComponentClass<any>) {+------------------------------------------------------
        return <span>Hello, <Elem />!</span>;
    };*/
    //var GantryComponent = renderGreeting(RtContactCover);



    /* To jest miejsce gdzie resetowane są STANY
    - ponieważ stan obiektu GATRY nie jest resetowany tylko dlatego, że GANTRY wołane jest z innym parameterm - pozostaje i powoduje problemy
        - stan musi być ręcznie zresetowany przy warunkach nałożonych przez useEffect czyli tutaj
     */
    useEffect(() => {
        /*
        - containerObjList enough for the component to wait for the NEW data
        * */

            /* find routerMenuId */
        if (redirectionObj.menu_item_id) {
            /* obecnie IDLE nie istnieje */
            if (!rqJos4Menu.isIdle &&
                !rqXxClubPageModule2PageSets.isIdle &&
                !rqInstructorLetterInfo.isIdle
            ) {
                if (!rqJos4Menu.isLoading &&
                    !rqXxClubPageModule2PageSets.isLoading &&
                    !rqInstructorLetterInfo.isLoading
                ) {
                    /* &&
                         !rqJos4Menu.isIdle &&
                         !rqXxClubPageModule2PageSets.isIdle &&
                         !rqInstructorLetterInfo.isIdle*/
                    setMenuItemObj(rqJos4Menu.data.data[0]);
                    setInstructorObjList(processElement(rqInstructorLetterInfo.data.data));
                    setContainerObjList(rqXxClubPageModule2PageSets.data.data);

                    // TODO - ta logika powinna być dla wszystkich stron ponieważ nie ma sytuiacji gdzie przeładowujemy MENU_ID i chcemy zachować walidację formularza ?
                    if (
                            redirectionObj.menu_item_id == 155 ||
                            redirectionObj.menu_item_id == 488
                    )   {
                        setStoreValidationSchemaShapeObj({});
                    }

                } else {
                    /* THIS IS where PAGE RELOAD happens
                     - currenly loading
                     - both are based on redirectionObj.menu_item_id
                      and if ONE is loading then RESET both
                      - if You DO NOT reset - the previous CONTEXT exists and old RQ loading with new DATA (i.e. GalleryRQ  -> ERRORS)
                    * */
                    if (menuItemObj) {                        setMenuItemObj(undefined);                    }
                    if (containerObjList && containerObjList.length > 0) {  setContainerObjList([]);    }
                }
            }
        } // end of IfMenu
    }
    ,    [
            routeCategory,
            redirectionObj.menu_item_id,
            (!rqJos4Menu.isLoading && !rqInstructorLetterInfo.isLoading && !rqXxClubPageModule2PageSets.isLoading  ? 1 : 0),
            (!rqJos4Menu.isIdle && !rqInstructorLetterInfo.isIdle && !rqXxClubPageModule2PageSets.isIdle ? 1 : 0),
        ]);

    /* Nie pokazuj stront PROFIL dla niezalogowanych */
    useEffect(() => {
            if (redirectionObj.menu_item_id) {
                if (userInfoObj.user_id == 0 && routeCategory == 'own_profile'
                ) {
                    navigate(linkDictionary.LOGIN_URL);
                }
            }
        },
        [redirectionObj.menu_item_id]
    );

    /* NONLOGGED redirection:
        - Dopiero przy pełnych informacjach o MENU - przekierowuj
        - pierwsze dobre miejsce z pełnymi informacjami

        TODO - kiedy otwierana w nowej zakładce - wtedy przez chwilę USER_ID = 0 i wtedy od razu przekierowanie
            -  rozszerzyć warunek: (!cookieJos4Name.jos4_name) nie tylko wogóle COOKIE ale jeszcze do tego ważne
            - lub inaczej - sprawdzaj USER_ID ale dopiero kiedy faktycznie identyfikacja COOKIE  nastąpiła
    */
    useEffect(() => {
            if (menuItemObj
                && (menuItemObj.access == 1 || menuItemObj.access == 2)
                && userInfoObj.user_id == 0
                && (!cookieJos4Name.jos4_name)
            ) {

                /* Przekieruj na sztywno, aby zmniejszyć ilość problemów, zresetować stany */
                let loginUrl = linkDictionary.LOGIN_URL;
                var params = new URLSearchParams();
                    params.append('redir_code', 'nonlogged_redirection');
                    params.append('redir_menu_item_id', menuItemObj.id.toString());
                    params.append('redir_redirection_id', redirectionObj.redirection_id.toString());
                    params.append('redir_menu_name', menuItemObj.xx_name_desktop);
                    params.append('redir_oldurl', redirectionObj.oldurl);
                    params.append('redir_newurl', redirectionObj.newurl);

                window.location.href=loginUrl + "?" + params.toString();

            }
        },
        [!!menuItemObj]
    );

    let isDataLoaded = containerObjList?.length > 0 && menuItemObj && Object.keys(userInfoObj).length > 0;

    /* Facebook EVENT in useEffect because of hook */
    useEffect(() => {
      if (isDataLoaded)   {
          if (typeof menuItemObj?.xx_react_gantry !== 'undefined')    {
              facebookApiFunction(
                  EventTypes.ViewContent,
                  location.pathname,
                  "website",
              );
          }
      }
    },
        [
            (isDataLoaded ? 1 : 0),
        ]
    );


    /*const renderGreeting = (Elem: JSXElementConstructor<any>) => {
        return <Elem />;
    }*/

    /* TODO:  wstawienie elementu TAG jest równoznaczne z wywołaniem konstruktora ze słowem new*/


    function getGantryContent() {
        var getComponentContainer: JSXElementConstructor<any> = props => {
            // 👇️ use as {Comp}
            if (typeof menuItemObj?.xx_react_gantry !== 'undefined')    {
                return components[menuItemObj.xx_react_gantry];

            } else {
                var tempContainer: JSXElementConstructor<any>;
                tempContainer =  (props) => {
                    const { GraphComp } = props;
                    return (
                        <div> <GraphComp /> </div>
                    )
                };
                return tempContainer;

            }
        };
        // @ts-ignore
        GantryComponent = getComponentContainer(null);
        if (typeof GantryComponent !== 'undefined') {
            /*For tracking page view*/
            // ReactPixel.pageView();

        return (
                <>
                    <CtxYupSchemaObj.Provider value={{
                        storeValidationSchemaShapeObj: storeValidationSchemaShapeObj,
                        setStoreValidationSchemaShapeObj: setStoreValidationSchemaShapeObj,
                        storeFormikInitialValueObj: storeFormikInitialValueObj,
                        setStoreFormikInitialValueObj: setStoreFormikInitialValueObj,
                    }}>
                        <CtxFormResponse.Provider value={{
                            storeFormResponseObj: storeFormResponseObj,
                            setStorestoreFormResponseObj: setStoreFormResponseObj,
                        }}>
                        <ToastContainer
                            position="top-center"
                            autoClose={3000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme={"light"}
                        />
                    <GantryComponent
                        menuItemObj={menuItemObj}
                        containerObjList={containerObjList}
                        userInfoObj={userInfoObj}
                        routeCategory={routeCategory}
                        redirectionObj={redirectionObj}
                    />
                        </CtxFormResponse.Provider>
                    </CtxYupSchemaObj.Provider>
                </>);
        } else {
            return (
            <Container maxWidth="sm">
                <Alert severity="error">
                    <p>AppRouter2Decode2Page/ComponentContainer: Not found:</p>
                    <h3>Route category:</h3>
                    <p> {routeCategory}</p>
                    <h3>Gantry:</h3>
                    <p> {menuItemObj?.xx_react_gantry}</p>
                    <h3>Redirection obj:</h3>
                    <p> {JSON.stringify(redirectionObj)}</p>
                    <h3>Menu item obj:</h3>
                    <p> {JSON.stringify(menuItemObj)}</p>
                    <h3>container Obj List:</h3>
                    <p>{JSON.stringify(containerObjList)}</p>
                </Alert></Container>
        );
        }


    }

    return (
        <>
    {
               isDataLoaded ?
                   getGantryContent()
                   :
                   <AfdLoadingFullpage
                       componentCategory="AppRouter2Decode2Gantry"
                   />


        }
        </>
    );
}
