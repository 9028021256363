import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
import IUserInfo from "../../../type/IUserInfo";
import SoftBox from "../../../components/SoftBox";
import {IElementForumChapterContent} from "../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import IParamObj from "../../../type/IParamObj";
import IContainerQuery from "../../../type/IContainerQuery";
import parse from "html-react-parser";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import {IElementIssueItemReplace} from "./SetForumChapterIssueItemReplace";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import {getImageZoomIconTSX} from "../newbb/get_phpbb_image/GetPhpbbImageUrl";

export interface IProp {
    content: string;
    elementChapterIssueObj: IElementForumChapterContent;
    paramObj: IParamObj;
    elementIssueAssetObjList: IElementForumChapterIssueAsset[];
}

export interface IElementForumChapterIssueAsset {
    asset_message_id: string;
    asset_user_id: string;
    item_format: string;
    item_format_heading: string;
    item_type: string;
    asset_source_id: string;
    item_host_type: string;
    item_title: string;
    item_type_title: string;
    issue_item_full_url: string;
    asset_local_url: string;
    desc_education: string;
    desc_usage: string;
    asset_source_category: string;
    calc_asset_source_url: string;
    asset_is_image_embed: string;
    asset_source_full_url: string;
    asset_source_desc: string;
    asset_source_category_desc: string;
    asset_frontend_url: string;
    assign_message_id: string;
    assign_title: string;
    assign_comment: string;
    local_http_status: string;
    element_name: string;
}

export const SetForumChapterIssueAssetReplace = ({
                                                     content,
                                                     elementChapterIssueObj,
                                                     paramObj,
                                                     elementIssueAssetObjList
                                                 }: IProp
) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [imgsViewer, setImgsViewer] = useState<boolean>(false);
    const openImgsViewer: () => void
        = () => setImgsViewer(true);
    const closeImgsViewer: () => void
        = () => setImgsViewer(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    let contentType2Color: {
        [key: string]: string;
    }
        = {
        'CONTENT_LINK': "purple",
        'CONTENT_IMAGE': "blue",
        'CONTENT_VIDEO': "green",
    }


    let currentElementIssueItemObj: IElementForumChapterIssueAsset | undefined = elementIssueAssetObjList.find((item: IElementForumChapterIssueAsset) => {
        let tagAssignMessage = 'ASSET_ASSIGN_' + item.assign_message_id;
        let tagAssignTitle = item.assign_title;
        return (content == tagAssignMessage
            || content == tagAssignTitle
        );
    });
    let assetType: string = currentElementIssueItemObj?.item_format ? currentElementIssueItemObj?.item_format : '';

    function getContent(currentElementIssueItemObj: IElementForumChapterIssueAsset,
                        assetType: string,
    ) {
        if (currentElementIssueItemObj) {
            if (assetType == 'CONTENT_LINK') {
                return <>
                    <SoftBox
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <SoftButton
                            variant="contained"
                            color="primary"
                            target={"_blank"}
                            href={currentElementIssueItemObj.issue_item_full_url}
                        >
                            Zobacz zdjęcie (w nowym oknie)
                        </SoftButton>
                    </SoftBox>
                </>;
            } else if (assetType == 'CONTENT_IMAGE') {
                if (currentElementIssueItemObj.asset_is_image_embed == '1') {
                    return <>
                        <SoftBox
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            position={"relative"}
                        >
                            <SoftBox
                            >
                                    <SoftBox
                                        component={"img"}
                                        src={currentElementIssueItemObj.asset_frontend_url}
                                        alt="Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone"
                                        borderRadius={"sm"}
                                        sx={{
                                            width: "100%",
                                            height: "auto",
                                        }}
                                    />
                            </SoftBox>
                            <SoftBox
                                position={"absolute"}
                                bottom={"5%"}
                                right={"5%"}
                            >
                                {getImageZoomIconTSX(
                                    currentElementIssueItemObj.asset_frontend_url,
                                    imgsViewer,
                                    closeImgsViewer,
                                    openImgsViewer,
                                    globalConfigParamObj
                                )}
                            </SoftBox>
                            <SoftBox>
                                <SoftTypography
                                    variant={"button"}
                                >{currentElementIssueItemObj.assign_title}
                                </SoftTypography>
                                {currentElementIssueItemObj.assign_comment}
                                {/*[[ADMIN_EDIT_LINK]] TODO*/}
                            </SoftBox>
                        </SoftBox>
                    </>;
                    /*NO EMBED*/
                } else {
                    return <>
                        <SoftBox
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SoftButton
                                variant="contained"
                                color="primary"
                                target={"_blank"}
                                href={currentElementIssueItemObj.issue_item_full_url}
                            >
                                Zobacz zdjęcie (w nowym oknie)
                            </SoftButton>
                        </SoftBox>
                    </>;
                }
            } else if (assetType == 'CONTENT_VIDEO') {
                if (currentElementIssueItemObj.asset_is_image_embed == '1') {
                    currentElementIssueItemObj.asset_frontend_url = currentElementIssueItemObj.asset_frontend_url.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')/* + '?start=' + currentElementIssueItemObj.issue_item_custom1*/;
                    currentElementIssueItemObj.asset_frontend_url = currentElementIssueItemObj.asset_frontend_url.replace('youtube.com/watch?v=', 'www.youtube.com/embed/')/* + '?start=' + currentElementIssueItemObj.issue_item_custom1*/;
                } else {
                    currentElementIssueItemObj.asset_frontend_url = currentElementIssueItemObj.asset_frontend_url.replace('www.youtube.com/watch?v=', 'www.youtube.com/embed/')/* + '?start=' + currentElementIssueItemObj.issue_item_custom1*/;
                    currentElementIssueItemObj.asset_frontend_url = currentElementIssueItemObj.asset_frontend_url.replace('youtube.com/watch?v=', 'www.youtube.com/embed/')/* + '?start=' + currentElementIssueItemObj.issue_item_custom1*/;
                }

                return <>
                    <SoftBox
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {/* TODO
                        <iframe src="[[ASSET_FRONTEND_URL]]" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>*/}
                    </SoftBox>
                </>;
            }
        }
    }

    /*elementIssueAssetObjList.forEach((elementIssueAssetObj: IElementForumChapterIssueAsset) => {
        const elementName = elementIssueAssetObj.element_name.toUpperCase();
        let templateModule: string = '';

        if ((content.includes(elementIssueAssetObj.assign_title) ||
            content.includes(elementIssueAssetObj.assign_message_id))) {

            if (elementIssueAssetObj.local_http_status !== '404') {
                templateModule = `
                    <div class="element_forum_chapter_issue_asset ${elementIssueAssetObj.item_format.toLowerCase()}">
                        ${parse(templateModule)}
                    </div>
                `;
            } else {
                templateModule = `<div class="element_forum_chapter_issue_asset ${elementIssueAssetObj.item_format.toLowerCase()}"></div>`;
            }
            content = content.replace(`[[${elementIssueAssetObj.assign_title}]]`, templateModule);
            content = content.replace(`[[ASSET_ASSIGN_${elementIssueAssetObj.assign_message_id}]]`, templateModule);
        }
    });*/

    /*return content;*/

    function getAssetFrameFooterTSX(currentElementIssueItemObj: IElementForumChapterIssueAsset) {
        return <SoftBox
            display={"flex"}
            flexDirection={"column"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            py={{xs: 1, sm: 1}}
        >
            <SoftBox
                display={"flex"}
                sx={{
                    width: "100%",
                    wordBreak: "break-word",
                }}
            >
                <SoftTypography
                    variant="caption"
                    sx={{
                        mr: 1,
                    }}
                >Źródło:{" "} </SoftTypography>
                <SoftTypography
                variant="caption"
                color={"info"}
            >
                {currentElementIssueItemObj.asset_frontend_url}
            </SoftTypography>
            </SoftBox>
            <SoftBox
                display={"flex"}
            >
                <SoftTypography
                    variant="caption"
                    sx={{
                        mr: 1,
                    }}
                >Strona:{" "}</SoftTypography>
                <SoftTypography
                    variant="caption"
                    color={"info"}
                >
                    <SoftBox
                        component="a"
                        href={currentElementIssueItemObj.asset_source_full_url}
                        title={`${currentElementIssueItemObj.asset_source_category_desc}: ${currentElementIssueItemObj.asset_source_desc}`}
                        target="_blank"
                        sx={{
                            color: "info.main",
                        }}
                    >
                        {currentElementIssueItemObj.asset_source_desc}
                    </SoftBox>
                </SoftTypography>
            </SoftBox>
        </SoftBox>;
    }

    if (currentElementIssueItemObj?.local_http_status != '404') {
        return currentElementIssueItemObj ? <SoftBox
            mx={{xs: 1, sm: 2}}
            my={{xs: 2, sm: 3}}
            px={{xs: 2, sm: 3}}
            py={{xs: 1, sm: 2}}
            sx={{
                borderLeftWidth: "3px",
                borderLeftStyle: "solid",
                borderLeftColor: contentType2Color[assetType],
            }
            }
        >
            {
                <>
                    <SoftBox
                        display="flex"
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                    >
                        <SoftBox display="flex" className="divTableHeading">
                            <SoftBox className="divTableHead">
                                <SoftTypography variant="body2">
                                    <span>
                                        {currentElementIssueItemObj.item_format_heading}:
                                    </span>
                                    {currentElementIssueItemObj.assign_title}
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            justifyContent={"center"}
                        >
                            <SoftBox
                                display={"flex"}
                            >
                                <SoftTypography variant="body2">
                                    {currentElementIssueItemObj.assign_comment}
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox
                                display={"flex"}
                            >
                                <SoftTypography variant="body2">
                                    {getContent(currentElementIssueItemObj,
                                        assetType
                                    )}
                                </SoftTypography>
                            </SoftBox>

                        </SoftBox>
                        {getAssetFrameFooterTSX(currentElementIssueItemObj)}
                    </SoftBox>

                </>
            }
        </SoftBox> : null;
    } else {
        return null;
    }
}
