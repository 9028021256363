import React, {Suspense, lazy} from "react";

const ElementClubTutorialRow = lazy(() => import("./ElementClubTutorialRow")); // page_item_id: 233
const ElementSiteTutorialRow = lazy(() => import("./ElementSiteTutorialRow")); // page_item_id: 120
const ElementTutorialCourseSelectRow = lazy(() => import("./ElementTutorialCourseSelectRow")); // page_item_id: 7
const ElementTutorialMainpageRow = lazy(() => import("./ElementTutorialMainpageRow")); // page_item_id: 1
const ElementSiteTutorialNewsletterRow = lazy(() => import("./ElementSiteTutorialNewsletterRow")); // page_item_id: 2
import Emptybox from "../../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../../AfdLoadingFullpage";
import AfdContainerCard from "../../../AfdContainerCard";
import SoftBox from "../../../../components/SoftBox";
import useCHContainerData from "../../../../hook/useCHContainerData";
import IPropContainer from "../../../../type/IPropContainer";

import RokPageTitle from "../../../../title/RokPageTitle";
import GetWelcome from "../../../sourcerer/GetWelcome";
import parse, {HTMLReactParserOptions} from "html-react-parser";
import SoftTypography from "../../../../components/SoftTypography";
import {setTextParagraphToBr, setTextSquareTagToDiv} from "../../../../util/UtilReact";
import GetBusymumCalendar from "../../../sourcerer/GetBusymumCalendar";
import ICtxGlobalConfig from "../../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../../hook/useCHGetContextSoftUI";
import IElementTutorialBodyRow from "../../../../type/tutorial/IElementTutorialBodyRow";
import useCHSourcererData from "../../../../hook/useCHSourcererData";
import {IElementGlobalConfig} from "../../../../type/gloibal_config/IElementGlobalConfig";
import IContainerQuery from "../../../../type/IContainerQuery";
import {
    getCollapseMoreBarSmallTSX,
    getWelcomeInstructorInfoTSX
} from "../../../../util/UtilReactElement";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import SoftBadge from "../../../../components/SoftBadge";
import IContainerTutorialList from "../../../../type/tutorial/IContainerTutorialList";
import {IContainerTutorialBody} from "../../../../type/tutorial/IContainerTutorialBody";
import SoftAvatar from "../../../../components/SoftAvatar";
import IUserInfo from "../../../../type/IUserInfo";
import { getGlobalConfigParserOption } from "../../../../util/UtilReactParser";


/*ng-click="psedudoScrollNextPage()"*/

//{containerRow.post_count}

export interface IElementProp {
    containerTutorialInfoObj: IContainerTutorialBody,
    row: IElementTutorialBodyRow,
    userInfoObj: IUserInfo,
    controllerInfoObj?: IContainerQuery,
    index: number,
    mainpageImageId: number,
    parserOptions: HTMLReactParserOptions,
    elementRefetchFunction?: () => void,
}


const GetSiteContainerTutorialBody = ({
                                          controllerInfoObj: controllerInfoObj,
                                          userInfoObj: userInfoObj,
                                          paramObj: paramObj,
                                          redirectionObj: redirectionObj
                                      }: IPropContainer) => {


    const components: any = {
        ElementClubTutorialRow,
        ElementSiteTutorialRow,
        ElementTutorialCourseSelectRow,
        ElementTutorialMainpageRow,
        ElementSiteTutorialNewsletterRow
    }
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    var message_id: number | null = controllerInfoObj.const_param1 ? Number(controllerInfoObj.const_param1) : null;
    if (!message_id) {
        message_id = (redirectionObj?.object_message_id ? redirectionObj.object_message_id : null);
    }
    if (!message_id && controllerInfoObj.page_item_id == 120) {
        message_id = paramObj.position_param8 ? Number.parseInt(paramObj.position_param8) : null;
    }
    var promotion_code = paramObj.position_param1;
    var mainpageImageId: number = paramObj.position_param2 ? Number.parseInt(paramObj.position_param2) : -1;
    var code = promotion_code;
    /*ng_param_array['client_ng_object2_id'] = 'final_';*/ // TODO

    /*var confirm_query = "call set_tutorial_visit_insert(" + "" + message_id + "" + ", " + "" + user_id + "" + ")";

    if (!promotion_code) promotion_code = 'poradnik15';*/

    // TODO: komponent social_tutorial_like w ógóle nie wygenerowany

    if (controllerInfoObj.element1CamelName !== undefined) {
        var ElementComponent = components[controllerInfoObj.element1CamelName];
        var [elementTutorialRowObjList, getElementTutorialRowObjList, containerTutorialInfoObj, setContainerTutorialInfoObj,
            elementRefetchFunction
        ]
            = useCHContainerData<IElementTutorialBodyRow, IContainerTutorialBody>(
            controllerInfoObj,
            {
                object2_id: mainpageImageId,
                message_id: message_id,
            },
            {
                object2_id: mainpageImageId,
                message_id: message_id,
            }
        );
    }

    const [elementGlobalValueListObj] = useCHSourcererData<IElementGlobalConfig>(
        'get_course_rules',
        'sql_query3',
        {},
    );

    function getContainerContentPublishedYes() {

        /* Podmiana GLOBAL CONFIG w treści */
        const parserOptions = getGlobalConfigParserOption(elementGlobalValueListObj,
            (text: any) => {
                return <SoftTypography
                    display={"inline"}
                    variant={"body2"}
                    fontWeight={"medium"}
                >{text}</SoftTypography>
            }
        );


        /*wykorzystywane przy NEWSLETTER gdzie rozbudowane wstępy*/
        function getTutorialIntroTSX(
            team_letter: string,
            tutorialIntroText: any,
            ending: any,
            title: any,
            controllerInfoObj: IContainerQuery,
            instructorInfoPosition: "footer" | "tooltip",
        ) {
            let instructor: {
                image: any;
                full_name: string;
                rank_title: string
                user_id: number
            } = {
                image: containerTutorialInfoObj.instructor_image,
                full_name: containerTutorialInfoObj.instructor_full_name,
                rank_title: containerTutorialInfoObj.instructor_rank_title,
                user_id: containerTutorialInfoObj.instructor_user_id,
            }

            return <Card>
                <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={2}>

                    {/*instructor info*/}
                    <SoftBox display={globalConfigParamObj.isMobile ? "block" : "flex"}
                             mr={2}
                    >
                        {/*avatar - za pomocą SoftBox aby z Tooltip*/}
                        <SoftBox
                            display={"block"}
                            sx={{
                                float: "left",
                            }}
                        >
                            <SoftBox
                                data-for='test'
                                data-tip=''
                                component="img"
                                src={instructor.image}
                                alt="message-image"
                                borderRadius="lg"
                                shadow="md"
                                /*width="100%"*/
                                maxWidth={globalConfigParamObj.isMobile ? "20vw" : "8vw"}
                                maxHeight={globalConfigParamObj.isMobile ? "20vw" : "8vw"}
                                height="auto"
                                display="inherit"
                                mr={2}
                            /></SoftBox>
                        <SoftBox
                                 flexDirection="column"
                                 justifyContent={globalConfigParamObj.isMobile ? "flex-start" : "center"}
                                 ml={1}>
                            {/*<SoftTypography variant="h6">{title}</SoftTypography>*/}
                            <SoftBox mt={1}
                                     mb={{xs: 0.5, md: 1, lg: 2}}
                                     lineHeight={0}
                                     display="inline"
                            >
                                <SoftTypography
                                    pb={1}
                                    variant="button" fontWeight="regular" color="text">
                                    <SoftTypography
                                        variant={globalConfigParamObj.isMobile ? "body2" : "body1"}
                                        fontWeight={"light"}
                                        lineHeight={1.5}
                                    >{tutorialIntroText}</SoftTypography>
                                </SoftTypography>
                                <SoftTypography
                                    pb={1}
                                    variant="button" fontWeight="regular" color="text">
                                    {ending}
                                </SoftTypography>

                            </SoftBox>

                            {/*instructor rank_title*/}
                            {getWelcomeInstructorInfoTSX(
                                instructorInfoPosition,
                                instructor
                            )}

                        </SoftBox>

                    </SoftBox>
                </SoftBox>
            </Card>;
        }

        let row = containerTutorialInfoObj;
        let introTSX: React.ReactNode;
        let endingTSX: React.ReactNode;
        let tutorialIntroText = row.intro && row.intro.length > 3 ? parse(setTextSquareTagToDiv(setTextParagraphToBr(row.intro)), parserOptions) : <></>;
        if (row.intro && row.intro.length > 3) {
            /*introTSX = <><GetWelcome
                team_letter={row.instructor_letter}
                content={<SoftTypography
                    variant={globalConfigParamObj.isMobile ? "body2" : "h5"}
                    fontWeight={"light"}
                    align={"center"}
                    lineHeight={1.5}
                >{tutorialIntroText}</SoftTypography>}
                ending={''}
                title={''}
                controllerInfoObj={controllerInfoObj}
                instructorInfoPosition={"tooltip"}
            /></>;*/
            introTSX = getTutorialIntroTSX(
                row.instructor_letter,
                tutorialIntroText,
                '',
                '',
                controllerInfoObj,
                "tooltip"
            );
        }

        if (row.ending && row.ending.length > 3) {
            endingTSX = <GetWelcome
                team_letter={row.instructor_letter}
                content={parse(row.ending)}
                ending={''}
                title={''}
                controllerInfoObj={controllerInfoObj}
                instructorInfoPosition={"tooltip"}
            />;
        }

        /* Ilość kroków wstawiana do treści */
        // TODO
        /*  main_text = str_replace("[[TUTORIAL_COUNT]]", row_count,    main_text);

          main_text = str_replace("[[CONTENT_TITLE]]", row['content_title'],    main_text);
          main_text = str_replace("[[INTRO_IMAGE_PATH]]", row['intro_image_path'],    main_text);
          */


        /* Poradnik na stronie oraz jednocześnie NEWSLETTER / HOPELETTER
            - nadal na tyle podobne do sibie,
            że nie ma sensu replikować tych komponentów */
        if (controllerInfoObj.containerName === 'CONTAINER_ARTICLE_TUTORIAL_BODY') {
            return (
                <>
                    <SoftBox mb={3} p={1}>
                        <SoftTypography
                            variant={globalConfigParamObj.isMobile ? "h4" : "h2"}
                            textTransform="capitalize"
                            fontWeight="bold"
                        >
                            {row.content_title}
                        </SoftTypography>
                    </SoftBox>
                    <SoftBox
                    ><GetWelcome
                        team_letter={row.instructor_letter}
                        content={<SoftTypography
                            variant={globalConfigParamObj.isMobile ? "body2" : "h5"}
                            fontWeight={"light"}
                            lineHeight={1.5}
                        >{tutorialIntroText}</SoftTypography>}
                        ending={''}
                        title={''}
                        controllerInfoObj={controllerInfoObj}
                        instructorInfoPosition={"tooltip"}
                    /></SoftBox>
                    {
                        elementTutorialRowObjList.map((elementTutorialRowObj: IElementTutorialBodyRow,
                                                       index: number
                        ) => (
                            <ElementComponent
                                key={elementTutorialRowObj.client_track_id}
                                containerTutorialInfoObj={containerTutorialInfoObj}
                                userInfoObj={userInfoObj}
                                controllerInfoObj={controllerInfoObj}
                                index={index}
                                row={elementTutorialRowObj}
                                parserOptions={parserOptions}
                                elementRefetchFunction={elementRefetchFunction}
                            />
                        ))
                    }
                    <SoftBox>{endingTSX}</SoftBox>
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_TUTORIAL_NEWSLETTER') {
            return (
                <>
                    <SoftBox mb={{xs: 1, md: 2, lg: 3}}
                             px={1}
                             py={1}
                    >
                        <SoftTypography
                            variant={globalConfigParamObj.isMobile ? "h4" : "h2"}
                            textTransform="capitalize"
                            fontWeight="bold"
                        >
                            {row.content_title}
                        </SoftTypography>
                    </SoftBox>
                        <SoftBox
                        >{introTSX}</SoftBox>


                    {
                        elementTutorialRowObjList.map((elementTutorialRowObj: IElementTutorialBodyRow,
                                                       index: number
                        ) => (
                            <ElementComponent
                                key={elementTutorialRowObj.client_track_id}
                                containerTutorialInfoObj={containerTutorialInfoObj}
                                userInfoObj={userInfoObj}
                                controllerInfoObj={controllerInfoObj}
                                index={index}
                                row={elementTutorialRowObj}
                                parserOptions={parserOptions}
                                elementRefetchFunction={elementRefetchFunction}
                            />
                        ))
                    }
                    <SoftBox>{endingTSX}</SoftBox>
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_TUTORIAL_BUSYMUM_MODE') {
            return (
                <>
                    {/*                    <RokPageTitle>{row.content_title}</RokPageTitle>*/}

                    <SoftBox>{introTSX}</SoftBox>
                    {
                        elementTutorialRowObjList.map((elementTutorialRowObj: IElementTutorialBodyRow,
                                                       index: number
                        ) => {
                            return (

                                <><ElementComponent
                                    key={elementTutorialRowObj.client_track_id}
                                    containerTutorialInfoObj={containerTutorialInfoObj}
                                    userInfoObj={userInfoObj}
                                    controllerInfoObj={controllerInfoObj}
                                    index={index}
                                    row={elementTutorialRowObj}
                                    parserOptions={parserOptions}
                                    elementRefetchFunction={elementRefetchFunction}
                                />
                                    {
                                        index == 1 ?
                                            <><SoftBox
                                                key={index}
                                                px={{xs: 0, sm: 0, md: 5, lg: 9, xl: 15}}
                                                py={{xs: 0, sm: 0, md: 2, lg: 5, xl: 5}}
                                            >
                                                <GetBusymumCalendar
                                                    controllerInfoObj={controllerInfoObj}
                                                    userInfoObj={userInfoObj}
                                                    paramObj={paramObj}
                                                /></SoftBox></>
                                            : null
                                    }</>

                            )
                        })
                    }
                    <SoftBox>{endingTSX}</SoftBox>
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_TUTORIAL_COURSE_SELECT') {
            return (
                <>
                    <SoftBox>{introTSX}</SoftBox>
                    {
                        elementTutorialRowObjList.map((elementTutorialRowObj: IElementTutorialBodyRow,
                                                       index: number
                        ) => (
                            <ElementComponent
                                key={elementTutorialRowObj.client_track_id}
                                containerTutorialInfoObj={containerTutorialInfoObj}
                                userInfoObj={userInfoObj}
                                controllerInfoObj={controllerInfoObj}
                                index={index}
                                row={elementTutorialRowObj}
                                parserOptions={parserOptions}
                                elementRefetchFunction={elementRefetchFunction}
                            />
                        ))
                    }
                    <SoftBox>{endingTSX}</SoftBox>
                </>
            );
        }
        if (controllerInfoObj.containerName === 'CONTAINER_SITE_TUTORIAL_MAINPAGE') {
            return (
                <>
                    <SoftBox
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        mb={{xs: 4, sm: 4, md: 4, lg: 5, xl: 5}}
                    >{introTSX}</SoftBox>
                    <>{
                        elementTutorialRowObjList.map((elementTutorialRowObj: IElementTutorialBodyRow,
                                                       index: number
                        ) => (
                            <ElementComponent
                                key={elementTutorialRowObj.client_track_id}
                                containerTutorialInfoObj={containerTutorialInfoObj}
                                userInfoObj={userInfoObj}
                                controllerInfoObj={controllerInfoObj}
                                index={index}
                                row={elementTutorialRowObj}
                                parserOptions={parserOptions}
                                elementRefetchFunction={elementRefetchFunction}
                            />
                        ))
                    }</>
                </>
            );
        }
    }

    function getContainerContentPublishedNo() {
        return (
            <>
                <SoftBox
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    mb={{xs: 4, sm: 4, md: 4, lg: 5, xl: 5}}
                >
                    <SoftTypography
                        variant={'h4'}
                        color={"error"}
                    >Tutorial nieopublikowany: {containerTutorialInfoObj.tip_title}</SoftTypography>
                </SoftBox>
                <>
                </>
            </>
        );
    }

    return (
        <>
            <Suspense fallback={<AfdLoadingFullpage
                componentCategory="AfdController_Suspense"
                controllerInfoObj={controllerInfoObj}
            />}>
            </Suspense>

            {
                (elementTutorialRowObjList?.length > 0 && containerTutorialInfoObj) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementTutorialRowObjList}
                        >
                            {
                                (containerTutorialInfoObj.published == 1 ||
                                        controllerInfoObj.page_item_id == 120
                                ) ?
                                getContainerContentPublishedYes() :
                                    getContainerContentPublishedNo()

                            }
                        </AfdContainerCard>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementTutorialRowObjList}
                    />)
            }
        </>
    );
}

export default GetSiteContainerTutorialBody;

