import SoftButton from "../components/SoftButton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import SoftAvatar from "../components/SoftAvatar";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import DoNotDisturbOutlinedIcon from "@mui/icons-material/DoNotDisturbOutlined";
import {Link} from "react-router-dom";

export function getDatatableBackendLinkInfoTSX(
    linkTitle: string,
    linkUrl: string) {
    return <SoftButton
        href={linkUrl} // TODO
        target={"_blank"}
        size={"small"}
        variant={"text"}
        color={"info"}
        startIcon={<ArrowForwardIosIcon
            fontSize={"medium"}
            sx={{
                marginBottom: "0.2em",
            }}
        />
        }
    >{linkTitle}</SoftButton>;
}

export function getDatatableCourseAvatarTSX(
  image_thumb: string,
  category_id: string,
  description_ubar: string,
  description: string) {
  return (
    <SoftBox display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <SoftBox
        component="img"
        src={image_thumb}
        alt={description_ubar}
        sx={{ clear: "both", maxWidth: "35px", maxHeight: "35px" }}
      />
      <SoftTypography variant={"caption"} display={"block"}>
        <Link to={`/course/${category_id}`}>
          {description_ubar}
        </Link>
        <br />
        {description}
      </SoftTypography>
    </SoftBox>
  );
}


export function getDatatableBackendGroupInfoTSX(course_description: string, group_name: string) {
    return <SoftBox
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        sx={{
            textOverflow: "ellipsis",
            maxWidth: "25ch",
        }
        }
    >
        <SoftTypography
            variant={"caption"}
            display={"block"}
        >{course_description}</SoftTypography>
        <SoftTypography
            variant={"caption"}
            display={"block"}
        >{group_name}</SoftTypography></SoftBox>;
} /*ng-click="psedudoScrollNextPage()"*/
export function getDatatableBackendIsInstrTSX(isInstr: boolean) {
    return <SoftTypography
        variant={"caption"}
    >{isInstr ?
        <SoftBox
            component={"a"}
            title={"Kurs w wersją Z instruktorem"}
        ><SupportAgentOutlinedIcon
            fontSize={"large"}
        /></SoftBox> :
        <SoftBox
            component={"a"}
            title={"Kurs w wersją Bez instruktora. Nie odpowiadaj na posty. Mama może dyskutować na forum, ale nie otrzymuje wsparcia. " +
                "Jeżeli dokupi wersję Z instruktorem - wszystkie jej posty - nawet przeszłe zostaną oznaczone jako pozostałe do odpowiedzi."}
        >
            <DoNotDisturbOutlinedIcon
                fontSize={"large"}
                color={"disabled"}
            />
        </SoftBox>
    }</SoftTypography>;
}

export function getDatatableBackendUserProfileTSX(
    picture_link_thumb: string,
    username: string) {
    return <>
        <SoftAvatar
            src={picture_link_thumb}
            alt={username}
            sx={{width: 24, height: 24}}
        />
        <SoftTypography
            variant={"caption"}
        >{username}</SoftTypography>
    </>;
}

export function getClubDatatableUserProfile(
    username: string,
    picture_link_thumb: string,
    isMobile?: boolean,
) {
    return <SoftBox
        display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"}
        justifyContent={"center"}
    >
        <SoftAvatar
            src={picture_link_thumb}
            alt={username}
        />
        <SoftTypography
            display={"block"}
            variant={isMobile ? "caption" : "caption"}
            sx={isMobile ?
                {
                    textOverflow: "ellipsis",
                    width: "9ch",
                } : {}}
        >{username}</SoftTypography></SoftBox>;
}

/*ng-click="psedudoScrollNextPage()"*/
export function getDatatableClubButtonGoToPost(post_url: string,
                                               link_title: string = "Idź do posta"
                                               ) {
    return <><SoftButton
        component={Link}
        to={post_url}
        variant={"outlined"}
        color={"primary"}
        size={"small"}
    >{link_title}</SoftButton></>;
}
