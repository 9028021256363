import RestApiService, { ACCOUNT_CREATE_URL, API_URL, AUTH_URL } from "./RestApiService";
import IAxiosPostData from "../../type/axios/IAxiosPostData";
import { IUtmCampaign, IUtmMedium, IUtmPlacement, IUtmSource } from "../../type/IUtm";
import { bin2hex } from "../../util/Util";


export default class RestApiAuthorizationService extends RestApiService {

    constructor() {
        super();
    }


    static setPostSiteLogin(
        username: string,
        password: string,
        remember: boolean,
    ): IAxiosPostData {

        let loginFormReturn = "B:aHR0cHM6Ly93d3cuYWthZGVtaWEtZm90b2dyYWZpaS1kemllY2llY2VqLnBsL2luZGV4LnBocD9vcHRpb249Y29tX2NvbnRlbnQmYW1wO0l0ZW1pZD02NjkmYW1wO2lkPTYwNCZhbXA7bGFuZz1wbCZhbXA7dmlldz1hcnRpY2xl";
        let loginFormSecurity = "cbm_54fef581_4ccd2a7b_c59d808343c95a51345c1d1bb01ca055";

        let postDataObj: IAxiosPostData = {
            url: AUTH_URL,
            postData: {
                task: 'loginJSON',
                op2: 'login',
                lang: 'polish',
                force_session: '1',
                return: loginFormReturn,
                message: '0',
                loginfrom: 'loginmodule',
                cbsecuritym3: loginFormSecurity,
                username: username,
                passwd: password,
                remember: remember,
            }
        };

        return postDataObj;
    }


    static setPostSiteLogout(
    ): IAxiosPostData {

        let loginFormReturn = "B:aHR0cHM6Ly93d3cuYWthZGVtaWEtZm90b2dyYWZpaS1kemllY2llY2VqLnBsL2luZGV4LnBocA==";
        let loginFormSecurity = "2d5565a0_714ce41f_37164a56f65517d53c702e5d02c89dc0";

        let postDataObj: IAxiosPostData = {
            url: AUTH_URL,
            postData: {
                task: 'logoutJSON',
                op2: 'logout',
                lang: 'polish',
                return: loginFormReturn,
                message: '0',
                cbsecuritym3: loginFormSecurity,
            }
        }

        return postDataObj;
    }

    /* identifyCookieName - nie przekazywane de facto ponieważ pobierane z COOKIE, ale aby nadać kontekst */
    static setPostSiteIdentify(
        identifyCookieName?: string,
    ): IAxiosPostData {

        let postDataObj: IAxiosPostData = {
            url: AUTH_URL,
            postData: {
                task: 'identifyJSON',
            }
        }
        return postDataObj;
    }


    /*import { useMutation } from 'react-query';*/
   /* const [identifyJSON, { status }] = useMutation(() => {
    const postData = { task: 'identifyJSON' }
    const options = {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
            'Content-Type': 'application/json',
            'Cookie': ${cookieName}=someValue
        }
    }
    return fetch(AUTH_URL, options).then(response => response.json());
});*/


    static setPostAccountCreate(
        email: string,
        cbsecuritym3: string,
        cbrasitway: string,
        id: number,
        gid: number,
        emailpass: number,
        username: string,
        password: string,
        password__verify: string,
        cb_modelaparatu: string,
        cb_freshmail_chat: number,
        cb_freshmail_newsletter: number,
        cb_freshmail_course: number,
        cb_law_commercial: number,
        cb_law_electronic: number,
        acceptedterms: number,
        utm_source?: IUtmSource,
        utm_medium?: IUtmMedium,
        utm_campaign?: IUtmCampaign,
        utm_content?: string,
        utm_placement?: IUtmPlacement,
        ) {

        let option: string = 'com_comprofiler';
        /*let task: string = 'saveregisters';*/

      /*  return array(
            'status' => 'error',
            'message' => 'post_count_0',
            'message_desc' => 'Brak danych logoowania. Skontaktuj się z administratorem.',
            'data' => $_POST
        );
          array(
                'status' => 'error',
                'message' => 'spoof_check_error',
                'message_desc' => "Błąd weryfikacji CRC. Skontaktuj się z administratorem."
                 'data' => array(
                    'result_error' => $resultError,
                )
            );
        */

        let postDataObj: IAxiosPostData = {
            url: ACCOUNT_CREATE_URL,
            postData: {
                email: email,
                cbsecuritym3: cbsecuritym3,
                cbrasitway: cbrasitway,
                id: id,
                gid: gid,
                emailpass: emailpass,
                option: option,
                username: username,
                password: password,
                password__verify: password__verify,
                cb_modelaparatu: cb_modelaparatu,
                cb_freshmail_chat: cb_freshmail_chat,
                cb_freshmail_newsletter: cb_freshmail_newsletter,
                cb_freshmail_course: cb_freshmail_course,
                cb_law_commercial: cb_law_commercial,
                cb_law_electronic: cb_law_electronic,
                acceptedterms: acceptedterms,
                utm_source: utm_source,
                utm_medium: utm_medium,
                utm_campaign: utm_campaign,
                utm_content: utm_content,
                utm_placement: utm_placement,
            }
        };

        return postDataObj;
    }

    static setUserPasswordReset(
        email: string,
        user_or_password: string,
        ) {
        var afdEmailHex = bin2hex(encodeURIComponent(email));
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'user_password_reset',
                user_or_password: user_or_password,
                email_encode: afdEmailHex,
            }
        };
        return postDataObj;
    }

    static setUserAccountRestore(
        user_id: number,
        key: string,
    ) {
        let postDataObj: IAxiosPostData = {
            url: API_URL,
            postData: {
                custom_call: 'user_account_restore',
                user_id: user_id,
                key: key,
            }
        };
        return postDataObj;
    }



}
