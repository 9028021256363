import axios from "axios";

import { debugLog } from "../../util/UtilReact";

import { getURLParameter } from "../../util/Util";
import IAxiosPostData from "../../type/axios/IAxiosPostData";

// export const API_URL = 'https://apygfpqsvp.us-east-1.awsapprunner.com/restapi/xxx/';
//
/*export const API_URL = 'https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/afd_router/index.php';
// http://localhost:8004/restapi/xxx/
//https://cmxanrjthr.eu-central-1.awsapprunner.com/restapi/xxx/
//http://91.204.160.75:8000/restapi/xxx/
//https://mamazaparatem.pl:8000/restapi/xxx/

export const AUTH_URL = "https://www.akademia-fotografii-dzieciecej.pl/component/option,com_comprofiler/";
export const ACCOUNT_CREATE_URL = "https://www.akademia-fotografii-dzieciecej.pl/component/option,com_comprofiler/task,saveregistersJSON/";
export const ML_SERVICE_URL = "http://localhost:8004/backend-openai/";*/

/*"API_URL": "https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/afd_router/index.php",*/

/*
https://apygfpqsvp.us-east-1.awsapprunner.com/restapi/xxx/
https://www.akademia-fotografii-dzieciecej.pl//components/php_restapi/afd_router/index.php
*/


// @ts-ignore
import restapi_url_config from "../../restapi_url_config.json";
import { isDev } from "../../util/isDev";

export const API_URL = restapi_url_config.API_URL[isDev() ? 'dev' : 'prod'];
export const AUTH_URL = restapi_url_config.AUTH_URL[isDev() ? 'dev' : 'prod'];
export const ACCOUNT_CREATE_URL = restapi_url_config.ACCOUNT_CREATE_URL[isDev() ? 'dev' : 'prod'];
export const ML_SERVICE_URL = restapi_url_config.ML_SERVICE_URL[isDev() ? 'dev' : 'prod'];

//const SA_LOGIN = 'admin';
//const SA_PASSWORD = 'niuPheu2ahho5aiv';

//headers.append('Authorization', 'Basic ' + btoa(SA_LOGIN + ":" + SA_PASSWORD));


/*mode: 'no-cors' won’t magically make things work. In fact it makes things worse, because one effect it has is to tell browsers,
“Block my frontend JavaScript code from seeing contents of the response body and headers under
all circumstances.” Of course you never want that.
https://stackoverflow.com/questions/43262121/trying-to-use-fetch-and-pass-in-mode-no-cors

https://stackoverflow.com/questions/42406856/how-to-get-header-fields-in-a-cors-request-using-fetch-api
*/

//"Access-Control-Request-Method": 'GET',
//"Access-Control-Request-Headers": 'Origin,X-Requested-With,Content-Type,Accept',
const headers: any  = {
    "Content-Type": 'text/html'
};
//?function_name=get_site_opinion_table&page_item_id=206&sequence=80&object_id=&object2_id=-1&limit_from=0&day_ago_from=0
class RestApiService {
    static init() {
        axios.defaults.baseURL = API_URL;
        axios.defaults.headers = headers;
        axios.defaults.withCredentials = false;
        /*axios.defaults.mode = 'cors';*/
        axios.defaults.method = 'GET';
    }

    // TODO: promise powinno zwracać z TYPEM
    static getPromise(url: string,
                      method: "GET" | "POST" = 'GET',
                      postDataObj?: IAxiosPostData,
                      ): Promise<any>    {
        url = (url + '&r=' + Math.floor(Math.random() * 10000));
        // @ts-ignore
        if (method === 'POST') {
            /*PHP doesn't support JSON as a data format for populating $_POST.
            It only supports the machine-processable formats natively supported by HTML forms:
                application/x-www-form-urlencoded
            multipart/form-data
            https://stackoverflow.com/questions/41457181/axios-posting-params-not-read-by-post
                In a browser, you can use the URLSearchParams API as follows:
            var params = new URLSearchParams();
            params.append('param1', 'value1');*/

            if (postDataObj) {
                debugLog('getPromise() POST url: ' + postDataObj.url + " Data: " + JSON.stringify(postDataObj.postData));

                /* BUGFIX - comprofiler przjmuje POST w formie zmiennych */
                    let formPostDataUrl = new URLSearchParams(postDataObj.postData);
                    let formPostDataJSON = JSON.stringify(postDataObj.postData);

                    let formPostData = postDataObj.url.includes("com_comprofiler") ? formPostDataUrl : formPostDataJSON;
                return axios.post(postDataObj.url,
                    formPostData,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    }
                );
            } else {
                throw new Error('postDataObj is undefined');
            }
        } else {
            // @ts-ignore
            debugLog('getPromise: '+getURLParameter('controller_name', url) + ' ' + url);
            return axios.get(url);
        }
    }


}

export default RestApiService;