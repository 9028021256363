import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
import IUserInfo from "../../../type/IUserInfo";
import SoftBox from "../../../components/SoftBox";
import {IElementForumChapterIssueAsset} from "./SetForumChapterIssueAssetReplace";
import {IElementForumChapterContent} from "../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import IContainerQuery from "../../../type/IContainerQuery";
import IParamObj from "../../../type/IParamObj";
import parse from "html-react-parser";
import {IElementForumChapterIssuePartReplace} from "./SetForumChapterIssuePartReplace";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import SoftTypography from "../../../components/SoftTypography";

export interface IProp {
    content: string | React.ReactNode;
    elementChapterIssueObj: IElementForumChapterContent;
    paramObj: IParamObj;
    elementIssueGlossaryObjList: IElementForumChapterIssueGlossaryReplace[];
}

export interface IElementForumChapterIssueGlossaryReplace {
    image_big_thumb_src: string;
    image_mid_thumb_src: string;
    image_light_thumb_src: string;
    image_thumb_src: string;
}

const SetForumChapterIssueGlossaryReplace = (
    {
        content,
        elementChapterIssueObj,
        paramObj,
        elementIssueGlossaryObjList
    }: IProp) => {

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    let currentElementIssueGlossaryObj: IElementForumChapterIssueGlossaryReplace | undefined = elementIssueGlossaryObjList.find((item: IElementForumChapterIssueGlossaryReplace) => {
        /* TODO
        return (item.image_big_thumb_src !== "" && item.image_big_thumb_src !== null);*/
    });

    /*elementIssueGlossaryObjList.forEach((elementIssueGlossaryObj: IElementForumChapterIssueGlossaryReplace) => {
        content = content.replace('[[GLOSSARY_]]',
            `<div class="ELEMENT_FORUM_CHAPTER_ISSUE_GLOSSARY">
<!-- TODO {parse(elementIssueGlossaryObj)}-->
</div>`
        );
    });

    let templateTSX = <div className="divTable ELEMENT_PHPBB_COURSE_CHAPTER_ISSUE_GLOSSARY">
        <div className="divTableBody">
            <div className="divTableRow">
                <div className="divTableCell content_front_image">
                    <figure>
                        <img src="[[IMAGE_LIGHT_THUMB_SRC]]"
                             alt="Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone"/>
                        <figcaption>[[TITLE_CANON]]</figcaption>
                    </figure>
                </div>
            </div>
        </div>
    </div>;

    return content;*/

    function getContent(currentElementIssueGlossaryObj: IElementForumChapterIssueGlossaryReplace
    ) {
        if (currentElementIssueGlossaryObj) {
            return <>
                <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                >

                </SoftBox>
            </>;
        }
    }

    return currentElementIssueGlossaryObj ? <SoftBox
        mx={{xs: 1, sm: 2}}
        my={{xs: 2, sm: 3}}
        px={{xs: 2, sm: 3}}
        py={{xs: 1, sm: 2}}
        sx={{
            borderLeftWidth: "3px",
            borderLeftStyle: "solid",
        }
        }
    >
        {
            <>{getContent(currentElementIssueGlossaryObj)}</>
        }
    </SoftBox> : null;
};

export default SetForumChapterIssueGlossaryReplace;
