import React, {Suspense, useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../../hook/useCHSourcererData";
import CtxAppObj from "../../../../context/CtxApp";
import IContainerQuery from "../../../../type/IContainerQuery";
import IElementNewbbTopicPost from "../../../../type/newbb/IElementNewbbTopicPost";
import IUserInfo from "../../../../type/IUserInfo";
import SoftBox from "../../../../components/SoftBox";
import {is_numeric} from "../../../../util/Util";
import IGetPhpbbImageUrl from "../../../../type/newbb/IGetPhpbbImageUrl";
import SoftTypography from "../../../../components/SoftTypography";
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import {isDev} from "../../../../util/isDev";
import AfdInfiniteLoading from "../../../AfdInfiniteLoading";
import AfdSourcererLoading from "../../../AfdSourcererLoading";
import {extractFileNameAndExtension} from "../../../../util/UtilTS";
import SoftButton from "../../../../components/SoftButton";
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
// @ts-ignore
import ImgsViewer from "react-images-viewer";
import ICtxGlobalConfig from "../../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../../hook/useCHGetContextSoftUI";
import {getUrlParseDomainName} from "../../../../util/UtilReact";
import ICtxClubNewbb from "../../../../type/context/ICtxClubNewbb";
import CtxClubNewbb from "../../../../context/CtxClubNewbb";
import SoftAlert from "../../../../components/SoftAlert";
import Icon from "@mui/material/Icon";
import RestApiService from "../../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../../service/restapi/RestApiCommonService";
import {getPostTextTSX} from "../../../../util/UtilReactParser";
import ICtxReducerState from "../../../../type/reducer_state/ICtxReducerState";


interface IProps {
    userInfoObj: IUserInfo,
    elementPostObj: IElementNewbbTopicPost,
    url: string,
    mode: string,
    isPostFullwidth?: boolean,
    image_seq?: number | null,
    post_id?: number | null,
    elementPostReplyObj?: IElementNewbbTopicPost,
}

export function getImageZoomIconTSX(
    imageSrc: string,
    isViewerOpen: boolean,
    handleViewerCloseFunction: any,
    handleViewerOpenFunction: any,
    globalConfigParamObj: ICtxReducerState,

) {
    return <><ImgsViewer
        /*imgs={elementInstructorPortObjList.map(
            (portfolioObj: any) => ({"src": portfolioObj.image_big_thumb_src})
        )}*/
        imgs={[{"src": imageSrc}]
        }
        isOpen={isViewerOpen}
        onClose={handleViewerCloseFunction}
        currImg={0}
        backdropCloseable
    />
        <SoftBox
            color={"white"}
            onClick={handleViewerOpenFunction}
        ><ZoomInOutlinedIcon
            fontSize={globalConfigParamObj.isMobile ? "medium" : "large"}
        />
        </SoftBox></>;
}

export function getInternalGalleryCommentButtonTSX(
    catId: number,
    imageUrl: any
) {
    /*
       Wersja z dodatkowym odniesieniem do POSTA
        if (mode == 'FULL' && (!!post_id && post_id > 0)) {

    <a href="[[POST_URL]]" target="_blank"
       title="[[POST_LINK_TITLE]]"><img className="lozad"
                                        data-src="[[BIG_URL]]"
                                        alt="Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone"/></a>*/
    return <>
        {/*GP comment*/}
        {
            catId == 5 && <SoftBox
                pt={1}
                pb={0.5}
            >
                <SoftButton
                    component={Link}
                    to={imageUrl}
                    variant={"outlined"}
                    color={"info"}
                    size={"small"}
                    startIcon={<CollectionsOutlinedIcon/>}
                >
                    <SoftTypography
                        variant={"caption"}
                        color={"info"}
                    >
                        Skomentuj w GP...
                    </SoftTypography>
                </SoftButton>

                {/*{elementXtImageObjList[0].url}*/}
            </SoftBox>
        }
    </>;
}

export function getPhpbbImageExifInfoTSX(
  elementXtImageObjList: IGetPhpbbImageUrl[]
) {
    if (elementXtImageObjList[0].exif_aperture && elementXtImageObjList[0].exif_iso) {
        return (<SoftTypography
          variant={"caption"}
        >
            Przesłona: {elementXtImageObjList[0].exif_aperture}; {elementXtImageObjList[0].exif_iso} (pobrane ze
            zdjęcia)
        </SoftTypography>);
    } else {
        return null;
    }
}

function GetPhpbbImageUrl(
    {
        userInfoObj,
        elementPostObj,
        elementPostReplyObj,
        url,
        mode = 'DISCUSSION',
        isPostFullwidth = false,
        image_seq = null,
        post_id = null
    }: IProps
) {
    const appObj = React.useContext(CtxAppObj);
    var topic_id = elementPostObj.topic_id;

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const ctxNewbbFunctionObj: ICtxClubNewbb = useContext<ICtxClubNewbb>(CtxClubNewbb);
    let urlFilenameExtract = extractFileNameAndExtension(url).filename;
    const [imgsViewer, setImgsViewer] = useState<boolean>(false);
    const openImgsViewer: () => void
        = () => setImgsViewer(true);
    const closeImgsViewer: () => void
        = () => setImgsViewer(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

    /*static setDbLongTrackAdapterUrl(
            adapterClassName: string,
            procedureName: string,
            userId: number,
            id: number
    ): string {*/


    function getSqlQuery(url: string): "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | null {
        if (is_numeric(url)) {
            return 'sql_query4';

            /* Każde inne tekstowe SRC
            */
        } else {
            if (getPhpbbImageSrcIsInternal(url)) {

                /* Tylko dla nowych
                */
                if (topic_id > 42394) {
                    return 'sql_query5';
                }
            }
        }
        return null;
    }

    let sqlInitialXtQuery: "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | null = getSqlQuery(url);

    function get_phpbb_image_src_is_can_access(userId: number, categoryId: number, published: number, isInternal: boolean) {
        if (userId > 0 && published == 1) return true; /* for all logged in */
        if (categoryId == 5 && published == 1) return true; /* public */
        if (!isInternal) return false; /* not in the BUSINESS of publishing EXTERNAL IMAGES */
        return false;
    }

    function getPhpbbImageSrcIsInternal(imageUrl: string): boolean {
        if (
            (imageUrl.indexOf("jwallpapers_files/") !== -1 && imageUrl.indexOf("http") === -1) ||
            (imageUrl.includes("/jwallpapers_files/") && imageUrl.indexOf("http") === -1) ||
            (imageUrl.includes("akademia")
                && imageUrl.includes(".pl")
                && imageUrl.includes("/jwallpapers_files/")
                && imageUrl.includes("http"))
        ) {
            return true;
        } else {
            return false;
        }
    }

    /* Wybiera SQL_QUERY 5, fallback na QUERY6, jeżeli NUMERIC - QUERY4 */
    const [elementXtImageObjList,
        setElementXtImageObjList,
        refetchElementXtImageObjList,
        isElementXtImageObjListLoading
    ] = useCHSourcererData<IGetPhpbbImageUrl>(
        'get_phpbb_image',
        sqlInitialXtQuery ? sqlInitialXtQuery : "sql_query4",
        {
            image_src: btoa(url),
            image_filename: urlFilenameExtract,
            topic_id: topic_id,
        });

    /* Nie znaleziono w XT
    - próbuj wyliczyć w wyjątkowych przypadkach  -zmiana treści niezsynchronizowana jeszcze
    */
    //return "Cache HIT.";
    //print_r($row8);
    const [elementFallbackXxImageObjList,
        setElementFallbackXxImageObjList,
        refetchElementFallbackXxImageObjList,
        isElementFallbackXxImageObjListLoading
    ] = useCHSourcererData<IGetPhpbbImageUrl>(
        'get_phpbb_image',
        "sql_query6",
        {
            image_src: btoa(url),
            image_filename: urlFilenameExtract,
            topic_id: topic_id,
        },
        (!isElementXtImageObjListLoading && (elementXtImageObjList.length == 0))
    );

    /* Implement FALLBACK to set elementXtImageObjList */
    useEffect(() => {
            if (elementFallbackXxImageObjList.length > 0) {
                setElementXtImageObjList(elementFallbackXxImageObjList);
            }
        },
        [JSON.stringify(elementFallbackXxImageObjList)]
    );


    var [elementImagePostInfoObjList,
        setElementImagePostInfoObjList,
        refetchFunctionImagePostInfoObjList,
    ] = useCHSourcererData(
        'get_phpbb_image',
        "sql_query1",
        {
            post_id: post_id,
            image_id: elementXtImageObjList.length ? elementXtImageObjList[0].image_id : null,
        },
        (
            mode == 'FULL' && (!!post_id && post_id > 0))
    );


    /* Sprawdzanie, czy zewnętrzny
    */
    /* Wykorzystywane tylko na razie dla UNI HALF
    - systemowo generowanych
    */
    var imageIsInternal: boolean = getPhpbbImageSrcIsInternal(url);


    /* BUGFIX; Zdjęcia zewnęrzne nie mają żadnego zapytania do bazy AFD - wstaw URL do HTML
     */
    function getExternalImageTSX(url: string) {

        //TODO SQL: get_url_parse_domain_name('[[IMAGE_SRC]]') domain_url,
        if (userInfoObj.user_id == 7140) {
            return (
                <img src={url}/>
            );
        } else {

            /* W związku z prawami autorskimi nie możemy wstawiać na stronę zdjęć zewnętrznych
           - ponieważ nie mamy informacji o autorze i możliwe pomyłki lub atak
           - zdjęcia zewnętrzne do wyświetlenia (wywiady UNI muszą być wstawione jako ASSET)
           - wszystkie inne jako LINKi - bez względu czy przez kursanta czy instruktora
           */
            return (<SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                shadow={"md"}
                borderRadius={5}
                px={1}
                py={{xs: 0, sm: 0, md: 1}}
                mx={{xs: 0, sm: 0, md: 2}}
                my={{xs: 0, sm: 0, md: 2}}
                sx={{
                    backgroundColor: 'background.paper',
                }}
            >
                <SoftBox
                    px={2}
                >
                    {/*Link*/}
                    <SoftBox
                        component={"a"}
                        href={url}
                        alt={"Zdjęcie na serwerze wewnętrznym w domenie: " + getUrlParseDomainName(url)}
                        target="_blank">
                        <SoftTypography
                            variant={"caption"}
                        >Zdjęcie na serwerze zewnętrznym:</SoftTypography>{" "}
                        <SoftTypography
                            variant={"button"}
                            display={"block"}
                        >{getUrlParseDomainName(url)}</SoftTypography>
                        {/*<br></br>Kliknij, aby otworzyć w osobnym
                        oknie...*/}
                    </SoftBox>
                </SoftBox>
                <SoftBox
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    py={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}

                ><SoftButton
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<ZoomInOutlinedIcon/>}
                    href={url}
                    target={"_blank"}
                    /*onClick={() => {
                        window.location.href = url;
                    }
                    }*/
                >
                    Otwórz zdjęcie
                </SoftButton>
                </SoftBox>

            </SoftBox>);
        }
    }

    function getDeniedImageTSX() {
        /* Wewnęrzne: ACESS DENIED:
        - podaj powód
        - lepiej kolejne sporadyczne zapytanie niż odświerzać XT
        */
        if (elementXtImageObjList[0]['image_id']) {
            return (<SoftBox
                display="block"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                shadow={"md"}
                borderRadius={5}
                p={2}
                m={2}
                sx={{
                    backgroundColor: 'background.paper',
                }}
            >

                <div className="divTableFooting">
                    <div className="divTableFoot">{elementXtImageObjList[0].denied_reason_desc}</div>
                </div>

            </SoftBox>);
        }
    }


    function getContainerContent() {


        function getImageFooterNonpublicWarningTSX() {
            // Button handler
            let handleImageCategoryUpdate = () => {
                RestApiService.getPromise('', "POST",
                    RestApiCommonService.setDbLongTrackAdapterUrl(
                        'AfdDLTUserImageCategoryUpdate',
                        'set_user_image_category_update',
                        userInfoObj.user_id,
                        elementXtImageObjList[0].image_id,
                    ))
                    .then((response: any) => {
                            /* REST API musi zwrócić coś, aby uznane za sukces, zwraca TRUE */
                            if (response?.data) {
                                /* BUGFIX: refetch idzie od razu do CALLBACK aby nie robić warunkowości ze względu na PERF
                                - fallback wywołuje setter w useEffect
                                 */
                                refetchElementFallbackXxImageObjList();
                                // refetchElementXtImageObjList();
                            }
                        }
                    ).catch((error: any) => {

                    }
                );
            }

            /* Tylko jeżeli miejsce i USER wyświetlaj cokolwiek */
            if (ctxNewbbFunctionObj.storeNewbbUniForumInfoObj &&
                ctxNewbbFunctionObj.storeNewbbUniForumInfoObj?.forum_post_final_id == elementPostObj.post_id
                && (!elementPostReplyObj)
                && (userInfoObj.user_id == elementXtImageObjList[0].image_user_id
                    || userInfoObj.is_instructor == 1
                )
            ) {
                return <>
                    {
                        (elementXtImageObjList[0].category_item_deny_acl != '') ?
                            <SoftAlert color="error">
                                <Icon fontSize="medium"
                                >warning</Icon>&nbsp;&nbsp;
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                ><SoftTypography
                                    variant="caption"
                                    fontWeight="bold"
                                    color="light"
                                    display="inline"
                                    pb={1}
                                >Informacja tylko dla Ciebie:
                                </SoftTypography>
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <SoftTypography
                                            variant="caption"
                                            fontWeight="regular"
                                            color="white"
                                            display="inline"
                                        >Jesteś autorką tego zdjęcia i zostało wyróżnione w tym wyzwaniu.
                                            Ponieważ jednak jest dodane do {elementXtImageObjList[0].category_title_d} (a nie GP) - nie możemy
                                            pokazać go na zewnątrz (BLOGu, mediach społecznościowych).
                                        </SoftTypography>
                                        <SoftBox
                                            pt={1}
                                        ><SoftButton
                                            variant="outlined"
                                            color="light"
                                            size="small"
                                            startIcon={<Icon fontSize="small">chevron_right</Icon>}
                                            onClick={
                                                handleImageCategoryUpdate
                                            }
                                        >
                                            Przenieś zdjęcie do Galerii WWW
                                        </SoftButton></SoftBox>
                                    </SoftBox>
                                </SoftBox>
                            </SoftAlert>

                            : <SoftBox>
                                <SoftAlert color="success">
                                    <Icon fontSize="medium"
                                    >done</Icon>&nbsp;&nbsp;
                                    <SoftBox
                                        display="flex"
                                        flexDirection="column"
                                    ><SoftTypography
                                        variant="caption"
                                        fontWeight="bold"
                                        color="light"
                                        display="inline"
                                        pb={1}
                                    >Doskonale! :
                                    </SoftTypography>
                                        <SoftBox
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <SoftTypography
                                                variant="caption"
                                                fontWeight="regular"
                                                color="white"
                                                display="inline"
                                            >Zdjęcie jest dodane do {elementXtImageObjList[0].category_title_d} i w ramach publikacji tego rozstrzygnięcia możemy pokazać Twój sukces światu.
                                                Na pewno to zrobimy!
                                            </SoftTypography>

                                        </SoftBox>
                                    </SoftBox>
                                </SoftAlert></SoftBox>
                    }
                </>;

            } else {
                /* brak jeżeli nie miejsce ani USER */
                return null;
            }
        }

        function getAllowedInternalImageTSX() {


            /*
            ADMING Testing
            if (mode == 'FULL' && (!!post_id && post_id > 0)) {
            if (mode == 'DISCUSSION') {
            * */

            return (
                <><SoftBox // nic ponieważ FLEX w getContent()
                    display={"block"}
                    flexDirection={"column"}
                    sx={{
                        clear: "none",
                        float: "left",
                    }}
                >
                    {
                        (elementXtImageObjList[0].cat_id != 6 || !isDev()) ? <SoftBox
                                display={"flex"}
                                position={"relative"}
                            ><SoftBox
                                component={"img"}
                                /*style="vertical-align: middle; max-width: 402px;"*/
                                /*className="lozad"*/
                                src={elementXtImageObjList[0].light_thumb}
                                sx={{
                                    maxWidth: "100%",
                                }}
                                alt="Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone"/>
                                <SoftBox
                                    position={"absolute"}
                                    bottom={"5%"}
                                    right={"5%"}
                                >
                                    {getImageZoomIconTSX(
                                        elementXtImageObjList[0].big_thumb,
                                        imgsViewer,
                                        closeImgsViewer,
                                        openImgsViewer,
                                        globalConfigParamObj
                                    )}
                                </SoftBox>
                            </SoftBox> :
                            <SoftBox
                            ><SoftTypography
                                variant={"caption"}
                                color={"error"}>Zdjęcie niedostępne w
                                DEV</SoftTypography>
                            </SoftBox>

                    }
                    {getInternalGalleryCommentButtonTSX(
                        elementXtImageObjList[0].cat_id,
                        elementXtImageObjList[0].url
                    )}

                    {/*Image Footer*/}
                    <SoftBox
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        {/*content_exif_data*/}
                        {getImageFooterNonpublicWarningTSX()}
                        <SoftTypography>{
                            getPhpbbImageExifInfoTSX(elementXtImageObjList)
                        }
                        </SoftTypography>
                    </SoftBox>
                </SoftBox>
                </>
            );


            /* Jeżeli istnieje LIGHT_THUMB - podaj ten
            - nie zawsze tak było
            */
            /*  TODO
                         if (file_exists(elementXtImageObjList[0]['light_thumb'])) {
                        var light_thumb = elementXtImageObjList[0]['light_thumb'];
                    } else {
                        light_thumb = elementXtImageObjList[0]['big_thumb'];
                    }*/
        } // end of IS ALLOWED FUNC

        /*if(i.image_category_id = 5,
            concat('<a style=\"height: 1%; padding-top: 1px; padding-bottom: 1px; color: #999999;\" href=\"',i.image_url,'\" target=\"_blank\">Skomentuj w Galerii Publicznej...</a>'),'') link,*/
        if (imageIsInternal) {
            /* Wewnęrzne: ACESS GRANTED:
                - jeżeli GP lub zalogowany
                - oraz opublikowany w ogóle
            */
            let imageFrameWidth = globalConfigParamObj.isMobile ?
                isPostFullwidth ? "calc(100%/3 - 0em)" : "calc(100% - 0em)"
                :
                isPostFullwidth ? "calc(100%/3 - 1em)" : "calc(100% - 1em)"
            return (
                <>
                    {
                        (get_phpbb_image_src_is_can_access(userInfoObj.user_id,
                            elementXtImageObjList[0]['cat_id'], elementXtImageObjList[0]['published'], (imageIsInternal))) ?
                            <>
                                {/*className="divTableHead">Zdjęcie #[[IMAGE_SEQ]] / <span
                                className="content_image_seq_global">([[IMAGE_ID]])*/}
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow={"md"}
                                    sx={{
                                        backgroundColor: 'background.paper',
                                        clear: 'none',
                                        float: 'left',
                                    }}
                                    borderRadius={5}
                                    p={0.5}
                                    m={0.5}
                                    width={imageFrameWidth}
                                >
                                    {getAllowedInternalImageTSX()}
                                </SoftBox>
                            </>
                            : getDeniedImageTSX()
                    }
                </>
            );

        } else {
            return getExternalImageTSX(url)
        } // END ZEW OR WEW

    }

    return (
        <>
            {
                (elementXtImageObjList?.length > 0 || !getPhpbbImageSrcIsInternal(url)) ?
                    (
                        getContainerContent()
                    ) : (<><AfdSourcererLoading
                    />
                    </>)
            }
        </>
    );
}

export default GetPhpbbImageUrl;

