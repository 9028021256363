/*
Wszystkie funkcje SUBMIT w jednym miejscu, aby spójna logika*/
import { debugLog } from "../util/UtilReact";
import IFormFormikHandleSubmit from "../type/form/IFormFormikHandleSubmit";
import IUserInfo from "../type/IUserInfo";
import IAmocourseRegInsert from "../type/amocourse/IAmocourseRegInsert";
import RestApiService, { API_URL } from "../service/restapi/RestApiService";
import RestApiAmocourseService from "../service/restapi/RestApiAmocourseService";
import RestApiAuthorizationService from "../service/restapi/RestApiAuthorizationService";
import IUniversalFormSubmitReponse from "../type/comprofiler/IUniversalFormSubmitReponse";
import { isDev } from "../util/isDev";
import RestApiCommonService from "../service/restapi/RestApiCommonService";
import linkDictionary from "../linkDictionary";
import parse from "html-react-parser";
import ReactPixel from "react-facebook-pixel";
import { toast } from "react-toastify";
import ICtxFormResponse from "../type/context/ICtxFormResponse";
import IValueRtAmocourseTrain from "../type/value_initial/IValueRtAmocourseTrain";
import IValueRtAccountTrain from "../type/value_initial/IValueRtAccountTrain";
import IValueGetTinyEdit from "../type/value_initial/IValueGetTinyEdit";
import IValueGalleryCommentDrawer from "../type/value_initial/IValueGalleryCommentDrawer";
import IValueJwallpaperAuthorize from "../type/value_initial/IValueJwallpaperAuthorize";
import IValueSiteLogout from "../type/value_initial/IValueSiteLogout";
import IValueGalleryCommentLine from "../type/value_initial/IValueGalleryCommentLine";
import IValueSiteLogin from "../type/value_initial/IValueSiteLogin";
import IValueNewbbTopicPost from "../type/value_initial/IValueNewbbTopicPost";
import ICtxClubNewbb from "../type/context/ICtxClubNewbb";
import { RestApiNewbbService } from "../service/restapi/RestApiNewbbService";
import IValueRtAmocoursePackageInsert from "../type/value_initial/IValueRtAmocoursePackageInsert";
import IValueNewbbPostReport from "../type/value_initial/IValueNewbbPostReport";
import IValueNewbbEditPost from "../type/value_initial/IValueNewbbEditPost";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import { getNowDateFormatMySQL } from "../util/Util";
import IValueLexForm from "../type/value_initial/IValueLexForm";
import useCHFBConversionAPI, { EventTypes } from "../hook/useCHFBConversionAPI";

import { getFieldGroupIsPersistent } from "../util/UtilTS";
import IValueSitePasswordReset from "../type/value_initial/IValueSitePasswordReset";
import { IValueIssueQuestionAdminEdit } from "../component/sourcerer/chapter/GetChapterIssueQuestionAdminEdit";
import IAxiosPostData from "../type/axios/IAxiosPostData";
import { IValueIssueImageAdminEdit } from "../component/sourcerer/chapter/GetForumChapterIssueImageListAdminEdit";
import { RestApiChapterService } from "../service/restapi/RestApiChapterService";
import { IFormikProfileInitialValues } from "./formikProfileInitialValues";
import { IValueDiplomaOrder } from "../component/modal/ModalMyAcademyDiplomaOrder";
import RestApiContactEnhService from "../service/restapi/RestApiContactEnhService";
import { IValueUserGroupCourseExtend } from "../component/modal/ModalUserCourseExtendButton";
import { specResetObj } from "../component/controller/get_site_password_reset/GetSitePasswordReset";
import IValueSiteContactUsMessage from "../type/value_initial/IValueSiteContactUsMessage";
import IValueChatbotForm from "../type/value_initial/IValueChatbotForm";
import {
  IChatbotResponse,
  IChatbotResponseDataObj,
} from "../component/controller/get_site_chatbot_form/GetSiteChatbot";
import { FormikState } from "formik";
import IValueSidebarAdminText from "../type/value_initial/IValueSidebarAdminText";


const formikAmocourseHandleSubmit: IFormFormikHandleSubmit<IValueRtAmocourseTrain> = (
  values: IValueRtAmocourseTrain,
  { setSubmitting }: any,
  userInfoObj: IUserInfo | undefined,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    courseEventId: courseEventId,
    categoryId: categoryId,
    sessionId: sessionId,
    location: location,
    facebookApiFunction: facebookApiFunction,
  }: any,
) => {

  var sendDataObject: IAmocourseRegInsert = {
    user_id: (userInfoObj ? userInfoObj.user_id : 0),
    course_event_id: courseEventId,
    email: btoa(values.email1),
    data_phone: values.data_phone,
    data_instr: (values.data_instr == "full" || values.is_instr_add[0] == "on" ? "full" : "half"), // 2023
    data_findus: values.data_findus,
    data_cityname: values.data_cityname,
    data_city1: values.data_city1,
    data_experience: values.data_experience,
    data_name1: values.data_name1,
    data_fname1: values.data_fname1,
    country: values.country,
    promocode: values.promocode,
    data_postcode: values.data_postcode,
    data_country_code: values.data_country_code,
    data_data1: (values.data_data1[0] === "on" ? "true" : "false"),
    data_commercial1: (values.data_commercial1[0] === "on" ? "true" : "false"),
    nip1: values.nip1,
    is_installment: values.is_installment,
    set_user_registration_insert: "1",
    c7ee7f06a07f07aba6e88e091cfac857: "1",
    xx_session_id: sessionId,
  };

  /*
  bez wzgledu na rezultat
  https://www.npmjs.com/package/react-facebook-pixel
  */
  // ReactPixel.track('Purchase', {
  //     content_ids: categoryId
  // });
  facebookApiFunction(
    EventTypes.Purchase,
    location.pathname,
    "website",
  );

  RestApiService.getPromise(RestApiAmocourseService.setUserRegistrationInsert(sendDataObject))
    .then((response) => {
        debugLog("RESPONSE: " + JSON.stringify(response));
        setSubmitting(false);
        let regId = response.data.reg_id;

        /*if (!isDev()) {*/
        var currentUrl = window.location.href;
        window.location.href = linkDictionary.AMOCOURSE_REG_CONFIRM + "&reg_id=" + regId;
        /*}*/
      },
    );


};

const formikAccountHandleSubmit: IFormFormikHandleSubmit<IValueRtAccountTrain> = (
  values: IValueRtAccountTrain,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    handleNextStepFunction: handleNextStepFunction,
    utm_source: utm_source,
    utm_medium: utm_medium,
    utm_campaign: utm_campaign,
    utm_content: utm_content,
    utm_placement: utm_placement,
    location: location,
    facebookApiFunction: facebookApiFunction,
  }: any,
) => {

  /* POST method is different
         email:            testsdfsd@test.pl
  cbsecuritym3:
  cbrasitway:
      id:                0
  gid:            0
  emailpass:            0
  option:
      com_comprofiler        xx_course_id:
      task:                saveregisters
  username:            testbnbnnn
  password:            mike123
  password__verify:            mike123
  cb_modelaparatu:            asdf
  cb_freshmail_chat:            1
  acceptedterms:            1
  * */

  /*
  bez wzgledu na rezultat
  https://www.npmjs.com/package/react-facebook-pixel
  */

  // ReactPixel.track('CompleteRegistration', {});
  facebookApiFunction(
    EventTypes.CompleteRegistration,
    location.pathname,
    "website",
  );
  let postPromise = !isDev() ? RestApiService.getPromise("",
    "POST",
    RestApiAuthorizationService.setPostAccountCreate(
      values.email,
      "",
      "",
      0,
      0,
      0,
      values.username,
      values.password,
      values.password__verify,
      values.cb_modelaparatu,
      (values.cb_freshmail_chat[0] === "on" ? 1 : 0),
      (values.cb_freshmail_newsletter[0] === "on" ? 1 : 0),
      (values.cb_freshmail_course[0] === "on" ? 1 : 0),
      (values.cb_law_commercial[0] === "on" ? 1 : 0),
      (values.cb_law_electronic[0] === "on") ? 1 : 0,
      (values.acceptedterms[0] === "on" ? 1 : 0),
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      utm_placement,
    ),
  ) : Promise.resolve({
    data: JSON.stringify({},
    ),
  });
  postPromise.then((response) => {
    let internalResponseObj: IUniversalFormSubmitReponse = JSON.parse(
      response.data.replace(/\r\n/g, "").trim(),
    );
    /* BUGFIX:bez opakowywania w gantry */
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(internalResponseObj);
    debugLog("RESPONSE: " + JSON.stringify(internalResponseObj));

    setSubmitting(false);
    /*window.location.href = linkDictionary.LOGIN_URL;*/
    /* Nie przeskakuj do LOGIN - najpierw potwierdź na tym samym ekranie mając ten same kontekst
    * */
    handleNextStepFunction();
  }).catch((error) => {
      debugLog("ERROR: " + JSON.stringify(error));
      setSubmitting(false);
    },
  );
};


const formikAmocourseRegistrationPackageInsert: IFormFormikHandleSubmit<IValueRtAmocoursePackageInsert> = (
  values: IValueRtAmocoursePackageInsert,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    course_event_id: course_event_id,
    location: location,
    facebookApiFunction: facebookApiFunction,
  }: any,
) => {


  /*
  bez wzgledu na rezultat
  https://www.npmjs.com/package/react-facebook-pixel
  */
  /*   ReactPixel.track('Purchase', {
         content_ids: categoryId
     });*/

  facebookApiFunction(
    EventTypes.Purchase,
    location.pathname,
    "website",
  );

  RestApiService.getPromise(
    "",
    "POST",
    RestApiAmocourseService.setUserRegistrationPackageInsert({
        set_user_registration_package_insert: "1",
        user_id: (userInfoObj ? userInfoObj.user_id : 0),
        course_event_id: course_event_id,
        data_data1: "true", // TODO
      },
    )).then((response) => {
      debugLog("RESPONSE: " + JSON.stringify(response));
      setSubmitting(false);
      let regId = response.data.reg_id;

      /*if (!isDev()) {*/
      var currentUrl = window.location.href;
      window.location.href = linkDictionary.AMOCOURSE_REG_CONFIRM + "&reg_id=" + regId;
      /*}*/
    },
  );

};


const formikGalleryCommentHandleSubmit: IFormFormikHandleSubmit<IValueGalleryCommentDrawer> = (
  values: IValueGalleryCommentDrawer,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    ctxGalleryFunctionObj: ctxGalleryFunctionObj,
    pic_id,
  }: any,
) => {

  var replyCommentId = ctxGalleryFunctionObj?.storeOrigCommentObj?.comment_id;
  RestApiCommonService.getPromise(RestApiCommonService.setUserCommentInsertUrl(
    userInfoObj.user_id,
    pic_id,
    values.comment,
    replyCommentId ? replyCommentId : null,
    values.heart_id,
  )).then((response: any) => {
      if (response?.data) {
        let newCommentId = Number(response.data);
        /* Przekazuj niezależnie od PUSHER */
        ctxGalleryFunctionObj.setStoreNewCommentInfoObj({
          comment_id: newCommentId,
          comment: values.comment,
          user_id: userInfoObj.user_id,
          orig_comment_id: replyCommentId ? replyCommentId : null,
          heart_id: values.heart_id,
        });
        setSubmitting(false);
        /*dispatchFunction(actFormSubmissionResultUpdate({
            formSubmissionResult: {
                success: true,
                message: response.data.message,
            }
        }));
        getElementLatestCommentObjList();
    } else {
        dispatchFunction(actFormSubmissionResultUpdate({
            formSubmissionResult: {
                success: false,
                message: response.data.message,
            }
        }));
    }*/
      }
    },
  );

};


function getTextareaContent(textareaIdHTMLTag: string) {
  const textarea = document.getElementById(textareaIdHTMLTag) as HTMLTextAreaElement;
  const textAreaContent = textarea.value;
  return textAreaContent;
}

const formikNewbbTopicPostHandleSubmit: IFormFormikHandleSubmit<IValueNewbbTopicPost, ICtxClubNewbb> = (
  values: IValueNewbbTopicPost,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    afdTopicId,
    afdForumId,
    afdTopicType,
    afdSystemType,
    afdBBCodeUid,
    afdOrigPostId,
    textareaIdHTMLTag,
    setIsEditorOpen,
    isMobile,
  }: any,
  ctxNewbbFunctionObj,
) => {

  /* BUFFIX temp */
  let postText: string = getTextareaContent(textareaIdHTMLTag); // BUGFIX temp: values.post_text,
  let finalTopicId = (values.post_topic_select > 0 ? values.post_topic_select : afdTopicId);

  RestApiService.getPromise("", "POST",
    RestApiNewbbService.setPostInsertData(
      "",
      finalTopicId,
      userInfoObj.user_id,
      afdBBCodeUid,
      postText,
      afdOrigPostId,
      values.post_new_topic_title,
      afdForumId,
      afdTopicType,
    )).then((response) => {
      if (response?.data) {
        let newPostId = Number(response.data.post_id);
        // TODO let newTopicId = Number(response.data.topic_id);
        /*Przekazuj niezależnie od PUSHER*/
        ctxNewbbFunctionObj?.setStoreNewPostInfoObj({
          post_id: newPostId,
          topic_id: finalTopicId,
          post_text: postText,
          poster_jos4_user_id: userInfoObj.user_id,
          reply_to_orig_id: afdOrigPostId,
          post_new_topic_title: values.post_new_topic_title,
        });
        setSubmitting(false);

        /* BUGFIX: od Ola: wyłączaj edytor w MOBILE i DESKTOP  */
        setIsEditorOpen(false);

        /* zmień softowanie aby nowy na górze */
        ctxNewbbFunctionObj?.storeSetOrderByCode("CREATED_DATE");
      }


    },
  ).catch((error) => {
      console.log(error);
    },
  );

  /*var replyCommentId = ctxGalleryFunctionObj?.storeOrigCommentObj?.comment_id;
  RestApiCommonService.getPromise(RestApiCommonService.setUserCommentInsertUrl(
      userInfoObj.user_id,
      pic_id,
      values.comment,
      replyCommentId ? replyCommentId : null,
      values.heart_id
  )).then((response: any) => {
          if (response?.data) {
              let newCommentId = Number(response.data);
              /!* Przekazuj niezależnie od PUSHER *!/
              ctxGalleryFunctionObj.setStoreNewCommentInfoObj({
                  comment_id: newCommentId,
                  comment: values.comment,
                  user_id: userInfoObj.user_id,
                  orig_comment_id: replyCommentId ? replyCommentId : null,
                  heart_id: values.heart_id,
              });
              setSubmitting(false);
          }
      }
  )*/

};

/* Wstawiaj POST INSTRUCKTORA do posta */
export const formikNewbbInstructorCommentInsert: IFormFormikHandleSubmit<IValueSidebarAdminText, ICtxClubNewbb> = (
  values: IValueSidebarAdminText,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    poster_id,
    post_id,
    setUserInstructorCommentId,
  }: any,
  ctxNewbbFunctionObj,
) => {

  /* BUFFIX temp */
  let instructorPostText = (values.sidebar_admin_text ? values.sidebar_admin_text : "");

  RestApiService.getPromise("", "POST",
    RestApiNewbbService.setUserInstructorCommentInsertData(
      poster_id,
      userInfoObj.user_id,
      post_id,
      instructorPostText,
    )).then((response) => {
      if (response?.data) {
        let internalResponseObj: IUniversalFormSubmitReponse = JSON.parse(
          response.data.replace(/\r\n/g, "").trim(),
        );
        setUserInstructorCommentId(response.data);
        ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(internalResponseObj);
        setSubmitting(false);
      }
    },
  ).catch((error) => {
      console.log(error);
    },
  );
};

const formikNewbbEditPostSubmit: IFormFormikHandleSubmit<IValueNewbbEditPost, ICtxClubNewbb> = (
  values: IValueNewbbEditPost,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    afdTopicId,
    afdSystemType,
    postId,
    afdOrigPostId,
    setIsEditorOpen,
    setElementPostObjList,
    setElementPostReplyObjList,
    isMobile,
    textareaIdHTMLTag,
  }: any,
  ctxNewbbFunctionObj,
) => {

  /* BUFFIX temp */
  let postText: string = getTextareaContent(textareaIdHTMLTag); // BUGFIX temp: values.post_text,
  // let postText = values.edit_post_text ? values.edit_post_text : '';

  let topicTitle = values.edit_topic_title ? values.edit_topic_title : "";

  RestApiService.getPromise("", "POST",
    RestApiNewbbService.setPostEditData(
      "",
      postId,
      afdOrigPostId,
      topicTitle,
      postText,
    )).then((response) => {
      if (response?.data) {
        setSubmitting(false);

        /*  Aktualizacja posta głównego:
            - aktualizuj tylko jezeli ORIG */
        if (!afdOrigPostId) {
          setElementPostObjList((prevElementPostObjList: IElementNewbbTopicPost[]) => {
            return prevElementPostObjList.map((elementPostObj: IElementNewbbTopicPost) => {
              if (elementPostObj.post_id == postId) {
                elementPostObj.post_text = postText;
                elementPostObj.topic_title = topicTitle;
              }
              return elementPostObj;
            });
          });
        } else {
          setElementPostReplyObjList((prevElementPostReplyObjList: IElementNewbbTopicPost[]) => {
              return prevElementPostReplyObjList.map((elementPostReplyObj: IElementNewbbTopicPost) => {
                if (elementPostReplyObj.post_id == postId) {
                  elementPostReplyObj.post_text = postText;
                }
                return elementPostReplyObj;
              });
            },
          );
        }
        /* Wyłączaj edytor w MOBILE */
        setIsEditorOpen(false);
      }
    },
  ).catch((error) => {
      console.log(error);
    },
  );
};


/* Generic for REPORTS */
const formikNewbbPostReportSubmit: IFormFormikHandleSubmit<IValueNewbbPostReport, ICtxClubNewbb> = (
  values: IValueNewbbPostReport,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    afdTopicId,
    afdSystemType,
    afdOrigPostId,
    textareaIdHTMLTag,
    setIsEditorOpen,
    setElementPostObjList,
    isMobile,
  }: any,
  ctxNewbbFunctionObj,
) => {

  /* BUFFIX */
  let reasonId = 5;
  RestApiService.getPromise("", "POST",
    RestApiNewbbService.setPostReportInsertData(
      reasonId,
      afdOrigPostId,
      userInfoObj.phpbb_user_id,
      values.report_text,
      null,
    )).then((response) => {
      if (response?.data) {
        let reportId = Number.parseInt(response.data.report_id);
        setSubmitting(false);
        switch (reasonId) {
          case 5:
            toast.success("Gratulacje dodane pomyślnie...");
            break;
          default:
            toast.success("Zgłoszenie dodane pomyślnie...");
        }

        /* Wyłączaj edytor w MOBILE */
        setIsEditorOpen(false);
        setElementPostObjList((prevElementPostObjList: IElementNewbbTopicPost[]) => {
            return prevElementPostObjList.map((elementPostObj: IElementNewbbTopicPost) => {
              if (elementPostObj.post_id == afdOrigPostId) {
                elementPostObj.report_gratulation_id = reportId;
                elementPostObj.report_gratulation_datetime = getNowDateFormatMySQL();
                elementPostObj.report_gratulation_text = values.report_text;
              }
              return elementPostObj;
            });

          },
        );

      }


    },
  ).catch((error) => {
      console.log(error);
    },
  );

};

const formikFileUploadImageInfoHandleSubmit: IFormFormikHandleSubmit<IValueJwallpaperAuthorize> = (
  values: IValueJwallpaperAuthorize,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group,
    image_id,
    where_column_name,
    elementImagePreviewRefetchFunction,
  }: any,
) => {
  setSubmitting(true);
  /* 1: Wyślij do uniwersalnego handlera dla form field, który iteruje i wstawia
   */

  // TODO - nie wiadomo co z tym zrobić - kilka fieldGroup w jednym SUBMIT - na razie ładuje błędy
  formikGlobalFieldGroupUpdate(
    values,
    { setSubmitting },
    userInfoObj,
    undefined,
    {
      field_group: "jwallpaper_image_info",
      image_id: image_id,
      where_column_name: where_column_name,
    },
  );

  formikGlobalFieldGroupUpdate(
    values,
    { setSubmitting },
    userInfoObj,
    undefined,
    {
      field_group: "jwallpaper_authorize",
      image_id: image_id,
      where_column_name: where_column_name,
    },
  );

  /*2: Opublikuj zdjęcie za pomocą standardowego call Action
  OLD: setUserImagePublish
  * */
  RestApiCommonService.getPromise(RestApiCommonService.setActionUrl(
    "setUserImagePublish",
    [image_id].join("|"),
    userInfoObj.user_id,
  )).then((response: any) => {
    if (response?.data) {
      if (response.status === 200) {
        /* Przegeneruj listęp zdjęć załadowanych, aby pobrać status */
        elementImagePreviewRefetchFunction();
        toast.success("Zdjęcie pomyślnie dodane...");
        /*setTimeout(() => {
                navigate(linkDictionary.CLUB_GALLERY_NEW_IMAGE);
            }
            , 3000);*/
      } else {
        /*toast.error(errorMessage);*/
        console.log("ERROR: " + JSON.stringify(response.data));
      }
    }
    setSubmitting(false);
  }).catch((error) => {
    console.log("ERROR: " + JSON.stringify(error));
    setSubmitting(false);
  });

};

const formikCommentLineHandleSubmit: IFormFormikHandleSubmit<IValueGalleryCommentLine> = (
  values: IValueGalleryCommentLine,
  { setSubmitting }: any,
) => {

  /*    RestApiCommonService.getPromise(RestApiCommonService.setUserCommentInsertUrl(
          16514,/!*userInfoObj.user_id,*!/
          pic_id,
          values.comment
      )).then((response: any) => {
              if (response?.data) {
                  let newCommentId = Number(response.data);

              }
          }
      )
*/
};

/*static setQuestionAndAnswerAdminInsert(
    comment: string,
    chapterIssueCode: string,
    questionSeq: number
): IAxiosPostData {

    let postDataObj: IAxiosPostData = {
        url: API_URL,
        postData: {
            "set_question_and_answer_admin_insert": 1,
            "comment": comment,
            "chapter_issue_code": chapterIssueCode,
            "question_seq": questionSeq
        }
    };

    return postDataObj;
}*/

export const formikIssueQuestionAdminEditHandleSubmit: IFormFormikHandleSubmit<IValueIssueQuestionAdminEdit> = (
  values: IValueIssueQuestionAdminEdit,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    elementChapterIssueObj,
  }: any,
) => {
  RestApiService.getPromise("", "POST",
    RestApiChapterService.setQuestionAndAnswerAdminInsert(
      values.comment,
      elementChapterIssueObj.chapter_issue_code,
      Math.floor(Math.random() * 10000) + 1,
    ),
  ).then((response: any) => {
      if (response?.data) {
        let newCommentId = Number(response.data);
        setSubmitting(false);
        toast.success("Komentarz dodany pomyślnie...");
      }
    },
  ).catch((error) => {
    console.log(error);
  });
};


/*


static setForumChapterIssueItemInsert(
    chapterIssueCode: string,
    issueItemType: string,
    issueItemTitle: string,
    issueItemContent: string,
    issueItemUrlOrId: string
): IAxiosPostData {

    let postDataObj: IAxiosPostData = {
        url: API_URL,
        postData: {
            "set_forum_chapter_issue_item_insert": 1,
            "chapter_issue_code": chapterIssueCode,
            "issue_item_type": issueItemType,
            "issue_item_title": issueItemTitle,
            "issue_item_content": issueItemContent,
            "issue_item_url_or_id": issueItemUrlOrId
        }
    };

    return postDataObj;
}
*/

export const formikIssueItemAdminEditHandleSubmit: IFormFormikHandleSubmit<IValueIssueImageAdminEdit> = (
  values: IValueIssueImageAdminEdit,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    elementChapterIssueObj,
    issue_item_type,
  }: any,
) => {
  RestApiService.getPromise("", "POST",
    RestApiChapterService.setForumChapterIssueItemInsert(
      elementChapterIssueObj.chapter_issue_code,
      issue_item_type,
      values.issue_item_title,
      values.issue_item_content,
      values.issue_item_url_or_id,
    )).then((response: any) => {
      if (response?.data) {
        let newCommentId = Number(response.data);
        setSubmitting(false);
        toast.success("Issue ITEM dodano pomyślnie...");
      }
    },
  ).catch((error) => {
      console.log(error);
    },
  );
};


const formikLoginHandleSubmit: IFormFormikHandleSubmit<IValueSiteLogin> = (
  values: IValueSiteLogin,
  { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    setCookieLogin: setCookieLogin,
    cookieJos4Name: cookieJos4Name,
    useNavigateHook: navigate,
    ctxAppObj: ctxAppObj,
  }: any,
) => {

  /* POST method is different */
  RestApiService.getPromise("",
    "POST",
    RestApiAuthorizationService.setPostSiteLogin(
      values.username,
      values.passwd,
      false,
    ),
  )
    .then((response) => {
        let internalResponseObj: IUniversalFormSubmitReponse = JSON.parse(
          response.data.replace(/\r\n/g, "").trim(),
        );

        /*              {
            "return": "https://www.akademia-fotografii-dzieciecej.pl/index.php?option=com_content&Itemid=669&id=604&lang=pl&view=article",
            "alertmessages": [],
            "restapi_cookie": {
              "phpbb3_6cqy7_u": {
                  "phpbb_data": "714",
                  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
                  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
                  "phpbb_secure": "",
                  "phpbb_httponly": "HttpOnly"
              },
              "phpbb3_6cqy7_k": {
                  "phpbb_data": "545dd80c17e1f8c4",
                  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
                  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
                  "phpbb_secure": "",
                  "phpbb_httponly": "HttpOnly"
              },
              "phpbb3_6cqy7_sid": {
                  "phpbb_data": "f8801edaa1c04e0b65b06516bde6c391",
                  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
                  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
                  "phpbb_secure": "",
                  "phpbb_httponly": "HttpOnly"
              },
              "user": {
                  "cookie_name": "d07f5e913332a8676887d3d6e34cb94a",
                  "rcookie": "57 A 658434358 F E124D4A 310 855 85444 C165B F F11725858 05D5D A5712 F11 2 8581544514B4A11 D145047 A15 D5452 D177872777C297171565C51 D124012594A",
                  "lifetime": 1696322728,
                  "path": "/"
              }
            }
            }*/


        if (internalResponseObj.status === "success") {
          try {
            if (!internalResponseObj.data.restapi_cookie.user.rcookie ||
              !internalResponseObj.data.restapi_cookie.user.cookie_name) {
              throw new Error("login_comprofiler_no_cookie"); // name, message
            }

            setCookieLogin(internalResponseObj);
            debugLog("Cookie VALUE: ", cookieJos4Name);

            let passedUserId: number = Number.parseInt(internalResponseObj.data.restapi_cookie.user.session_user_id);


            /*Kiedy status poprawny, ciasteczka ustawione, wszystko działa
            - możemy zająć się autentykacją
            * */
            ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(internalResponseObj);
            /*https://reactrouter.com/en/main/fetch/redirect*/
            setSubmitting(false);

            /* Opóźnieie, aby przeładowanie się nie pchało */
            ctxAppObj && ctxAppObj.setIsIdeaModalLoader(passedUserId);
            setTimeout(() => {
                navigate(linkDictionary.CLUB_STARTPAGE);
              }
              , 500);

            /*if (!isDev()) {*/
            /*}*/

            /*getIndetifyUserIdPromise()
                .then((response: IComprofilerIndentifyResponse) => {
                        let internalData = response.data;
                        debugLog("USER identify DATA: ", JSON.stringify(internalData));



                    }
                );*/

          } catch (error: any) {
            let errorResponseObj: IUniversalFormSubmitReponse = {
              status: "error",
              message: "login_comprofiler_no_cookie",
              message_desc:
                parse("ERROR: Serwer (comprofiler) nie zwrócił wymaganych ciasteczek. Bez ciasteczek nie możemy Cię zalogować." +
                  "<br></br>To może być problem z Twoją przeglądarką. Spróbuj zalogować się z innej przeglądarki" +
                  "<br> i/lub... <br>skontaktuj się z biurem Akademii."),
              data: {
                reason: "Error unhandled by comprofiler loginJSON method. Probably caused by error of Joomla login plugin (xm_materialized / phpbb_banlist)",
                internalResponseObj: internalResponseObj,
              },
            };
            ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
            setSubmitting(false);
          }

        } else if (internalResponseObj.status === "error") {
          //login_comprofiler_status_error
          let errorResponseObj: IUniversalFormSubmitReponse = {
            status: "error",
            message: "login_comprofiler_status_error",
            message_desc: "Błąd: Serwer (comprofiler) zwrócił wiadomość ze statusem: error. Skontatuj się z biurem Akademii.",
            data: {
              internalResponseObj: internalResponseObj,
            },
          };
          ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
          setSubmitting(false);
        }
      },
    ).catch((error) => {
      let errorResponseObj: IUniversalFormSubmitReponse = {
        status: "error",
        message: "login_error_rest_api",
        message_desc: "Błąd: Błąd połączenia z serwerem autoryzacji. Metoda (setPostSiteLogout). Skontaktuj się z biurem lub spróbuj ponownie później. ",
        data: error,
      };
      ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
      debugLog("ERROR: ", JSON.stringify(error));
      setSubmitting(false);
    },
  );

};

const formikLogoutHandleSubmit: IFormFormikHandleSubmit<IValueSiteLogout> = (
  values: IValueSiteLogout,
  { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void },
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    setAuthorizationDataObj: setAuthorizationDataObj,
    setCookieLogout: setCookieLogout,
  }: any,
) => {

  /* POST method is different */
  RestApiService.getPromise("",
    "POST",
    RestApiAuthorizationService.setPostSiteLogout(),
  )
    .then((response) => {
        let internalResponseObj: IUniversalFormSubmitReponse | undefined;
        try {
          if (typeof response.data === "string") {
            internalResponseObj =
              JSON.parse(
                response.data.replace(/\r\n/g, "").trim(),
              );
            debugLog("Response from STRING: " + JSON.stringify(internalResponseObj));
          } else {
            internalResponseObj = response.data;
            debugLog("Response OBJ: " + JSON.stringify(internalResponseObj));
          }

          setAuthorizationDataObj(internalResponseObj);
          /*              {
"return": "https://www.akademia-fotografii-dzieciecej.pl/index.php?option=com_content&Itemid=669&id=604&lang=pl&view=article",
"alertmessages": [],
"restapi_cookie": {
"phpbb3_6cqy7_u": {
  "phpbb_data": "714",
  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
  "phpbb_secure": "",
  "phpbb_httponly": "HttpOnly"
},
"phpbb3_6cqy7_k": {
  "phpbb_data": "545dd80c17e1f8c4",
  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
  "phpbb_secure": "",
  "phpbb_httponly": "HttpOnly"
},
"phpbb3_6cqy7_sid": {
  "phpbb_data": "f8801edaa1c04e0b65b06516bde6c391",
  "phpbb_expire": "Tue, 03-Oct-2023 08:45:28 GMT",
  "phpbb_domain": "; domain=.akademia-fotografii-dzieciecej.pl",
  "phpbb_secure": "",
  "phpbb_httponly": "HttpOnly"
},
"user": {
  "cookie_name": "d07f5e913332a8676887d3d6e34cb94a",
  "rcookie": "57 A 658434358 F E124D4A 310 855 85444 C165B F F11725858 05D5D A5712 F11 2 8581544514B4A11 D145047 A15 D5452 D177872777C297171565C51 D124012594A",
  "lifetime": 1696322728,
  "path": "/"
}
}
}*/
          if (internalResponseObj?.status === "success") {
            try {

              setCookieLogout(internalResponseObj);
              setCookieLogout(internalResponseObj, ".akademia-fotografii-dzieciecej.pl");
              setCookieLogout(internalResponseObj, "www.akademia-fotografii-dzieciecej.pl");


              ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(internalResponseObj);
              /*https://reactrouter.com/en/main/fetch/redirect*/

              setSubmitting(false);
              /*window.location.href = linkDictionary.LOGIN_URL;*/ // musi być aby przeładować stany? - NIE - inaczej nie ma komunikatów
            } catch (error: any) {
              let errorResponseObj: IUniversalFormSubmitReponse = {
                status: "error",
                message: "logout_client_cookie_clear",
                message_desc:
                  parse("Wystąpił błąd: serwer wykonał wylogowanie poprawnie, ale nie było możliwe usunięcie ciasteczek z Twojej przegladarki. Skontakuj się z biurem wysyłając zrzut tego ekranu." +
                    "Może to być problem Twojej przeglądarki."),
                data: {
                  internalResponseObj: internalResponseObj,
                  error: error,
                },
              };
              ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
              setSubmitting(false);
            }

          } else if (internalResponseObj?.status === "error") {
            let errorResponseObj: IUniversalFormSubmitReponse = {
              status: "error",
              message: "logout_server_result_error",
              message_desc: "Błąd: Serwer (comprofiler) zwrócił wiadomość ze statusem: error. Skontatuj się z biurem Akademii.",
              data: {
                internalResponseObj: internalResponseObj,
              },
            };
            ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
            setSubmitting(false);
          }

        } catch (error) {
          let errorResponseObj: IUniversalFormSubmitReponse = {
            status: "error",
            message: "logout_error_api",
            message_desc: "Błąd: Błąd w czasie parsowania odpowiedzi z serwera. ",
            data: {
              error: error,
              internalResponseObj: internalResponseObj ? internalResponseObj : "",
            },
          };
          ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
          debugLog("ERROR: ", JSON.stringify(error));
          setSubmitting(false);
          return;
        }
      },
    ).catch((error) => {
      let errorResponseObj: IUniversalFormSubmitReponse = {
        status: "error",
        message: "logout_error_api",
        message_desc: "Błąd: Błąd połączenia z serwerem autoryzacji. Metoda (setPostSiteLogout). Skontaktuj się z biurem lub spróbuj ponownie później. ",
        data: {
          exception: error,
        },
      };
      ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(errorResponseObj);
      debugLog("ERROR: ", JSON.stringify(errorResponseObj));
      setSubmitting(false);
    },
  );


};

const formikSitePasswordReset: IFormFormikHandleSubmit<IValueSitePasswordReset> = (
  values: IValueSitePasswordReset,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    userOrPasswordToggleDesc,
  }: any,
) => {
  /* Send email */
  /*let mailType =
      userOrPasswordToggleDesc == 'password_reset' ?
      'EMAILING_GLOBAL_USER_PASSWORD_RESET' : 'EMAILING_GLOBAL_USER_USERNAME_RESET';*/
  let mailType = specResetObj[userOrPasswordToggleDesc].mail_type;


  let postPromise = !isDev() ? RestApiService.getPromise("",
      "POST",
      RestApiAuthorizationService.setUserPasswordReset(
        values.password_reset_email,
        userOrPasswordToggleDesc,
      ),
    ) : RestApiService.getPromise("",
      "POST",
      RestApiAuthorizationService.setUserPasswordReset(
        values.password_reset_email,
        userOrPasswordToggleDesc,
      ),
    )
  ;
  postPromise.then((responsePasswordReset) => {
    /*let internalPasswordResetResponseObj: IUniversalFormSubmitReponse = JSON.parse(
        responsePasswordReset.data.replace(/\r\n/g, "").trim()
    );*/
    let internalPasswordResetResponseObj: IUniversalFormSubmitReponse = responsePasswordReset.data;
    RestApiCommonService.getPromise(RestApiCommonService.setMailSend(mailType, values.password_reset_email),
    ).then((mailSendResponseObj) => {
        ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(internalPasswordResetResponseObj);
        debugLog("RESPONSE: " + JSON.stringify(internalPasswordResetResponseObj));

      },
    ).catch((mailSendError) => {
        let mailSendErrorResponseObj: IUniversalFormSubmitReponse = {
          status: "error",
          message: "password_reset_error_email",
          message_desc: "Błąd przesłania wiadomości email. Skontaktuj się z biurem lub spróbuj ponownie później. ",
          data: {
            exception: mailSendError,
          },
        };
        ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(mailSendErrorResponseObj);
      },
    ).finally(() => {
      setSubmitting(false);
    });

  }).catch((passwordResetError) => {

      let mailSendErrorResponseObj: IUniversalFormSubmitReponse = {
        status: "error",
        message: "password_reset_error_js",
        message_desc: userOrPasswordToggleDesc ?
          "Błąd w czasie resetowania hasła. Skontaktuj się z biurem lub spróbuj ponownie później." :
          "Błąd w czasie przesyłania nazwy użytkownika. Skontaktuj się z biurem lub spróbuj ponownie później.",
        data: {
          exception: passwordResetError,
        },
      };
      ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(mailSendErrorResponseObj);
    },
  ).finally(() => {
    setSubmitting(false);
  });

};


const formikTinyEditHandleSubmit: IFormFormikHandleSubmit<IValueGetTinyEdit> = (
  values: IValueGetTinyEdit,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    column_name: column_name,
    table_name: table_name,
    where_name: where_name,
    object_name: object_name,
    setTitleToggle: setTitleToggle,
  }: any,
) => {

  RestApiCommonService.getPromise(RestApiCommonService.setFieldUpdateUrl(
    column_name,
    table_name,
    where_name,
    String(object_name),
    values["image_title"],
  )).then((downloadResponseObj) => {
    /* requestPromise już odpaliło żądanie
     */
    debugLog("xxxx" + values["image_title"]);
    setTimeout(() => {
        setTitleToggle(false);
        setSubmitting(false);
      },
      400,
    );
  }).catch((error) => {
    debugLog(error);
  });

};

export const formikProfileUserFoto: IFormFormikHandleSubmit<IFormikProfileInitialValues[keyof IFormikProfileInitialValues]> = (
  values: IFormikProfileInitialValues[keyof IFormikProfileInitialValues],
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group: field_group,
    current_user_id: current_user_id,
  }: any,
) => {
  let fieldPromiseArray: Promise<any>[] = new Array<Promise<any>>();


  let setFieldPromise: Promise<any> | null = null;
  /* Zbieraj PEROMISE dla poszczególnych FIELD różnych typów */
  let updateUserId: string = getFieldGroupIsPersistent(field_group) ? current_user_id : userInfoObj.user_id.toString();

  Object.keys(values).forEach((fieldName: any) => {
    setFieldPromise = RestApiCommonService.getPromise(RestApiAmocourseService.setGlobalFormFieldUpdate(
      field_group,
      fieldName,
      "user_id",
      updateUserId,
      // @ts-ignore
      values[fieldName],
    ));
    fieldPromiseArray.push(setFieldPromise);
  });
  Promise.all(fieldPromiseArray).then((downloadResponseObj) => {
    /* requestPromise już odpaliło żądanie
 */

    let formFieldUpdateResponseObj: IUniversalFormSubmitReponse = {
      status: "success",
      message: "field_group_field_update_success",
      message_desc: "Pola formularza zaktualizowane pomyślnie. Niektóre zmiany mogą pojawić się na profilu dopiero za kilka minut. Prosimy o cierpliwość....",
      data: {},
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(formFieldUpdateResponseObj);
  }).catch((formFieldUpdateError) => {
    let formFieldUpdateResponseObj: IUniversalFormSubmitReponse = {
      status: "error",
      message: "field_group_field_update_error",
      message_desc: "Błąd aktualizacji pól formularza. Skontaktuj się z biurem Akademii lub spróbuj ponownie później.",
      data: {
        exception: formFieldUpdateError,
      },
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(formFieldUpdateResponseObj);

    debugLog(formFieldUpdateError);
  }).finally(() => {
      setTimeout(() => {
          setSubmitting(false);
        },
        400,
      );
    },
  );

};

export const formikSiteContactUsMessageSubmit: IFormFormikHandleSubmit<IValueSiteContactUsMessage> = (
  values: IValueSiteContactUsMessage,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group: field_group,
  }: any,
) => {
  let promiseArray: Promise<any>[] = [];
  let keys = Object.keys(values) as (keyof typeof values)[];


  /* RestApiCommonService.getPromise(RestApiCommonService.setSiteContactUsInsertUrl(
       values.site_contact_us_email,
       (userInfoObj.user_id ? userInfoObj.user_id : 0),
       values.site_contact_us_title,
       values.site_contact_us_message_text,
       values.site_contact_us_image_id,
       values.site_contact_us_group_id,
       values.site_contact_us_url
   )).then((downloadResponseObj) => {
       toast.success("Doskonale, wiadomość wysłana ");
       setTimeout(() => {
               setSubmitting(false);
           },
           400
       );
   }).catch((error) => {
       debugLog(error);
   });
*/
  RestApiService.getPromise("",
    "POST",
    RestApiCommonService.setSiteContactUsInsertUrl(
      values.site_contact_us_email,
      (userInfoObj.user_id ? userInfoObj.user_id : 0),
      values.site_contact_us_title,
      values.site_contact_us_message_text,
      values.site_contact_us_image_id,
      values.site_contact_us_group_id,
      values.site_contact_us_url,
    ),
  ).then((response) => {
    toast.success("Doskonale, wiadomość wysłana ");
    setTimeout(() => {
        setSubmitting(false);
      },
      400,
    );

  }).catch((error) => {
    debugLog(error);
  });


};

export const formikSiteChatbotSubmit: IFormFormikHandleSubmit<IValueChatbotForm> = (
  values: IValueChatbotForm,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group: field_group,
    model: model,
    setModel: setModel,
    threadId: threadId,
    setThreadId: setThreadId,
    responseObjList: responseObjList,
    setResponseObjList: setResponseObjList,
    currentEndpoint: currentEndpoint,
    setCurrentEndpoint: setCurrentEndpoint,
    isPromptAutoSent: isPromptAutoSent,
    conversationParams: conversationParams,
    setConversationParams: setConversationParams,
    formikHelpers: formikHelpers,
  }: any,
) => {
  let promiseArray: Promise<any>[] = [];
  let keys = Object.keys(values) as (keyof typeof values)[];


  /* Put PROMPT from user only if it is not AUTO generated */
  if (!isPromptAutoSent) {
    /* Insert USER question  */
    setResponseObjList((prevResponseObjList: IChatbotResponseDataObj[]) => {
        return [...prevResponseObjList,
          {
            source: "user",
            data: {
              status: null,
              message: values.chatbot_question,
              model: null,
              assistant_id: null,
              assistant_name: null,
              assistant_thread_id: null,
              assistant_run_id: null,
              context_current_record: null,
            },
          },
        ];
      },
    );
  }

  setResponseObjList((prevResponseObjList: IChatbotResponseDataObj[]) => {
      return [...prevResponseObjList,
        {
          source: "loading",
          data: {
            status: null,
            message: "...",
            model: null,
            assistant_id: null,
            assistant_name: null,
            assistant_thread_id: null,
            assistant_run_id: null,
            context_current_record: null,
          },
        },
      ];
    },
  );

  RestApiService.getPromise(
    RestApiCommonService.getChatbotAssistantResponse(
      currentEndpoint,
      model,
      values.chatbot_question,
      threadId,
      isDev(),
    ),
  ).then(
    // @ts-ignore
    (response): IChatbotResponse => {
      if (response?.data) {
        setThreadId(response.data.assistant_thread_id);
        setSubmitting(false);

        // Replace LOADING message with USER response
        setResponseObjList((prevResponseObjList: {
          source: "agent" | "user" | "loading";
          data: IChatbotResponseDataObj;
        }[]) => {
          // Filter out the "loading" message before appending the user response
          const updatedResponseObjList: {
            source: "agent" | "user" | "loading";
            data: IChatbotResponseDataObj;
          }[]
            = prevResponseObjList.filter(obj => obj.source !== "loading");

          // Append the USER response
          return [
            ...updatedResponseObjList,
            {
              source: "assistant",
              data: response.data,
            },
          ];
        });

        /* Increase COUNTER  */
        setConversationParams((prevConversationParams: { apiResponseCount: number; }) => {
          return {
            ...prevConversationParams,
            apiResponseCount: prevConversationParams.apiResponseCount + 1,
          };

        });

        setThreadId(response.data.assistant_thread_id);
        /* Clear FIELD */
        formikHelpers.setFieldValue("chatbot_question", "");
        formikHelpers.setFormikState((prevState: FormikState<IValueChatbotForm>) => {
            return {
              ...prevState,
              values: {
                chatbot_question: "yyy",
              },
            };
          },
        );
      }
      /* Wyślij tak czy inaczej, aby przechwytywać zawsze odpowiedź */
      let fieldGroupInsertResponse: IUniversalFormSubmitReponse = {
        status: "success",
        message: "chatbot_message_submit_success",
        message_desc: "Udane przesłanie wiadomości chatbot",
        data: {},
      };
      ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(fieldGroupInsertResponse);
    },
  ).catch((error) => {

    /* 500 jeżeli TIMEOUT dla chatbot */
    console.log(error);
    /* Wyślij tak czy inaczej, aby przechwytywać zawsze odpowiedź */
    let fieldGroupInsertResponse: IUniversalFormSubmitReponse = {
      status: "error",
      message: "chatbot_message_submit_error",
      message_desc: "Niestety nie udało mi się połączyć z serwerem. Spróbuj ponownie lub napisz do nas do biura. Tam instruktor lub asystentka pomoże jak najszybciej.",
      data: {
        error: error,
      },
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(fieldGroupInsertResponse);
  });


};

const formikGlobalFieldGroupUpdate: IFormFormikHandleSubmit<IValueJwallpaperAuthorize> = (
  values: IValueJwallpaperAuthorize,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group: field_group,
    image_id: image_id,
    where_column_name: where_column_name,
  }: any,
) => {
  let promiseArray: Promise<any>[] = [];
  let keys = Object.keys(values) as (keyof typeof values)[];

  keys.forEach((field_name: keyof IValueJwallpaperAuthorize) => {
      promiseArray.push(
        RestApiCommonService.getPromise(RestApiAmocourseService.setGlobalFormFieldUpdate(
          field_group,
          field_name,
          where_column_name,
          image_id,
          (Array.isArray(values[field_name]) ?
              (values[field_name][0] == "on" ? "true" : "false") : values[field_name].toString()
          ),
        )),
      );
    },
  );
  Promise.all(promiseArray).then((downloadResponseObj) => {
    let formFieldUpdateResponseObj: IUniversalFormSubmitReponse = {
      status: "success",
      message: "field_group_field_update_success",
      message_desc: "Pola formularza zaktualizowane pomyślnie. Niektóre zmiany mogą pojawić się na profilu dopiero za kilka minut. Prosimy o cierpliwość....",
      data: {},
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(formFieldUpdateResponseObj);
    toast.success("Doskonale, zmiany zapisane ");
  }).catch((error) => {
    let formFieldUpdateResponseObj: IUniversalFormSubmitReponse = {
      status: "success",
      message: "field_group_field_update_success",
      message_desc: "Pola formularza zaktualizowane pomyślnie. Niektóre zmiany mogą pojawić się na profilu dopiero za kilka minut. Prosimy o cierpliwość....",
      data: {},
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(formFieldUpdateResponseObj);
    toast.error("Błąd połączenia z serwerm Akademii. Skontakuj się z biurem lub spróbuj ponownie za kilka minut.");
  }).finally(() => {
      setTimeout(() => {
          setSubmitting(false);
        },
        400,
      );
    },
  );


};

/* Generalna metoda do wstawiania wartości do bazy danych
IValueDiplomaOrder
* */
const formikGlobalFieldGroupInsert: IFormFormikHandleSubmit<
  IValueDiplomaOrder
  | IValueUserGroupCourseExtend
> = (
  values: IValueDiplomaOrder | IValueUserGroupCourseExtend,
  { setSubmitting }: any,
  userInfoObj: IUserInfo,
  ctxFormResponse: ICtxFormResponse | undefined,
  {
    field_group: field_group,
    messageContactId: messageContactId,
    xmProcedureName: xmProcedureName,
    additionalFieldObj: additionalFieldObj,
  }: any,
) => {
  let promiseArray: Promise<any>[] = [];
  let keys = Object.keys(values) as (keyof typeof values)[];

  /* Create NEW message */
  /*  let fieldValueObjList = [
        {
            field_name: "chat_calendar_id",
            field_id: 493,
            field_value: elementChatBoxObj.chat_calendar_id.toString()
        }
    ];*/
  /*let xmProcedureName = 'set_materialized_xm_form_chat_register_message_id';*/
  /* Field ID translated on the fly while inserting record */
  // @ts-ignore

  let fieldValueObjList: any[] = Object.keys(values).map((field_name: keyof IValueDiplomaOrder) => {
      // @ts-ignore
      let fieldValue = values[field_name] ? values[field_name]?.toString() : null;
      if (field_name == "diploma_order_is_exhibition") fieldValue = fieldValue == "on" ? "T" : "N";
      return {
        field_name: field_name,
        field_value: fieldValue,
      };
    },
  );

  /* Dodaj dopiero teraz ponieważ inaczej nie zgodne z: IValueDiplomaOrder */
  Object.keys(additionalFieldObj).forEach((field_name: string) => {
      fieldValueObjList.push({
        field_name: field_name,
        field_value: additionalFieldObj[field_name],
      });
    },
  );

  RestApiCommonService.getPromise(
    RestApiContactEnhService.setContactEnhancedMessageInsert(
      messageContactId,
      userInfoObj.user_id,
      new Date().toISOString(),
      field_group,
      // @ts-ignore
      fieldValueObjList,
      xmProcedureName,
    ),
  ).then((value) => {
    toast.success("Doskonale, zmiany zapisane ");

    let fieldGroupInsertResponse: IUniversalFormSubmitReponse = {
      status: "error",
      message: "field_group_message_and_field_insert_success",
      message_desc: "Wpis fomurlarza pomyślnie dodany do systemu",
      data: {
        value: value,
      },
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(fieldGroupInsertResponse);

  }).catch((error) => {
    let fieldGroupInsertResponse: IUniversalFormSubmitReponse = {
      status: "error",
      message: "field_group_message_and_field_insert_error",
      message_desc: "Błąd dodania wpisu formularza",
      data: {
        error: error,
      },
    };
    ctxFormResponse && ctxFormResponse.setStorestoreFormResponseObj(fieldGroupInsertResponse);
    toast.error("Błąd połączenia z serwerm Akademii. Skontakuj się z biurem lub spróbuj ponownie za kilka minut.");
  });


  /*
      keys.forEach((field_name: keyof IValueDiplomaOrder) => {
              promiseArray.push(
                  RestApiCommonService.getPromise(RestApiAmocourseService.setGlobalFormFieldInsert(
                      field_group,
                      field_name,
                      (Array.isArray(values[field_name]) ?
                              (values[field_name][0] == 'on' ? 'true' : 'false') : values[field_name].toString()
                      )
                  ))
              );
          }
      );
      Promise.all(promiseArray).then((downloadResponseObj) => {

          toast.success("Doskonale, zmiany zapisane ");
      }).catch((error) => {
          debugLog(error);
          toast.error("Błąd połączenia z serwerm Akademii. Skontakuj się z biurem lub spróbuj ponownie za kilka minut.");
      });*/


};


export {
  formikAmocourseHandleSubmit,
  formikAmocourseRegistrationPackageInsert,
  formikAccountHandleSubmit,
  formikGalleryCommentHandleSubmit,
  formikCommentLineHandleSubmit,
  formikLoginHandleSubmit,
  formikLogoutHandleSubmit,
  formikTinyEditHandleSubmit,
  formikGlobalFieldGroupUpdate,
  formikFileUploadImageInfoHandleSubmit,
  formikNewbbTopicPostHandleSubmit,
  formikNewbbPostReportSubmit,
  formikNewbbEditPostSubmit,
  formikGlobalFieldGroupInsert,
  formikSitePasswordReset,
};
