import React from 'react';
import IContainerQuery from "../type/IContainerQuery";
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import SoftBox from "../components/SoftBox";
import Grid from "@mui/material/Grid";

interface IProp {
  additionalInfoObjList?: string[],
}

function AfdSourcererLoading({
                            }: IProp) {

    let color: "inherit" | "primary" | "secondary" | "error" | "info" | "success" | "warning" = "info";

    /* TODO: BUG: nie można wycentrować ponieważ daje OVERFLOW i scrollbars */
    return (

        <SoftBox
          name={  "AfdSourcererLoading"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            width={"100%"}
    p={2}
            >
            <CircularProgress
                color={color}
                sx={{
                    opacity: 0.5,
                }}
            />
        </SoftBox>

    );
}

export default AfdSourcererLoading;
