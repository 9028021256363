/* nie
bardzo ważne: parametry wejściowe do PROMISE kiedy nie ma zmiennych
nie to samo co: IAmocourseRegInsert - zawiera wszystkie pozostałe wartości przekazywane do API
 */
import IFormikInitialValues from "../type/form/IFormikInitialValues";



export const formikInitialValueObj: IFormikInitialValues = {
    "rt-amocourse-train": {
        email1: '',
        data_phone: '',
        data_instr: 'half',
        /* Jednak dodany atrybut, aby nie usuwać logiki poprzedniego */
        is_instr_add: [],
        data_findus: '',
        data_cityname: '',
        data_city1: '',
        data_experience: '',
        data_name1: '',
        data_fname1: '',
        country: 'xx',
        promocode: '',
        data_postcode: '',
        data_country_code: '',
        data_data1: [],
        data_commercial1: [],
        nip1: '',
        is_installment: 1
    },
    "rt-account-train": {
        email: '',
        cbsecuritym3: '',
        cbrasitway: '',
        id: 0,
        gid: 0,
        emailpass: 0, // number
        username: '',
        password: '',
        password__verify: '',
        cb_modelaparatu: '',
        cb_freshmail_chat: [],
        cb_freshmail_newsletter: [],
        cb_freshmail_course: [],
        cb_law_commercial: [],
        cb_law_electronic: [],
        acceptedterms: [],
    },
    "get-tiny-edit": {
        image_title: '',
    },
    "jwallpaper_authorize": {
        afdIsAuthorized: [],
        afdIsCommercialUse: [],
        afdIsRodoApperance: [],
        afdSocialInstagramUrl: ''
    },
    "gallery-comment-drawer": {
        comment: '',
        heart_id: null
    },
    "site-logout": {},
    "gallery-comment-line": {},
    "site-login": {
        username: '',
        passwd: '',
    },
    "newbb-topic-post": {
        post_text: '',
        post_new_topic_title: '',
        post_topic_select: -1,
    },
    "newbb-post-report": {
        report_text: '',
    },
    "newbb-post-reply": {
        post_text: '',
    },
    "newbb-edit-post": {
        edit_topic_title: '',
        edit_post_text: '',
    },
    "amocourse-package": {
        data_data1: [],
    },
    "lex-form": {
        lex_question: '',
    },
    "issue-question-admin-edit": {
        comment: '',
    },
    "issue-item-admin-edit": {
        issue_item_type: '',
        issue_item_title: '',
        issue_item_content: '',
        issue_item_url_or_id: '',
    },
    "admin_crm_diploma_order": {
        diploma_order_firstname: '',
        diploma_order_lastname: '',
        diploma_order_language: '',
        diploma_order_is_exhibition: [],
    },
    "user_group_course_extend": {
        course_extend_reason_comment: '',
    },

    "backend_ep_pretend_review_list": {
        pretend_review_content_like: '',
        pretend_review_content_try: '',
        pretend_review_content_expect: '',
        pretend_review_user_id: -1,
        pretend_review_review_type: 'AFD_SUPERMUM',
        pretend_review_reg_id: -1,
        pretend_review_draft_like: '',
        pretend_review_draft_try: '',
        pretend_review_draft_expect: '',
        pretend_review_recom1_category_id: null,
        pretend_review_recom2_category_id: null,
    },
    "sidebar-admin-text": {
        sidebar_admin_text: ""
    }


}
