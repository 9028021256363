import IPropContainer from "../../../type/IPropContainer";


import React, {useEffect, useState} from "react";
import { lazy , Suspense } from "react";
import {debugLog, getAccountCreateUtmUrl} from "../../../util/UtilReact";
import RestApiService from "../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../service/restapi/RestApiCommonService";

import AfdDefaultNavbar from "../../../examples/Navbars/AfdDefaultNavbar";

const brand = require('../../../assets/images/logo-ct.png');
import {camelize, capitalize} from "../../../util/Util";
import {processContainer, processElement} from "../../../util/UtilReact";
import Emptybox from "../../emptybox/Emptybox";
import AfdLoadingFullpage from "../../AfdLoadingFullpage";
import AfdContainerCard from "../../AfdContainerCard";
import linkDictionary    from "../../../linkDictionary";
import useCHContainerData from "../../../hook/useCHContainerData";

import ReadMoreOutlinedIcon from '@mui/icons-material/ReadMoreOutlined';

import IGlobalMenuParent     from "../../../type/global_menu/IGlobalMenuParent";
import IRQOutputList from "../../../type/rq/IRQOutputList";
import IRQOutputSingle from "../../../type/rq/IRQOutputSingle";
import {userInfo} from "os";
import Sidenav from "../../../examples/Sidenav";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import {setMiniSidenav, useCHGetContextSoftUI} from "../../../context/CtxSoftUIGlobalSetupByRdx";
import IAppObj from "../../../type/context/IAppObj";
import CtxAppObj from "../../../context/CtxApp";

/*ng-click="psedudoScrollNextPage()"*/
//{containerRow.post_count}
const GetSiteGlobalMenu = ({
                               controllerInfoObj: controllerInfoObj,
                               routeCategory: routeCategory,
                               userInfoObj: userInfoObj,
                                menuItemObj: menuItemObj,
                               paramObj: paramObj
                           }: IPropContainer) => {
    const ctxAppObj: IAppObj = React.useContext(CtxAppObj);
    const [controllerParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controllerParamObj;
    const components: any = {

    }
    if (controllerInfoObj.element1CamelName !== undefined) {
        var ElementComponent = components[controllerInfoObj.element1CamelName];
    }

    /*Pobierz dane element*/
    var elementRouteObjList: IGlobalMenuParent[];
    var getElementRouteObjList: any;
    var containerInfoObj: any;
    var setContainerInfoObj: any;

    /* To samo zapytanie używane go generowania ROUTES na poziomie App.tsx więc musi uwzględniać wszystkie pozycje, które nie są odświeżane
    - dlatego FILTER
    * */
    [elementRouteObjList, getElementRouteObjList, containerInfoObj, setContainerInfoObj]
        = useCHContainerData(controllerInfoObj,
        {
            user_id: userInfoObj.user_id,
            bg_id: 0,
            item_id: controllerInfoObj.page_item_id
        },
        {
            object_id: 0,
            object2_id: 0
        },
        "parent_menu_id",
        "collapse"
    );


    /*{
        name: "Foundation",
            key: "foundation",
        collapse: [
        {
            name: "Colors",
            key: "colors",
            href: "https://www.creative-tim.com/learning-lab/react/colors/soft-ui-dashboard/",
        },*/

    function getSiteMenuContent() {
        let menutypeParentObjList = elementRouteObjList ? elementRouteObjList.filter((elementRouteObj: IGlobalMenuParent) => {
                return elementRouteObj.menutype === 'mainmenu'; //menuItemObj.xx_display_menutype;
            }
        ) : [];

       /* debugLog("MENU + " + JSON.stringify(menutypeParentObjList) + menuItemObj.menutype + "///" +
            JSON.stringify(elementRouteObjList)
        );*/
        let row = containerInfoObj;
        let temp = [
            { type: "title", title: "Docsx", key: "title-docs" }
        ]
        let menuIsLight = (menuItemObj.xx_react_gantry === 'rt-contact-cover' ? false : true);
        let menuIsTransparent =  (menuItemObj.xx_react_gantry === 'rt-contact-cover' ? false : true);;

        let accountCreateUrl = linkDictionary.ACCOUNT_CREATE_URL;
        if (ctxAppObj &&
            ctxAppObj.utm_source &&
            ctxAppObj.utm_medium &&
            ctxAppObj.utm_campaign &&
            ctxAppObj.utm_content
        ) {
            accountCreateUrl = getAccountCreateUtmUrl(
                "ACCOUNT",
                ctxAppObj.utm_source,
                ctxAppObj.utm_medium,
                ctxAppObj.utm_campaign,
                ctxAppObj.utm_content,
                "get_site_global_menu"
            );
        }

        if (controllerInfoObj.containerName === 'CONTAINER_CLUB_GLOBAL_MENU') {
            return (
                <>

                    <AfdDefaultNavbar
                        controllerInfoObj={controllerInfoObj}
                        routes={menutypeParentObjList}
                        action={{
                            type: "internal",
                            route: userInfoObj.user_id > 0 ?
                                    linkDictionary.CLUB_STARTPAGE :
                                    accountCreateUrl,
                            label: userInfoObj.user_id > 0 ?
                                "Klub Mam" :
                                "Konto Foto-Mamy",
                            color: "dark",
                        }}
                        light={menuIsLight}
                        transparent={menuIsTransparent}
                        userInfoObj={userInfoObj}
                    />

                </>
            );
        }
    }

    function getClubMenuContent() {
        /* Nie będziemy bazować na menutype wybranej pozycji bo i tak menu chcemy konkretne */
        let menutypeParentObjList = elementRouteObjList ? elementRouteObjList.filter((elementRouteObj: IGlobalMenuParent) => {
                return elementRouteObj.menutype ===  'dummy';//menuItemObj.xx_display_menutype;
            }
        ) : [];
        let row = containerInfoObj;
        let temp = [
            { type: "title", title: "Docsx", key: "title-docs" }
        ]
        let menuIsLight = (menuItemObj.xx_react_gantry === 'rt-contact-cover' ? false : true);
        let menuIsTransparent =  (menuItemObj.xx_react_gantry === 'rt-contact-cover' ? false : true);;

        // Open sidenav when mouse enter on mini sidenav
        const handleOnMouseEnter = () => {
            if (miniSidenav && !onMouseEnter) {
                setMiniSidenav(dispatchFunction, false);
                setOnMouseEnter(true);
            }
        };

        // Close sidenav when mouse leave mini sidenav
        const handleOnMouseLeave = () => {
            if (onMouseEnter) {
                setMiniSidenav(dispatchFunction, true);
                setOnMouseEnter(false);
            }
        };

        if (controllerInfoObj.containerName === 'CONTAINER_CLUB_GLOBAL_MENU') {
            return (
                <>
                    {(
                        <>
                            <Sidenav
                                routes={menutypeParentObjList}
                                userInfoObj={userInfoObj}
                                color={sidenavColor}
                                brand={brand}
                                brandName="Soft UI Dashboard PRO"
                                onMouseEnter={handleOnMouseEnter}
                                onMouseLeave={handleOnMouseLeave}
                            />

                        </>
                    )}
                </>
            );
        }
    }

    return (
        <>
            <Suspense fallback={ <AfdLoadingFullpage
            componentCategory="AfdController_Suspense"
            controllerInfoObj={controllerInfoObj}
        />}>
        </Suspense>
            {
                (elementRouteObjList?.length > 0 && containerInfoObj
                ) ?
                    (
                        <AfdContainerCard
                            controllerInfoObj={controllerInfoObj}
                            userInfoObj={userInfoObj}
                            paramObj={paramObj}
                            elementObjList={elementRouteObjList}
                        >
                            {
                                (menuItemObj.xx_display_menutype === 'mainmenu' ?
                                    getSiteMenuContent()
                                    :
                                    getClubMenuContent()
                                )
                            }
                        </AfdContainerCard>

                    ) : (<Emptybox
                        controllerInfoObj={controllerInfoObj}
                        userInfoObj={userInfoObj}
                        paramObj={paramObj}
                        elementObjList={elementRouteObjList}
                    />)
            }
        </>
    );
}

export default GetSiteGlobalMenu;
