import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
import IUserInfo from "../../../type/IUserInfo";
import SoftBox from "../../../components/SoftBox";
import {IElementForumChapterIssueAsset} from "./SetForumChapterIssueAssetReplace";
import {IElementForumChapterContent} from "../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import IContainerQuery from "../../../type/IContainerQuery";
import IParamObj from "../../../type/IParamObj";
import parse from "html-react-parser";
import {IElementIssueItemReplace} from "./SetForumChapterIssueItemReplace";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import SoftTypography from "../../../components/SoftTypography";
import Iframe from 'react-iframe'

export interface IProp {
    content: string | React.ReactNode;
    elementChapterIssueObj: IElementForumChapterContent;
    paramObj: IParamObj;
    elementIssuePartObjList: IElementForumChapterIssuePartReplace[];
}


export interface IElementForumChapterIssuePartReplace {
    category_id: number;
    chapter_forum_id: number;
    chapter_issue_code: string;
    site_page_item_id: number;
    part_seq: number;
    part_title: string;
    part_type: string;
    part_description: string;
    part_outro: string;
    part_path: string;
    part_content_type: string;
    part_type_color: string;
    part_type_name: string;
    part_medium_thumb_url: string;
    part_small_thumb_url: string;
    part_big_thumb_url: string;
    part_duration: number;
    number_of_likes: number;
    created_datetime: string;
    modified_datetime: string;
    part_is_hd: string;
    refresh_time: string;
    part_duration_minute: number;
    new_release_comment: string;
    new_release_date: string;
    part_width: number;
    part_height: number;
    link_title: string;
    cam_orig_filename: string;
    cam_ready_filename: string;
    cam_mov_ready_filename: string;
    ftp_check_cam_orig: string;
    ftp_check_cam_ready: string;
    ftp_check_mov_ready: string;
    chapter_issue_type: string;
}

const setForumChapterIssuePartReplace = ({
                                             content,
                                             elementChapterIssueObj,
                                             paramObj,
                                             elementIssuePartObjList,
                                         }: IProp
) => {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    /* Założenie: JEDEN tag  [[CONTENT_PART]] bez oznaczenia dla danego ISSUE
    - SQL zwraca jeden rekord per ISSUE
     */
    let currentElementIssuePartObj: IElementForumChapterIssuePartReplace = elementIssuePartObjList[0];

    /*if (elementIssuePartObjList?.length > 0) {
        elementIssuePartObjList.forEach(item => {
            const divContent = `<div class="ELEMENT_FORUM_CHAPTER_ISSUE_CONTENT_PART">${item.part_content_type}</div>`;
            renderedContent = renderedContent.replace(new RegExp(`\\[\\[CONTENT_PART_${item.part_path}\\]\\]`, 'g'), divContent);
            renderedContent = renderedContent.replace('[[CONTENT_PART]]', divContent);
        })
    }
    return parse(renderedContent);*/

    function getContent(currentElementIssuePartObj: IElementForumChapterIssuePartReplace
    ) {
        if (currentElementIssuePartObj) {
            /*let videoFullSrc = "//player.vimeo.com/video/" + currentElementIssuePartObj.part_path + "?title=0&byline=0&portrait=0&color=ffd966";*/
            var videoFullSrc = '//player.vimeo.com/video/' + currentElementIssuePartObj.part_path;
            var allowfullscreen = true;
            var frameborder = 0;

            return <>
                <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                >
                    <SoftBox display="flex"
                             color={currentElementIssuePartObj.part_type_color}
                    >
                        <SoftBox>
                            <SoftTypography variant="button"
                            >
                                <span>{currentElementIssuePartObj.part_type_name}:</span>
                            </SoftTypography>
                            <SoftTypography
                                variant="body2"
                            >
                                <SoftTypography
                                    variant={"caption"}
                                >Film nr&nbsp; {currentElementIssuePartObj.part_seq}:&nbsp;</SoftTypography>
                                <SoftBox
                                component="a"
                                name={currentElementIssuePartObj.part_seq.toString()}
                            >
                                     {currentElementIssuePartObj.part_title}
                                    {currentElementIssuePartObj.part_description}
                            </SoftBox>
                            </SoftTypography>


                        </SoftBox>
                        <SoftBox>{currentElementIssuePartObj.part_outro}</SoftBox>
                    </SoftBox>
                    <SoftBox display="flex"
                             flexDirection="row"
                             alignItems="center"
                             justifyContent="center"
                    >
                        <Iframe url={videoFullSrc}
                                id={currentElementIssuePartObj.part_path}
                                width={globalConfigParamObj.isMobile ? "100%" : "600"}
                                height="450"
                            /*className="myClassname"*/
                                display="block"
                                allowFullScreen={allowfullscreen}
                                frameBorder={frameborder}
                                position="relative"
                                styles={{
                                    "min-height": "30vw"
                                }}
                        />

                        {/*          <iframe
                                src={videoFullSrc}
                                width="600"
                                height="450"
                                frameBorder="0"
                                allowFullScreen>
                            </iframe>*/}
                    </SoftBox>
                </SoftBox>
            </>;
        }
    }

    return currentElementIssuePartObj ? <SoftBox
        mx={{xs: 0.5, sm: 1, md: 2}}
        my={{xs: 1, sm: 2, md: 3}}
        px={{xs: 0.5, sm: 1, md: 2}}
        py={{xs: 1, sm: 2, md: 3}}
        sx={
        globalConfigParamObj.isMobile ? {} :
        {
            borderLeftWidth: "3px",
            borderLeftStyle: "solid",
        }
        }
    >
        {
            <>{getContent(currentElementIssuePartObj)}</>
        }
    </SoftBox> : null;
}

export default setForumChapterIssuePartReplace;

