import React, {Suspense, useContext, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
// @ts-ignore
import ImgsViewer from "react-images-viewer";
import SoftBox from "../../../components/SoftBox";
import {IElementForumChapterIssueAsset} from "./SetForumChapterIssueAssetReplace";
import {IElementForumChapterContent} from "../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import IContainerQuery from "../../../type/IContainerQuery";
import IParamObj from "../../../type/IParamObj";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import IPropContainer from "../../../type/IPropContainer";
import SoftAvatar from "../../../components/SoftAvatar";
import {IElementForumIssueContentCourseDict2Dict} from "./GetForumIssueContentCourseDict2Dict";
import {getImageZoomIconTSX} from "../newbb/get_phpbb_image/GetPhpbbImageUrl";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";
import {getAddHost} from "../../../util/UtilReact";
import GetWelcome from "../GetWelcome";

export interface IProp {
    content: string | React.ReactNode;
    elementChapterIssueObj: IElementForumChapterContent;
    paramObj: IParamObj;
    elementIssueItemObjList: IElementIssueItemReplace[];
}

export interface IElementContentTypeCourseRecom {
    description: string;
    description_ubar: string;
    course_thumb: string;
    course_url: string;
    course_promo_text: string | null;
}

export interface IElementContentTypeImage {
    image_mid_thumb_src: string;
    image_thumb_src: string;
    user_profile_url: string;
    user_full_name: string;
    picture_link: string;
    username: string;
}

export interface IElementIssueItemReplace {
    issue_item_code: string;
    chapter_issue_code: string;
    issue_item_type: string;
    issue_item_seq: number;
    issue_item_title: string;
    issue_item_image_id: number;
    issue_item_ext_image_path: string;
    issue_item_content: string;
    issue_item_url: string;
    image_light_thumb_src: string;
    image_url: string;
    image_published: string;
    image_author_full_name: string;
    instructor_name: string;
    instructor_image_path: string;
    instructor_url: string;
    instructor_letter: string | null;
    issue_item_course_category_id: number;
    issue_item_custom1: string;
    is_local: string;
    css_content_source_display: string;
    issue_item_local_url: string;
}

const SetForumChapterIssueItemReplace = ({
                                             content,
                                             elementChapterIssueObj,
                                             paramObj,
                                             elementIssueItemObjList
                                         }: IProp) => {
    let adminLinkString = "";
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [imgsViewer, setImgsViewer] = useState<boolean>(false);
    const openImgsViewer: () => void
        = () => setImgsViewer(true);
    const closeImgsViewer: () => void
        = () => setImgsViewer(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

    if (paramObj.position_param4 === 'Y') {
        adminLinkString = ` get_a_link(getBackendModuleFromNameUrl(i.issue_item_code), 'Edytuj', 'Edytuj') admin_edit_link, `;
    }

    /*CONTENT_HINT
    CONTENT_IMAGE
    CONTENT_LINK
    COURSE_RECOM
    MORE_TECH_HINT*/
    let currentElementIssueItemObj: IElementIssueItemReplace | undefined = elementIssueItemObjList.find((item: IElementIssueItemReplace) => {
        return (item.issue_item_code == content);
    });

    /* Rozwiązuje problem złożonych warunków gdzie de facto to różne TSX */
    let issueItemType: string = currentElementIssueItemObj?.issue_item_type ? currentElementIssueItemObj?.issue_item_type : '';
    issueItemType = (issueItemType == 'CONTENT_IMAGE' && currentElementIssueItemObj && (!currentElementIssueItemObj?.issue_item_image_id)) ? 'CONTENT_IMAGE_EXT' : issueItemType;

    var [elementCourseRecomObjList] = useCHSourcererData<IElementContentTypeCourseRecom>(
            'set_forum_chapter_issue_item_replace',
            'sql_query2',
            {
                category_id: currentElementIssueItemObj ? currentElementIssueItemObj?.issue_item_course_category_id : -1,
            },
            (issueItemType == 'COURSE_RECOM')
        )
    ;

    var [elementIssueInternalImageObjList] = useCHSourcererData<IElementContentTypeImage>(
            'set_forum_chapter_issue_item_replace',
            'sql_query3',
            {
                issue_item_image_id: currentElementIssueItemObj ? currentElementIssueItemObj?.issue_item_image_id : -1,
            },
            (issueItemType == 'CONTENT_IMAGE')
        )
    ;

    let contentType2Color: {
        [key: string]: string;
    }
        = {
        'CONTENT_HINT': "purple",
        'CONTENT_IMAGE': "blue",
        'CONTENT_LINK': "green",
        'COURSE_RECOM': "orange",
        'MORE_TECH_HINT': "red",
    }


    function getContent(currentElementIssueItemObj: IElementIssueItemReplace,
                        issueItemType: string,
    ) {
        /* Display image with functions */
        function getImageTSX(
            currentElementIssueItemObj: IElementIssueItemReplace,
            issueItemContent: React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | Iterable<React.ReactNode>
        ) {
            return <SoftBox display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            position={"relative"}
                            px={{xs: 1, sm: 2}}
                            py={{xs: 1, sm: 2}}
            >
                <SoftBox
                    component={"img"}
                    src={getAddHost(currentElementIssueItemObj.image_light_thumb_src, true)}
                    alt={'Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone'}
                    borderRadius={"sm"}
                    sx={
                        globalConfigParamObj?.isMobile ?
                            {
                                height: "auto",
                                maxHeight: "75vh",
                                maxWidth: "100%",
                            } : {
                                height: "auto",
                                maxHeight: "65vh",
                                maxWidth: "90%",
                            }
                    }
                />
                <SoftBox
                    display="flex"
                    flexDirection="row"
                    px={{xs: 1, sm: 2}}
                    py={{xs: 1, sm: 2}}
                >
                    <SoftTypography variant="body1">
                        {issueItemContent}
                        {/* [[ ADMIN_EDIT_LINK ]] */}
                    </SoftTypography>
                </SoftBox>
                <SoftBox
                    position={"absolute"}
                    bottom={"5%"}
                    right={"5%"}
                >
                    {getImageZoomIconTSX(
                        currentElementIssueItemObj.image_light_thumb_src,
                        imgsViewer,
                        closeImgsViewer,
                        openImgsViewer,
                        globalConfigParamObj
                    )}
                </SoftBox>
            </SoftBox>;
        }

        function getImageSourceInfoTSX() {
            return <SoftBox
                display="flex"
            >
                <SoftTypography
                    variant="caption"
                    color={'info'}
                    sx={{
                        wordBreak: "break-all", /* BUGFIX - kluczowe, aby SOURCE ukryte */
                    }}
                >
                    Źródło:
                    <SoftBox
                        component={"a"}
                        href={currentElementIssueItemObj.issue_item_url}
                        target="_blank"
                    ><SoftTypography
                        variant="caption"
                        color={'info'}
                    >
                        {currentElementIssueItemObj.issue_item_url}
                    </SoftTypography>
                    </SoftBox>
                </SoftTypography>
            </SoftBox>;
        }

        if (currentElementIssueItemObj) {
            let issueItemContent: React.ReactNode = parse(currentElementIssueItemObj.issue_item_content);
            if (issueItemType == 'CONTENT_LINK') {
                return <>
                    <SoftBox
                        display="flex"
                        flexDirection={globalConfigParamObj.isMobile ? "column" : "row"}
                        alignItems="center"
                        justifyContent="space-between" /* wyrównanie przycisków do prawej strony */
                    >
                        <SoftBox display="flex"
                                 flexDirection="column"
                        >
                            <SoftTypography
                                variant={'button'}
                            >{currentElementIssueItemObj.issue_item_title}:</SoftTypography>
                            <SoftTypography
                                variant={'button'}
                                fontWeight={'light'}
                                align={'center'}
                            >
                                {issueItemContent}:
                                {/* TODO
                            <Link to={currentElementIssueItemObj.admin_edit_link}>Edit</Link>*/}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox display="flex"
                                 flexDirection="row"
                                 px={{xs: 0.5, sm: 1, md: 2}}
                                 py={{xs: 0.5, sm: 1, md: 2}}
                        >
                            <SoftButton
                                component={Link}
                                to={currentElementIssueItemObj.issue_item_url}
                                variant={'outlined'}
                                color={'secondary'}
                                size={'small'}
                            >
                                zobacz...
                            </SoftButton>
                        </SoftBox>
                    </SoftBox>
                </>;
            } else if (
                issueItemType == 'CONTENT_HINT' ||
                issueItemType == 'MORE_TECH_HINT'
            ) {
                return <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                ><SoftTypography
                    variant={'body2'}
                    fontWeight={'light'}
                    fontStyle={'italic'}
                    align={'center'}
                >{issueItemContent}</SoftTypography></SoftBox>
            } else if (
                issueItemType == 'CONTENT_PART'
            ) {
                return <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                >{issueItemContent}</SoftBox>
            } else if (
                issueItemType == 'INSTRUCTOR_CHOICE'
            ) {
                if (currentElementIssueItemObj.instructor_letter) {
                    return <SoftBox
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                        py={{xs: 1, sm: 1}}
                    >
                        <GetWelcome team_letter={currentElementIssueItemObj.instructor_letter}
                                    content={issueItemContent}
                                    ending={''}
                                    title={currentElementIssueItemObj.issue_item_title}
                                    instructorInfoPosition={"tooltip"}
                        />
                    </SoftBox>
                } else {
                    return null;
                }


            } else if (issueItemType == 'CONTENT_IMAGE') {
                return <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    {getImageTSX(
                        currentElementIssueItemObj,
                        issueItemContent)}

                    <SoftBox display="flex">
                        <SoftBox display="flex">
                            <SoftBox display="flex" alignItems="center">

                                <Link to={elementIssueInternalImageObjList[0].user_profile_url}>
                                    <SoftAvatar
                                        src={elementIssueInternalImageObjList[0].picture_link}
                                        variant={'circle'}
                                    />
                                </Link>
                                <SoftTypography variant="body2" ml={1}>
                                    Wykonane przez: <strong>{elementIssueInternalImageObjList[0].username}</strong>
                                </SoftTypography>
                            </SoftBox>
                            <SoftBox>
                                <Link to={elementIssueInternalImageObjList[0].user_profile_url}>
                                    <SoftTypography variant="caption">(profil)</SoftTypography>
                                </Link>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox display="flex">
                        <SoftBox display="flex">
                            <SoftTypography variant="body2">
                                {/* TODO
                                {currentElementIssueItemObj.css_content_exif}*/}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>
                </SoftBox>;
            } else if (issueItemType == 'CONTENT_IMAGE_EXT') {
                return <SoftBox display="flex" flexDirection="column">
                    {getImageTSX(
                        currentElementIssueItemObj,
                        issueItemContent)}

                    <SoftBox mt={1} display={currentElementIssueItemObj.css_content_source_display}
                             flexDirection="column">
                        {getImageSourceInfoTSX()}
                    </SoftBox>
                </SoftBox>;

            } else if (issueItemType == 'COURSE_RECOM'
            ) {
                let coursePromoText = elementCourseRecomObjList[0].course_promo_text ? parse(elementCourseRecomObjList[0].course_promo_text) : '';
                return <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent={'center'}
                >
                    <SoftTypography
                        variant={'button'}
                    >
                        Więcej na kursie Akademii: "{elementCourseRecomObjList[0].description}"
                    </SoftTypography>
                    <SoftBox display={globalConfigParamObj.isMobile ? "inline" : "flex"}
                             flexDirection="row"
                             alignItems="center"
                             justifyContent={'center'}
                    >
                        <SoftBox
                            component={Link}
                            to={elementCourseRecomObjList[0].course_url}
                            title={currentElementIssueItemObj.issue_item_title}
                            sx={globalConfigParamObj.isMobile ? {
                                float: 'left',
                                marginRight: '1em',
                                /*marginBottom: '0.5em'*/
                            } : {}
                            }
                        >
                            <SoftAvatar
                                variant="rounded"
                                size={'xl'}
                                src={elementCourseRecomObjList[0].course_thumb}
                                alt={`Kurs fotografii dziecięcej pakietu: ${elementCourseRecomObjList[0].description}`}
                            />
                        </SoftBox>
                        <SoftBox
                            display={globalConfigParamObj.isMobile ? "inline" : "flex"}
                            px={{xs: 1, sm: 2}}
                            py={{xs: 1, sm: 2}}
                        ><SoftTypography
                            variant={'button'}
                            fontWeight={'light'}
                        >
                            {/*Generalnie są komentarze do przekierowania na kursy, ale  gdzieniegdzie nie*/}
                            {currentElementIssueItemObj.issue_item_content ?
                                issueItemContent : coursePromoText
                            }</SoftTypography>
                            <SoftTypography
                                display={'block'}
                            >{currentElementIssueItemObj.issue_item_local_url}</SoftTypography>
                        </SoftBox>

                        <SoftBox/>
                    </SoftBox>
                </SoftBox>
            } else {
                return <SoftTypography
                    variant={'body2'}
                    color={"error"}
                >
                    {issueItemType}
                </SoftTypography>
            }
        }

        let templateModule = "";

        // Assuming you have a callBack named get_callback_issue_item_...(), replace ... with item.issue_item_type
        // let functionName = 'get_callback_issue_item_' + item.issue_item_type.toLowerCase();
        // templateModule = functionName(item);

        /* let cssElementClassName = 'ELEMENT_FORUM_CHAPTER_ISSUE_' + item.issue_item_type;

         if (cssElementClassName === 'ELEMENT_FORUM_CHAPTER_ISSUE_CONTENT_IMAGE' && !item.issue_item_image_id) {
             cssElementClassName += '_EXT';
         }

         if (item.image_published === '1' || item.issue_item_type !== 'CONTENT_IMAGE') {
             content = content.replace(
                 `[[${item.issue_item_code}]]`,
                 `<div class="${cssElementClassName}">${templateModule}</div>`
             )
         } else {
             content = content.replace(`[[${item.issue_item_code}]]`, '')
         }*/


        /*return parse(content);*/
    }

    if (issueItemType == 'INSTRUCTOR_CHOICE' && currentElementIssueItemObj) {
        return <>{getContent(currentElementIssueItemObj,
            issueItemType
        )}</>

    } else {
        return currentElementIssueItemObj ? <SoftBox
            mx={{xs: 1, sm: 2}}
            my={{xs: 2, sm: 3}}
            px={{xs: 2, sm: 3}}
            py={{xs: 1, sm: 2}}
            sx={{
                borderLeftWidth: "3px",
                borderLeftStyle: "solid",
                borderLeftColor: contentType2Color[issueItemType],
            }
            }
        >
            {
                (
                    (elementCourseRecomObjList.length > 0 || issueItemType != 'COURSE_RECOM') &&
                    (
                        elementIssueInternalImageObjList.length > 0 ||
                        issueItemType != 'CONTENT_IMAGE'
                    )
                ) &&
                <>{getContent(currentElementIssueItemObj,
                    issueItemType
                )}</>
            }
        </SoftBox> : null;
    }
}

export default SetForumChapterIssueItemReplace;
