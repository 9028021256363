import React, {Suspense, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../hook/useCHSourcererData";
import DefaultItem from "../../../examples/Items/DefaultItem";
import CtxAppObj from "../../../context/CtxApp";
import IUserInfo from "../../../type/IUserInfo";
import SoftBox from "../../../components/SoftBox";
import {IElementForumChapterIssueAsset} from "./SetForumChapterIssueAssetReplace";
import {IElementForumChapterContent} from "../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import IContainerQuery from "../../../type/IContainerQuery";
import IParamObj from "../../../type/IParamObj";
import parse from "html-react-parser";
import {IElementForumChapterIssueGlossaryReplace} from "./SetForumChapterIssueGlossaryReplace";
import SoftTypography from "../../../components/SoftTypography";
import {IElementForumChapterIssuePartReplace} from "./SetForumChapterIssuePartReplace";
import ICtxGlobalConfig from "../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../hook/useCHGetContextSoftUI";

export interface IProp {
    content: string | React.ReactNode;
    elementChapterIssueObj: IElementForumChapterContent;
    paramObj: IParamObj;
    elementIssueJwallpaperObjList: IElementIssueJwallpaperReplace[];
}

export interface IElementIssueJwallpaperReplace {
    issue_item_type: string;
    item_published: string;
    item_image_light_thumb_src: string;
    item_image_url: string;
    item_image_id: number;
}

export function SetForumChapterIssueJwallpaperReplace(
    {
        content,
        elementChapterIssueObj,
        paramObj,
        elementIssueJwallpaperObjList
    }: IProp
) {
    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();

    let currentElementIssueJwallpaperObj: IElementIssueJwallpaperReplace | undefined = elementIssueJwallpaperObjList.find((item: IElementIssueJwallpaperReplace) => {
        /* TODO return (item.part_path == content);*/
    });

/*    const css_element_class_name = 'ELEMENT_FORUM_CHAPTER_ISSUE_CONTENT_IMAGE';

    elementIssueJwallpaperObjList.forEach((elementIssueJwallpaperObj: IElementIssueJwallpaperReplace) => {
        const template_module = `get_callback_issue_item_${elementIssueJwallpaperObj.issue_item_type}(elementIssueJwallpaperObj)`;
        if (elementIssueJwallpaperObj.item_published === "1") {
            content = content.replace(`[[${elementIssueJwallpaperObj.item_image_id}]]`, `<div class="${css_element_class_name}">${template_module}</div>`);
        } else {
            content = content.replace(`[[${elementIssueJwallpaperObj.item_image_id}]]`, '');
        }
    });

    return parse(content);*/

    function getContent(currentElementIssueJwallpaperObj: IElementIssueJwallpaperReplace
    ) {
        if (currentElementIssueJwallpaperObj) {
            return <>
                <SoftBox
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                >


                </SoftBox>
            </>;
        }
    }

    return currentElementIssueJwallpaperObj ? <SoftBox
        mx={{xs: 1, sm: 2}}
        my={{xs: 2, sm: 3}}
        px={{xs: 2, sm: 3}}
        py={{xs: 1, sm: 2}}
        sx={{
            borderLeftWidth: "3px",
            borderLeftStyle: "solid",
        }
        }
    >
        {
            <>{getContent(currentElementIssueJwallpaperObj)}</>
        }
    </SoftBox> : null;

}
