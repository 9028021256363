// @ts-ignore
import BBCodeParser from "js-bbcode-parser/src";
import parse from "html-react-parser";
import parser, { domToReact, Element, HTMLReactParserOptions } from "html-react-parser";
import IElementNewbbTopicPost from "../type/newbb/IElementNewbbTopicPost";
import IParamObj from "../type/IParamObj";
import IContainerQuery from "../type/IContainerQuery";
import IElementForumOption from "../type/newbb/IElementForumOption";
import IUserInfo from "../type/IUserInfo";
import { addLeadingSlash, getAddImageHost, selectNSentencesAfterTag, setTextParagraphToBr } from "./UtilReact";
import GetPhpbbImageUrl from "../component/sourcerer/newbb/get_phpbb_image/GetPhpbbImageUrl";
import React from "react";
import ICtxClubNewbb from "../type/context/ICtxClubNewbb";
import { IElementGlobalConfig } from "../type/gloibal_config/IElementGlobalConfig";
import {
    IElementForumChapterContent,
} from "../component/controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import SetForumChapterIssueItemReplace, {
    IElementIssueItemReplace,
} from "../component/sourcerer/chapter/SetForumChapterIssueItemReplace";
import {
    IElementForumChapterIssueAsset,
    SetForumChapterIssueAssetReplace,
} from "../component/sourcerer/chapter/SetForumChapterIssueAssetReplace";
import SetForumChapterIssuePartReplace, {
    IElementForumChapterIssuePartReplace,
} from "../component/sourcerer/chapter/SetForumChapterIssuePartReplace";
import SetForumChapterIssueGlossaryReplace, {
    IElementForumChapterIssueGlossaryReplace,
} from "../component/sourcerer/chapter/SetForumChapterIssueGlossaryReplace";
import {
    IElementIssueJwallpaperReplace,
    SetForumChapterIssueJwallpaperReplace,
} from "../component/sourcerer/chapter/SetForumChapterIssueJwallpaperReplace";
import SoftBox from "../components/SoftBox";
import SoftTypography from "../components/SoftTypography";
import GetPhpbbImageDict from "../component/sourcerer/newbb/get_phpbb_image/GetPhpbbImageDict";
import GetPhpbbImageBlogPost from "../component/sourcerer/newbb/get_phpbb_image/GetPhpbbImageBlogPost";
import { IElementBlogPostViewGeneralInfo } from "../component/controller/get_site_blog_post_view/GetSiteBlogPostView";

export function getBBCodeParse(postText: string) {
    const bbCodeParser = new BBCodeParser({});
    bbCodeParser.setCodes({
        '\\[br\\]': '<br>',
        '\\[b\\]': '<strong>',
        '\\[/b\\]': '</strong>',
        '\\[i\\]': '<em>',
        '\\[/i\\]': '</em>',
        '\\[u\\]': '<u>',
        '\\[/u\\]': '</u>',
        '\\[s\\]': '<s>',
        '\\[/s\\]': '</s>',
        '\\[url\\](.*?)\\[/url\\]': '<a href="$1">$1</a>',
        '\\[url=(.*?)\\](.*?)\\[/url\\]': '<a href="$1">$2</a>',
        '\\[img\\](.*?)\\[/img\\]': '<img src="$1" />',
        '\\[img=(.*?)\\](.*?)\\[/img\\]': '<img src="$1" alt="$2" />',
        '\\[color=(.*?)\\](.*?)\\[/color\\]': '<span style="color:$1">$2</span>',
        '\\[size=(.*?)\\](.*?)\\[/size\\]': '<span style="font-size:$1px">$2</span>',
        '\\[font=(.*?)\\](.*?)\\[/font\\]': '<span style="font-family:$1">$2</span>',
        '\\[align=(.*?)\\](.*?)\\[/align\\]': '<div style="text-align:$1">$2</div>',
        '\\[list\\](.*?)\\[/list\\]': '<ul>$1</ul>',
        '\\[list=(.*?)\\](.*?)\\[/list\\]': '<ol type="$1">$2</ol>',
        '\\[\\*\\](.*?)\\[/\\*\\]': '<li>$1</li>',
        '\\[quote\\](.*?)\\[/quote\\]': '<blockquote>$1</blockquote>',
        '\\[quote=(.*?)\\](.*?)\\[/quote\\]': '<blockquote><cite>$1</cite>$2</blockquote>',
        '\\[cytat\\](.*?)\\[/cytat\\]': '<blockquote>$1</blockquote>',
        '\\[cytat=(.*?)\\](.*?)\\[/cytat\\]': '<blockquote><cite>$1</cite>$2</blockquote>',
        '\\[code\\](.*?)\\[/code\\]': '<pre>$1</pre>',
        '\\[youtube\\](.*?)\\[/youtube\\]': '<iframe width="560" height="315" src="https://www.youtube.com/embed/$1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        '\\[youtube=(.*?)\\](.*?)\\[/youtube\\]': '<iframe width="$1" height="$2" src="https://www.youtube.com/embed/$3" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        '\\[video\\](.*?)\\[/video\\]': '<video src="$1" controls></video>',
        '\\[video=(.*?)\\](.*?)\\[/video\\]': '<video width="$1" height="$2" src="$3" controls></video>',

    });
    console.log(bbCodeParser.parse('This is a text[br]with HTML Break.'));
    return bbCodeParser.parse(postText);
}

export function getIsPostFullwidth(elementPostObj: IElementNewbbTopicPost,
                                   paramObj: IParamObj,
                                   controllerInfoObj: IContainerQuery,
                                   ctxNewbbFunctionObj: ICtxClubNewbb
                                   ) {
    let isPostFullwidth: boolean = (elementPostObj.post_text_source ? true : false);
    if (ctxNewbbFunctionObj.storeNewbbUniForumInfoObj) {
        if (controllerInfoObj.position_code == 'body') {
            isPostFullwidth = false;
        } else if (controllerInfoObj.position_code == 'REACT-tab4-body') {
            isPostFullwidth = true;
        } else if (ctxNewbbFunctionObj.storeNewbbUniForumInfoObj.discussion_topic_id ||
            ctxNewbbFunctionObj.storeNewbbUniForumInfoObj.forum_post_final_topic_id ||
            ctxNewbbFunctionObj.storeNewbbUniForumInfoObj.forum_post_submit_topic_id
        ) {
            isPostFullwidth = true;
        } else {
            isPostFullwidth = false;
        }
    }
    return isPostFullwidth;
}

/* z konkretnej tablicy w kontekśćie */
export function findElementValue(optionObjList: IElementForumOption[],
                                 optionName: "f_subscribe" | "m_edit" | "f_reply" | "m_report" | "f_delete" | "f_edit"
) {
    return optionObjList.find((elementForumOptionObj: IElementForumOption) => {
            return elementForumOptionObj.option_name === optionName;
        }
    )?.option_value;
}


export function getPostTextRemainingTSX(
    userInfoObj: IUserInfo,
    elementPostObj: IElementNewbbTopicPost,
    postText: string,
    isPostFullwidth: boolean,
    postTextSource?: string,
    isCollapse: boolean = true,
) {


    postText = selectNSentencesAfterTag(postText, 2);

    if (!postTextSource && isCollapse == true
    ) {
        postText = getBBCodeParse(postText);
        //postText = getPhpbbTextBetweenTags(postText, 'zdjecie', 'zdjecie');

        const newContent = replaceZdjecieBBCodeTags(setTextParagraphToBr(postText));
        const  parserOptions: HTMLReactParserOptions = {
            replace: domNode => {
                if (domNode instanceof Element && domNode.attribs) {
                    if (domNode.type === 'tag' && domNode.attribs.content) {
                        return (<GetPhpbbImageUrl
                            userInfoObj={userInfoObj}
                            elementPostObj={elementPostObj}
                            url={domNode.attribs.content}
                            mode={"DISCUSSION"}
                            isPostFullwidth={isPostFullwidth}
                            image_seq={null}
                            post_id={null}
                        />);
                    } /*else                     if (domNode.type === 'tag' && domNode.name == 'strong') {
                        return <>xx</>;
                    }*/
                }
            }

        };
        return <div>{parser(newContent, parserOptions)}</div>


        /* non sourcerer post */

        /* BUGFIX:
        */
        /*     var post_text = elementPostObj['post_text'];
             post_text = str_replace('&lt;/strong&gt;', '[/b]', post_text);
             post_text = str_replace('&lt;strong&gt;', '[b]', post_text);*/
        //$post_text = str_replace('&lt;/strong&gt;', '</strong>', $post_text);
        //$post_text = str_replace('&lt;strong&gt;', '<strong>', $post_text);
        //$post_text = str_replace('</strong>', '[/b]', $post_text);
        //$post_text = str_replace('<strong>', '[b]', $post_text);

        /*var afdParser
        parse(post_text);
        var html_text = afdParser.getAsHtml();
        html_text = get_post_text_span_wrap(html_text);
        html_text = get_post_text_image_replace(html_text, 'zdjecie', elementPostObj['post_url']);

        /!* Tekst *!/
        template = str_replace('[[CONTENT_POST_TEXT]]', html_text, template);*/
        /*return postText;*/
    }
    return null;
}

// TODO
export function getPostTextTSX(
    userInfoObj: IUserInfo,
    elementPostObj: IElementNewbbTopicPost,
    elementPostReplyObj: IElementNewbbTopicPost,
    postText: string,
    postTextSource?: string,
    isCollapse: boolean = true,
) {

    //postText = selectNSentencesAfterTag(postText, 2);

    if (!postTextSource && isCollapse == true
    ) {
        const bbCodeParser = new BBCodeParser({});
        bbCodeParser.setCodes({});
        postText = bbCodeParser.parse(postText);
        //postText = getPhpbbTextBetweenTags(postText, 'zdjecie', 'zdjecie');

        const newContent = replaceZdjecieBBCodeTags(setTextParagraphToBr(postText));
        let parserOptions = {
            replace: ({type, attribs}: any) => {
                if (type === 'tag' && attribs.content)
                    return (<GetPhpbbImageUrl
                        userInfoObj={userInfoObj}
                        elementPostObj={elementPostObj}
                        elementPostReplyObj={elementPostReplyObj}
                        url={attribs.content}
                        mode={"DISCUSSION"}
                        image_seq={null}
                        post_id={null}
                    />);
            }
        };
        return <div>{parser(newContent, parserOptions)}</div>


        /* non sourcerer post */

        /* BUGFIX:
        */
        /*     var post_text = elementPostObj['post_text'];
             post_text = str_replace('&lt;/strong&gt;', '[/b]', post_text);
             post_text = str_replace('&lt;strong&gt;', '[b]', post_text);*/
        //$post_text = str_replace('&lt;/strong&gt;', '</strong>', $post_text);
        //$post_text = str_replace('&lt;strong&gt;', '<strong>', $post_text);
        //$post_text = str_replace('</strong>', '[/b]', $post_text);
        //$post_text = str_replace('<strong>', '[b]', $post_text);

        /*var afdParser
        parse(post_text);
        var html_text = afdParser.getAsHtml();
        html_text = get_post_text_span_wrap(html_text);
        html_text = get_post_text_image_replace(html_text, 'zdjecie', elementPostObj['post_url']);

        /!* Tekst *!/
        template = str_replace('[[CONTENT_POST_TEXT]]', html_text, template);*/
        /*return postText;*/
    }
    return null;
}

/* Parse POST from TEMPLATE FILLED with different data */
export function getBlogPostTextTSX<T>(
  userInfoObj: IUserInfo,
  contentId: number,
  elementGeneralInfoObjList: IElementBlogPostViewGeneralInfo,
  elementTemplateInfoObj: T,
  postText: string
) {
        const newContent = replaceZdjecieBBCodeTags(setTextParagraphToBr(postText));
        let parserOptions = {
            replace: ({type, attribs}: any) => {
                if (type === 'tag' && attribs.content)
                    return (<GetPhpbbImageBlogPost
                      userInfoObj={userInfoObj}
                      url={attribs.content}
                      contentId={contentId}
                      elementGeneralInfoObjList={elementGeneralInfoObjList}
                      elementTemplateInfoObj={elementTemplateInfoObj}
                    />);
            }
        };
        return <div>{parser(newContent, parserOptions)}</div>
}


export function getGlobalConfigParserOption(elementGlobalValueListObj: IElementGlobalConfig[],
                                            formatterFunction: (any: string | JSX.Element | JSX.Element[]) => any,
) {
    const parserOptions: {
        replace: (domNode: any) => any
    }
      = {
        replace: (domNode: any) => {
            if (domNode instanceof Element) {
                if (domNode.attribs.content === "REGISTRATION_BUSYMUM_ADDITIONAL_PAY_PERCENT") {
                    return <>{formatterFunction(parse(elementGlobalValueListObj[0].registration_busymum_additional_pay_percent.toString()))}</>;
                }
                if (domNode.attribs.content === "COURSE_CAL_CHAPTER_OFF_AFTER_BUSY_ON") {
                    return <>{formatterFunction(parse(elementGlobalValueListObj[0].course_close_busymum_day.toString()))}</>;
                }
            }
        },
    };
    return parserOptions;
} /* Pobiera myDIV z REF i formatuje */


function getWpPostContentTagReplaceOptions(rowPostContentGeneral: any): HTMLReactParserOptions {
    return {
        replace: (domNode) => {
            if (domNode instanceof Element && domNode.attribs && domNode.name === 'a') {
                return React.createElement('a', { href: domNode.attribs.href }, 'Replaced Content');
            }
        }
    };
}

export default getWpPostContentTagReplaceOptions;

/* parse CHAPTER ISSUE CONTENT */
export function getIssueContentParserOption(
  elementChapterIssueObj: IElementForumChapterContent,
  paramObj: IParamObj,
  elementIssueItemObjList: IElementIssueItemReplace[],
  elementIssueAssetObjList: IElementForumChapterIssueAsset[] | null,
  elementIssuePartObjList: IElementForumChapterIssuePartReplace[] | null,
  elementIssueGlossaryObjList: IElementForumChapterIssueGlossaryReplace[] | null,
  elementIssueJwallpaperObjList: IElementIssueJwallpaperReplace[] | null,
) {
    const contentParserOptions: {
        replace: (domNode: any) => any
    } = {
        replace: (domNode) => {
            if (domNode.name === "bbtagitem" && domNode.attribs && domNode.attribs.content) {
                return <SetForumChapterIssueItemReplace
                  content={domNode.attribs.content}
                  elementChapterIssueObj={elementChapterIssueObj}
                  paramObj={paramObj}
                  elementIssueItemObjList={elementIssueItemObjList}
                />;
            }
            if (domNode.name === "bbtagasset" && domNode.attribs && domNode.attribs.content && elementIssueAssetObjList) {
                return <SetForumChapterIssueAssetReplace
                  content={domNode.attribs.content}
                  elementChapterIssueObj={elementChapterIssueObj}
                  paramObj={paramObj}
                  elementIssueAssetObjList={elementIssueAssetObjList}
                />;
            }
            if (domNode.name === "bbtagcontentpart" && domNode.attribs && domNode.attribs.content && elementIssuePartObjList) {
                return <SetForumChapterIssuePartReplace
                  content={domNode.attribs.content}
                  elementChapterIssueObj={elementChapterIssueObj}
                  paramObj={paramObj}
                  elementIssuePartObjList={elementIssuePartObjList}
                />;
            }
            if (domNode.name === "bbtagglossary" && domNode.attribs && domNode.attribs.content && elementIssueGlossaryObjList) {
                return <SetForumChapterIssueGlossaryReplace
                  content={domNode.attribs.content}
                  elementChapterIssueObj={elementChapterIssueObj}
                  paramObj={paramObj}
                  elementIssueGlossaryObjList={elementIssueGlossaryObjList}
                />;
            }
            if (domNode.name === "bbtagjwallpaper" && domNode.attribs && domNode.attribs.content && elementIssueJwallpaperObjList) {
                return <SetForumChapterIssueJwallpaperReplace
                  content={domNode.attribs.content}
                  elementChapterIssueObj={elementChapterIssueObj}
                  paramObj={paramObj}
                  elementIssueJwallpaperObjList={elementIssueJwallpaperObjList}
                />;
            }
            if (domNode.name === "dictimage" && domNode.attribs) {
                return (<>
                    <GetPhpbbImageDict
                  elementChapterIssueObj={elementChapterIssueObj}
                  nodeAttribs={domNode.attribs}
                />
                </>);
            }
            /*
            BUGFIX: to jest bardzo szeroki zakres i nie wyświetla poprawnie treści pomiędzy TAGami
                if (domNode.name === 'p') {
                    return (
                        <p style={{
                            paddingTop: "0.5em",
                            paddingBottom: "0.5em",
                        }}>
                            {domToReact((domNode.children as DOMNode[]))}
                        </p>
                    );
                }*/
            /* if (domNode.name === 'ol'
             ) {
                 return (
                     <SoftBox
                         component="ol"
                         // @ts-ignore
                         style={
                             {
                                 paddingTop: "1em",
                                 paddingBottom: "1em",
                             }
                         }>
                         {domNode.children[0].data}
                     </SoftBox>
                 );
             }*/
            /*    if (domNode.name === 'li') {
                    return (
                        <SoftBox
                            component="li"
                            // @ts-ignore
                            style={
                                {marginLeft: '3em',
                                    lineHeight: '1.5',
                                }
                            }>
                            {domNode.children[0].data}
                        </SoftBox>
                    );
                }*/
            /* treść pomiędzy TAGami */
            if (domNode.name === "blockquote") {
                return <SoftBox
                  display="flex"
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  px={{ xs: 2, sm: 2, md: 3 }}
                  py={{ xs: 2, sm: 2, md: 3 }}
                  sx={{
                      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
                      borderRadius: "8px",
                      backgroundColor: "rgba(224, 224, 224, 0.5)",
                  }}
                >
                    {
                        /*BUGFIX: na wypadek błędnego HTML -- nie ma wtedy CHILD */
                        domNode.children?.length > 0 ? <SoftTypography
                            variant={"body2"}
                            fontWeight={"light"}
                            align={"center"}
                          >{domToReact([domNode])}</SoftTypography> :
                          <SoftTypography
                            variant={"body1"}
                            fontWeight={"light"}
                            align={"center"}
                            color={"error"}
                          >Błąd wyświetlania: bbtagblockquote</SoftTypography>
                    }</SoftBox>;
            }
        },
    };
    return contentParserOptions;
}

export function setRefDivCssFormat(myDiv: null) {
    /*Format basic HTML
    - forum chapter intro
    - forum chapter content
    * */
    if (myDiv) {
        // @ts-ignore
        const allPTags = myDiv.querySelectorAll("p"); // query inside myDiv
        allPTags.forEach((tag: any) => {
            tag.style.paddingTop = "0.5em";
            tag.style.paddingBottom = "1em";
        });
        // @ts-ignore
        const allBTags = myDiv.querySelectorAll("strong"); // query inside myDiv
        allBTags.forEach((tag: any) => {
            tag.style.fontWeight = "bold";
        });

        // @ts-ignore
        const allOlTags = myDiv.querySelectorAll("ol"); // query inside myDiv
        allOlTags.forEach((tag: any) => {
            tag.style.paddingTop = "1em";
            tag.style.paddingBottom = "1em";
            tag.style.paddingLeft = "2em";
        });
        // @ts-ignore
        const allUlTags = myDiv.querySelectorAll("ul"); // query inside myDiv
        allUlTags.forEach((tag: any) => {
            tag.style.paddingTop = "1em";
            tag.style.paddingBottom = "1em";
            tag.style.paddingLeft = "2em";
        });
    }
}

/* Nie da sie zrobić przez NODE i parser ponieważ same NODE CHILDren powinny być parsowane pod IMAGE
- przez REF formatowanie właściwe
 */
export function setRefXmlCssFormat(myDiv: null) {
    /*Format basic HTML
    - forum chapter intro
    - forum chapter content
    * */
    if (myDiv) {
        // @ts-ignore
        const allXMLQuestionItemTag = myDiv.querySelectorAll("question_item"); // query inside myDiv
        allXMLQuestionItemTag.forEach((tag: any) => {
            tag.style.display = "block";
        });

        // @ts-ignore
        const allXMLQuestionTag = myDiv.querySelectorAll("question"); // query inside myDiv
        allXMLQuestionTag.forEach((tag: any) => {
            /*const img = document.createElement("img");
            img.src = "path/to/your/image.png"; // Replace with the actual path to your image
            img.alt = "Question Icon";*/
            tag.style.fontWeight = "bold";
            tag.style.display = "block";
            tag.style.paddingRight = "20%";
        });

        // @ts-ignore
        const allXMLAnswerTag = myDiv.querySelectorAll("answer"); // query inside myDiv
        allXMLAnswerTag.forEach((tag: any) => {
            tag.style.display = "block";
            tag.style.paddingLeft = "20%";
        });

        // @ts-ignore
        const allXMLSpeakerTag = myDiv.querySelectorAll("speaker"); // query inside myDiv
        allXMLSpeakerTag.forEach((tag: any) => {
            tag.style.paddingLeft = "20%";
        });

        // @ts-ignore
        const allXMLGuestTag = myDiv.querySelectorAll("guest"); // query inside myDiv
        allXMLGuestTag.forEach((tag: any) => {
            tag.style.paddingLeft = "20%";
        });




    }
}

export function getPostTextAppendixParserOptions() {
    const parserOptions: HTMLReactParserOptions = {
        replace: (node: any) => {
            if (node.name === "a" && node.attribs.href.match(/\.(jpeg|jpg|gif|png)$/)) {
                /* BUGFIX: linki jwallpapers_files bez SLASH */
                let internalLink = node.attribs.href;
                if (internalLink.includes("jwallpapers_files")) {
                    internalLink = getAddImageHost(addLeadingSlash(internalLink));
                }
                return <><SoftBox
                  component={"img"}
                  sx={{
                      maxWidth: "100%",
                      maxHeight: "55vh",
                  }}
                  src={internalLink} alt={node.attribs.alt} /></>;
            }
 /*           if (node.name === "guest") {
                /!* BUGFIX: linki jwallpapers_files bez SLASH *!/
                return <>
                    <SoftTypography
                      variant={"button"}
                    >Gość: </SoftTypography> {node.children[0].data}
                </>;
            }
            if (node.name === "speaker") {
                /!* BUGFIX: linki jwallpapers_files bez SLASH *!/
                return <>
                    <SoftTypography
                      variant={"button"}
                    >Prowadząca: </SoftTypography> {node.children[0].data}
                </>;
            }
            if (node.name === "question") {
                /!* BUGFIX: linki jwallpapers_files bez SLASH *!/
                return <><SoftBox
                  alt={node.attribs.alt}
                  // @ts-ignore
                  pr={{ xs: "30%", sm: "30%", md: "30%" }}
                  py={{ xs: 0.5, sm: 0.5, md: 1 }}
                >
                    <SoftTypography
                      variant={"body2"}
                    >{node.children[0].data}</SoftTypography>
                </SoftBox>
                </>;
            }
            if (node.name === "answer") {
                /!* BUGFIX: linki jwallpapers_files bez SLASH *!/
                return <><SoftBox
                  alt={node.attribs.alt}
                  // @ts-ignore
                  pl={{ xs: "30%", sm: "30%", md: "30%" }}
                  py={{ xs: 0.5, sm: 0.5, md: 1 }}
                >
                    <SoftTypography
                      variant={"body2"}
                    >
                        {node.children[0].data}
                    </SoftTypography>
                </SoftBox>
                </>;
            }*/
        },


    };
    return parserOptions;
}

function replaceZdjecieBBCodeTagsInContent(content: string, tagName: string = "zdjecie", htmlTag: string = "div"): string {
    // Construct the replacement regex dynamically using the provided "tagName"
    const regex = new RegExp(`\\[${tagName}\\]([\\s\\S]*?)\\[\\/${tagName}\\]`, "g");
    // Replace all occurrences of the tag in the content
    content = content.replace(regex, `<${htmlTag}>$1</${htmlTag}>`);
    return content;
}

/* Example:
[blockquote]"dowolne narzędzie kierowania uwagi widza na treści przez nas zamierzone. W tym sensie
i w praktyce cały proces tworzenia fotografii będzie dla nas odpowiednim doborem elementów fotograficznych i w odpowiednich proporcjach. "[/blockquote]

function replaceZdjecieTags(content: string): string {
    content = content.replace(/\[zdjecie\]([\s\S]*?)\[\/zdjecie\]/g, `<div content="$1"></div>`);
    return content;
}
* */
function replaceZdjecieBBCodeTags(content: string, tagName: string = "zdjecie", htmlTag: string = "div"): string {
    // Construct the replacement regex dynamically using the provided "tagName"
    const regex = new RegExp(`\\[${tagName}\\]([\\s\\S]*?)\\[\\/${tagName}\\]`, "g");
    // Replace all occurrences of the tag in the content
    content = content.replace(regex, `<${htmlTag} content="$1"></${htmlTag}>`);
    return content;
}

/* Podwójny TAG ponieważ inaczej przeglądarka przeformatowuje i EMBED text */
export function replaceImageDictBBCodeTags(content: string, tagName: string = "DICTIMAGE"): string {
    // Construct the replacement regex dynamically using the provided "tagName"
    const regex = new RegExp(`\\[\\[${tagName}(.*?)\\]\\]`, "g");
    // Replace all occurrences of the tag in the content
    content = content.replace(regex, (match, attributes) => {
        // Convert BBCode attributes to HTML attributes
        const htmlAttributes = attributes.trim().split(" ").map((attr: { split: (arg0: string) => [any, any]; }) => {
            const [key, value] = attr.split("=");
            return `${key}=${value}`;
        }).join(" ");
        return `<dictimage ${htmlAttributes}></dictimage>`;
    });
    return content;
}

export { replaceZdjecieBBCodeTagsInContent };
export { replaceZdjecieBBCodeTags };