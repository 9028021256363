import useCHSourcererData from "../../../hook/useCHSourcererData";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import React from "react";
import {
    IElementForumChapterIssueImageList,
    IElementForumChapterIssueImageListDict
} from "./GetForumChapterIssueImageList";
import IUserInfo from "../../../type/IUserInfo";
import SoftBadge from "../../../components/SoftBadge";

export interface IPropCallback {
    userInfoObj: IUserInfo;
    chapterIssueCode: string;
    isAssigned: number;
    elementIssueImageObj: IElementForumChapterIssueImageList;
    assignPosition?: string;
    isAdmin?: boolean;
}


export function GetForumChapterIssueImageListDict(
    {
        userInfoObj,
        chapterIssueCode,
        isAssigned = 1,
        assignPosition = 'MAIN',
        elementIssueImageObj,
        isAdmin
    }: IPropCallback
) {


    var [elementImageDictObjList] = useCHSourcererData<IElementForumChapterIssueImageListDict>(
        'get_forum_chapter_issue_image_list',
        'sql_query2',
        {
            chapter_issue_code: chapterIssueCode,
            image_id: elementIssueImageObj.image_id,

        });


    /* Edycja MESSAGE
    TODO
    */

    /*if (isAdmin == 'Y')	{
        template = str_replace('[[CONTENT_ADMIN_EDIT]]', "<br>" + "" + row['admin_edit_link'], template);
    }*/

    function getContainerContent() {
        return (
            <>
                <SoftBox
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <SoftTypography
                        variant="button"
                        fontWeight={"regular"}
                    >
                        Kompetencje:
                    </SoftTypography>
                    <SoftBox
                        component="ul"
                    >
                        {
                            elementImageDictObjList.map(
                                (elementImageDictObj: IElementForumChapterIssueImageListDict, index: number) => {
                                    return (<>
                                            <SoftBadge
                                                data-for={index}
                                                data-tip=''
                                                variant={"gradient"}
                                                color={"secondary"}
                                                size={"sm"}
                                                badgeContent={
                                                    <SoftBox
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        justifyContent={'center'}
                                                        color={'white'}
                                                    >
                                                        <SoftTypography
                                                            variant={'caption'}
                                                            color={'white'}
                                                        >
                                                            {elementImageDictObj.dict_title}
                                                        </SoftTypography>
                                                    </SoftBox>
                                                }
                                            />
                                        </>
                                    );
                                }
                            )

                        }
                    </SoftBox>
                </SoftBox>
            </>
        );
    }

    return (
        <>
            {
                (elementImageDictObjList?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<></>)
            }
        </>
    );

}
