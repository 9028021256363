import React from "react";

// @mui material components
import useCHSourcererData from "../../../hook/useCHSourcererData";
import CtxAppObj from "../../../context/CtxApp";
import IUserInfo from "../../../type/IUserInfo";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import {Link} from "react-router-dom";
import {getAddHost} from "../../../util/UtilReact";
import SoftButton from "../../../components/SoftButton";
import {GetForumChapterIssueImageListDict} from "./GetForumChapterIssueImageListDict";
import GetForumChapterIssueImageListAdminEdit from "./GetForumChapterIssueImageListAdminEdit";
import Grid from "@mui/material/Grid";
import parse from "html-react-parser";

interface IProps {
    userInfoObj: IUserInfo;
    chapterIssueCode: string;
    isAssigned: number;
    assignPosition?: string;
    isAdmin?: boolean;
}


export interface IElementForumChapterIssueImageList {
    image_id: number;
    chapter_issue_code: string;
    assign_position: string;
    image_mid_thumb_src: string;
    seo_image_alt: string;
    title: string;
    url: string;
    exif_make: string;
    exif_aperture: string;
    exif_speed: string;
    exif_iso: string;
    exif_is_color: string;
    exif_crop_value: string;
    exif_focal_length: string;
    is_exif: number;
    user_profile_url: string;
    username: string;
    cb_miasto: string;
    image_assign_url: string;
    issue_item_content: string;
    issue_item_title: string;
    admin_edit_link: string;
}


export interface IElementForumChapterIssueImageListDict {
    dict_code: string;
    chapter_issue_title: string;
    dict_title_full: string;
    dict_title: string;
}

export function getImageUserInfoTSX(
  user_profile_url: string,
  username: string,
  cb_miasto: string
) {
    return <SoftBox
      pt={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
    >{
      user_profile_url && <SoftBox
        display="flex"
        flexDirection="column"
      >
          <SoftTypography
            variant="button"
            fontWeight={"light"}
          >
              Wykonane przez Mamę:{" "}
              <SoftBox component={Link}
                       to={getAddHost(user_profile_url)}
                       target={"_blank"}
              >
                  <strong>{username}</strong>
              </SoftBox>{" "}
              {cb_miasto}
          </SoftTypography>
      </SoftBox>
    }</SoftBox>;
}


function GetForumChapterIssueImageList(
    {
        userInfoObj,
        chapterIssueCode,
        isAssigned = 1,
        assignPosition = 'MAIN',
        isAdmin = false
    }: IProps
) {
    const appObj = React.useContext(CtxAppObj);

    var assigned_string = "";
    var container_title = "";
    if (isAssigned == 1) {
        assigned_string = " and g.issue_item_image_id IS NOT NULL ";
        container_title = "Fotografie wyróżnione do zagadnienia";
    } else {
        assigned_string = " and g.issue_item_image_id IS NULL ";
        container_title = "ADMIN: Przydziel zdjęcia";
    }

    /* Pobierz wszystkie zdjęcia z TAGAMI jakie są w danym ISSUE = podzapytanie o to z ISSUE_DICT
        -dodatkowo LEFT OUTER z zestawem zdjeć przypiętym do tego DICT aby sprawdzić czy ASSIGNED
        - wiele zdjęć przypiętych być może do różnych DICT w tym samym ISSUE więc GROUP po ID bo ostatecznie to jest lista zdjęć
            - w WHERE IFNULL bo może nie być w ogóle
    */




    var [elementIssueImageObjList] = useCHSourcererData<IElementForumChapterIssueImageList>(
        'get_forum_chapter_issue_image_list',
        'sql_query1',
        {
            chapter_issue_code: chapterIssueCode,
            assign_position: assignPosition,
            user_id: userInfoObj.user_id,
            is_assigned: isAssigned,
            assigned_string: assigned_string,

        });


    function getContainerContent() {
        function getImageDictListTSX(elementIssueImageObj: IElementForumChapterIssueImageList) {
            return             <GetForumChapterIssueImageListDict
                userInfoObj={userInfoObj}
                chapterIssueCode={chapterIssueCode}
                isAssigned={isAssigned}
                assignPosition={assignPosition}
                elementIssueImageObj={elementIssueImageObj}
                isAdmin={isAdmin}

            />;
        }

        function getImageExifInfoTSX(elementIssueImageObj: IElementForumChapterIssueImageList) {
            return <>{
                elementIssueImageObj.is_exif == 1 &&
                <SoftTypography variant="caption"
                                fontWeight={"bold"}
                >
                    {elementIssueImageObj.exif_make}, {elementIssueImageObj.exif_crop_value}
                    {elementIssueImageObj.exif_aperture}, {elementIssueImageObj.exif_speed}, {elementIssueImageObj.exif_iso}, {elementIssueImageObj.exif_focal_length}
                </SoftTypography>
            }</>;
        }

        function getImageAdminPanelTSX(elementIssueImageObj: IElementForumChapterIssueImageList) {
            return <SoftBox
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <SoftTypography
                    variant={"button"}
                >
                    <SoftButton
                        href={elementIssueImageObj.image_assign_url}
                        variant={"text"}
                        color={"primary"}
                    >
                        Przypisz...
                    </SoftButton></SoftTypography>
            </SoftBox>;
        }



        /*if (".$is_assigned." = 1,
                    if (ifnull(g.assign_position, 'MAIN') = '".$assign_position."',
                        'ELEMENT_FORUM_CHAPTER_ISSUE_IMAGE_MAIN',
                        'ELEMENT_FORUM_CHAPTER_ISSUE_IMAGE_MORE'),
                'ELEMENT_FORUM_CHAPTER_ISSUE_IMAGE_ASSIGN'
            )
                template_module,*/
        if (isAssigned == 1) {
            return (
                <>
                    <SoftBox
                        px={{xs: 1, sm: 2}}
                        py={{xs: 1, sm: 2}}
                    ><SoftTypography
                        variant={'h5'}
                    >{container_title}:</SoftTypography></SoftBox>
                    {
                        elementIssueImageObjList.map(
                            (elementIssueImageObj: IElementForumChapterIssueImageList, index: number) => {
                                let isImageMain: boolean = (!elementIssueImageObj.assign_position) || (elementIssueImageObj.assign_position == 'MAIN');

                                let issueItemContent: React.ReactNode | null = elementIssueImageObj.issue_item_content ? parse(elementIssueImageObj.issue_item_content) : null;
                                return (
                                    <SoftBox
                                        key={index}
                                        sx={index > 0 ? {
                                            borderTop: "1px solid #ccc",
                                        } : {}
                                        }
                                        pt={{xs: 1, md: 2, lg: 2}}
                                        pb={{xs: 2, md: 3, lg: 3}}
                                    >
                                        <SoftBox
                                            display="flex"
                                            flexDirection="row"
                                            justifyContent={"center"}
                                        >
                                            <SoftBox
                                                component={elementIssueImageObj.url ? Link : 'div'}
                                                to={getAddHost(elementIssueImageObj.url)}
                                            >
                                                <SoftBox
                                                    component={"img"}
                                                    src={elementIssueImageObj.image_mid_thumb_src}
                                                    alt={elementIssueImageObj.seo_image_alt}
                                                    shadow={"md"}
                                                    borderRadius={"md"}
                                                    sx={
                                                        {
                                                            maxWidth: "100%",
                                                            maxHeight: "50vh",
                                                        }
                                                    }
                                                />
                                            </SoftBox>
                                        </SoftBox>
                                        <>{
                                            isImageMain && <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                px={{xs: 1, sm: 2}}
                                                py={{xs: 1, sm: 2}}
                                            >
                                                <SoftTypography
                                                    variant={"button"}
                                                >{elementIssueImageObj.issue_item_title}:</SoftTypography>
                                                <SoftBox
                                                    pt={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
                                                ><SoftTypography
                                                    variant="body2"
                                                    fontStyle={"italic"}
                                                >{issueItemContent}
                                                </SoftTypography></SoftBox>
                                            </SoftBox>
                                        }</>
                                        {getImageDictListTSX(elementIssueImageObj)}
                                        {getImageUserInfoTSX(
                                            elementIssueImageObj.user_profile_url,
                                            elementIssueImageObj.username,
                                            elementIssueImageObj.cb_miasto
                                        )}
                                    </SoftBox>
                                )

                            }) // end of map
                    }

                </>
            );

        } else
            {

                return (
                    <>
                        <SoftTypography
                            variant={'h5'}
                        >{container_title}:</SoftTypography>
                        <Grid
                            container
                            spacing={2}
                        >{
                            elementIssueImageObjList.map(
                                (elementIssueImageObj: IElementForumChapterIssueImageList, index: number) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12} sm={6} md={4} lg={3} xl={3}
                                            key={index}
                                        >

                                            <SoftBox
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <SoftBox>
                                                    <SoftBox>
                                                        {elementIssueImageObj.image_id}
                                                    </SoftBox>
                                                </SoftBox>

                                                <SoftBox
                                                    display="flex"
                                                >
                                                    <SoftBox
                                                        component={"img"}
                                                        src={elementIssueImageObj.image_mid_thumb_src}
                                                        alt={elementIssueImageObj.seo_image_alt}
                                                    />
                                                </SoftBox>

                                                {getImageDictListTSX(elementIssueImageObj)}
                                                <SoftBox
                                                    py={{xs: 1, sm: 1, md: 1, lg: 1, xl: 1}}
                                                >{isAdmin ?
                                                    getImageAdminPanelTSX(elementIssueImageObj)
                                                    : null
                                                }</SoftBox>
                                                {getImageExifInfoTSX(elementIssueImageObj)}
                                            </SoftBox>
                                        </Grid>
                                    )
                                }
                            ) // end of map
                        }</Grid>

                    </>
                );
            }
        }

        return (
            <>
                {
                    (elementIssueImageObjList?.length > 0) ?
                        (
                            getContainerContent()
                            /*Brak z zdjęć dla danego ISSUE*/
                        ) : (<></>)
                }
            </>
        );
        }

        export default GetForumChapterIssueImageList;

