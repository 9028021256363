import React, {Suspense, useContext, useEffect, useState} from "react";

import {Link} from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import useCHSourcererData from "../../../../hook/useCHSourcererData";
import CtxAppObj from "../../../../context/CtxApp";
import IContainerQuery from "../../../../type/IContainerQuery";
import IElementNewbbTopicPost from "../../../../type/newbb/IElementNewbbTopicPost";
import IUserInfo from "../../../../type/IUserInfo";
import SoftBox from "../../../../components/SoftBox";
import {is_numeric} from "../../../../util/Util";
import IGetPhpbbImageUrl from "../../../../type/newbb/IGetPhpbbImageUrl";
import SoftTypography from "../../../../components/SoftTypography";
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';
import {isDev} from "../../../../util/isDev";
import AfdInfiniteLoading from "../../../AfdInfiniteLoading";
import AfdSourcererLoading from "../../../AfdSourcererLoading";
import {extractFileNameAndExtension} from "../../../../util/UtilTS";
import SoftButton from "../../../../components/SoftButton";
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined';
// @ts-ignore
import ImgsViewer from "react-images-viewer";
import ICtxGlobalConfig from "../../../../type/context/ICtxGlobalConfig";
import useCHGetContextSoftUI from "../../../../hook/useCHGetContextSoftUI";
import {getUrlParseDomainName} from "../../../../util/UtilReact";
import ICtxClubNewbb from "../../../../type/context/ICtxClubNewbb";
import CtxClubNewbb from "../../../../context/CtxClubNewbb";
import SoftAlert from "../../../../components/SoftAlert";
import Icon from "@mui/material/Icon";
import RestApiService from "../../../../service/restapi/RestApiService";
import RestApiCommonService from "../../../../service/restapi/RestApiCommonService";
import {getPostTextTSX} from "../../../../util/UtilReactParser";
import ICtxReducerState from "../../../../type/reducer_state/ICtxReducerState";
import {
    IElementForumChapterContent
} from "../../../controller/get_club_forum_chapter_content/GetClubForumChapterContent";
import { getPhpbbImageExifInfoTSX } from "./GetPhpbbImageUrl";
import { getImageUserInfoTSX } from "../../chapter/GetForumChapterIssueImageList";



interface IProps {
    elementChapterIssueObj: IElementForumChapterContent,
    nodeAttribs: {
        competency1: string,
        competency2?: string,
    }
}

function GetPhpbbImageDict(
    {
        elementChapterIssueObj,
        nodeAttribs
    }: IProps
) {
    /*const appObj = React.useContext(CtxAppObj);*/

    const [globalConfigParamObj, dispatchFunction]: ICtxGlobalConfig = useCHGetContextSoftUI();
    const [imgsViewer, setImgsViewer] = useState<boolean>(false);
    const openImgsViewer: () => void
        = () => setImgsViewer(true);
    const closeImgsViewer: () => void
        = () => setImgsViewer(false);
    const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);
    const isPostFullwidth:boolean = false;

    /*static setDbLongTrackAdapterUrl(
            adapterClassName: string,
            procedureName: string,
            userId: number,
            id: number
    ): string {*/


    function getSqlQuery(): "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | "sql_query7" {
            return 'sql_query7';
    }

    let sqlInitialXtQuery: "sql_query1" | "sql_query2" | "sql_query3" | "sql_query4" | "sql_query5" | "sql_query6" | "sql_query7" = getSqlQuery();

    /* QUERY7 pobiera według DICT ale te same dane więc ten sam I */
    const [elementXtImageObjList,
        setElementXtImageObjList,
        refetchElementXtImageObjList,
        isElementXtImageObjListLoading
    ] = useCHSourcererData<IGetPhpbbImageUrl>(
        'get_phpbb_image',
        sqlInitialXtQuery,
        {
            dict_parent_code1: nodeAttribs['competency1'],
            dict_parent_code2: nodeAttribs['competency2']
        });

    function getContainerContent() {

        function getAllowedInternalImageTSX() {


            /*
            ADMING Testing
            if (mode == 'FULL' && (!!post_id && post_id > 0)) {
            if (mode == 'DISCUSSION') {
            * */

            return (
                <><SoftBox // nic ponieważ FLEX w getContent()
                    display={"block"}
                    flexDirection={"column"}
                    sx={{
                        clear: "none",
                        float: "left",
                    }}
                >
                    {
                        (elementXtImageObjList[0].cat_id != 6 || !isDev()) ? <SoftBox
                                display={"flex"}
                                position={"relative"}
                            ><SoftBox
                                component={"img"}
                                /*style="vertical-align: middle; max-width: 402px;"*/
                                /*className="lozad"*/
                                src={elementXtImageObjList[0].light_thumb}
                                sx={{
                                    maxWidth: "100%",
                                }}
                                alt="Zdjęcie dodane na użytek serwisu akademia-fotografii-dzieciecej.pl, wszystkie prawa zastrzeżone"/>
                                <SoftBox
                                    position={"absolute"}
                                    bottom={"5%"}
                                    right={"5%"}
                                >
                    {/*                {getImageZoomIconTSX(
                                        elementXtImageObjList[0].big_thumb,
                                        imgsViewer,
                                        closeImgsViewer,
                                        openImgsViewer,
                                        globalConfigParamObj
                                    )}*/}
                                </SoftBox>
                            </SoftBox> :
                            <SoftBox
                            ><SoftTypography
                                variant={"caption"}
                                color={"error"}>Zdjęcie niedostępne w
                                DEV</SoftTypography>
                            </SoftBox>

                    }

                    {/*Image Footer*/}
                    <SoftBox
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                    >
                        {/*content_exif_data*/}
                        <SoftBox>{getImageUserInfoTSX(
                          '',
                          '',
                          ''
                          /*elementXtImageObjList.user_profile_url,
                          elementXtImageObjList.username,
                          elementXtImageObjList.cb_miasto*/
                        )}
                        </SoftBox>
                        <SoftBox><SoftTypography>{
                            getPhpbbImageExifInfoTSX(elementXtImageObjList)
                        }
                        </SoftTypography></SoftBox>
                    </SoftBox>
                </SoftBox>
                </>
            );


            /* Jeżeli istnieje LIGHT_THUMB - podaj ten
            - nie zawsze tak było
            */

        } // end of IS ALLOWED FUNC

            /* Wewnęrzne: ACESS GRANTED:
                - jeżeli GP lub zalogowany
                - oraz opublikowany w ogóle
            */
            let imageFrameWidth = globalConfigParamObj.isMobile ?
                isPostFullwidth ? "calc(100%/3 - 0em)" : "calc(100% - 0em)"
                :
                isPostFullwidth ? "calc(100%/3 - 1em)" : "calc(100% - 1em)"
            return (
                <>
                    {

                            <>
                                {/*className="divTableHead">Zdjęcie #[[IMAGE_SEQ]] / <span
                                className="content_image_seq_global">([[IMAGE_ID]])*/}
                                <SoftBox
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    alignItems="center"
                                    shadow={"md"}
                                    sx={{
                                        backgroundColor: 'background.paper',
                                        clear: 'none',
                                        float: 'left',
                                    }}
                                    borderRadius={5}
                                    p={0.5}
                                    m={0.5}
                                    width={imageFrameWidth}
                                >
                                    {getAllowedInternalImageTSX()}
                                </SoftBox>
                            </>
                    }
                </>
            );



    }

    return (
        <>
            {
                (elementXtImageObjList?.length > 0) ?
                    (
                        getContainerContent()
                    ) : (<><AfdSourcererLoading
                    />
                    </>)
            }
        </>
    );
}

export default GetPhpbbImageDict;

